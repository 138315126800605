import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAutores'
})
export class FilterAutoresPipe implements PipeTransform {
  transform(value: any[], autorId?: number): any[] {
    if (!value || !Array.isArray(value)) {
      return [];
    }

    if (!autorId) {
        return value;
    }

    return value.filter((item) => {
      const proyectosAutores = item.proyectosAutores;
      return proyectosAutores && proyectosAutores.some(p => p.autorId === autorId);
    });
  }
}
