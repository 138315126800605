<ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab == 1}" (click)="activeTab = 1">Autores</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab == 2}" (click)="activeTab = 2">Comisiones</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active':activeTab == 3}" (click)="activeTab = 3">Temas</a>
      </li>
    <li class="nav-item">
      <a class="nav-link" [ngClass]="{'active':activeTab == 4}" (click)="activeTab = 4">Bloques</a>
    </li>
  </ul>
  
  <ng-container *ngIf="activeTab === 1">
    <ng-container *ngComponentOutlet="autoresTab"></ng-container>
  </ng-container>
  
  <ng-container *ngIf="activeTab === 2">
    <ng-container *ngComponentOutlet="comisionesTab"></ng-container>
  </ng-container>

  <ng-container *ngIf="activeTab === 3">
    <ng-container *ngComponentOutlet="temasTab"></ng-container>
  </ng-container>

  <ng-container *ngIf="activeTab === 4">
    <ng-container *ngComponentOutlet="bloquesTab"></ng-container>
  </ng-container>
  
  
  
