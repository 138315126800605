<h3>Envío de Alertas - Selección de Entidades</h3>
<div class="d-flex flex-row">
  <button
    type="button"
    class="btn btn-primary btn-sm mr-2"
    (click)="toggleView()"
  >
    {{ !viewingSent ? "Ver enviadas (Base de datos)" : "Volver" }}
  </button>
  <button
    type="button"
    class="btn btn-primary btn-sm"
    [disabled]="selectedAlertas.length === 0"
    (click)="nextStep()"
  >
    Siguiente
  </button>
</div>
<hr />

<ng-container *ngIf="alertas.length > 0; else noAlertas">
  <div class="row" *ngIf="!viewingSent">
    <button
      class="btn btn-primary btn-sm mr-2 ml-2 mb-2"
      [disabled]="selectedAlertas.length == alertas.length"
      (click)="selectAll()"
    >
      Seleccionar todas
    </button>
    <button
      class="btn btn-primary btn-sm mb-2"
      [disabled]="selectedAlertas.length < 1"
      (click)="removeAll()"
    >
      Deseleccionar todas
    </button>
  </div>

  <div class="card-container">
    <div *ngFor="let alerta of alertas">
      <div class="card text-center" style="width: 18rem">
        <div class="card-body">
          <h5 class="card-title">{{ alerta.subject }}</h5>
          <div
            class="d-flex flex-row justify-content-around"
            *ngIf="!viewingSent"
          >
            <a
              (click)="selectAlerta(alerta.entityId, alerta.entityType)"
              *ngIf="!checkIfSelected(alerta.entityId, alerta.entityType)"
              class="btn btn-secondary"
              >Seleccionar</a
            >
            <a
              (click)="removeAlerta(alerta.entityId, alerta.entityType)"
              *ngIf="checkIfSelected(alerta.entityId, alerta.entityType)"
              class="btn btn-outline-secondary"
              >Quitar</a
            >
            <a class="btn btn-warning" (click)="openEdit(alerta.entityId)"
              >Editar</a
            >
          </div>
          <div class="d-flex flex-column mt-1" *ngIf="!viewingSent">
            <button
              type="button"
              (click)="openPreview(alerta.entity)"
              class="btn btn-outline-primary w-100 mr-2"
            >
              Previsualizar
            </button>
          </div>
          <div
            class="d-flex flex-column justify-content-around"
            *ngIf="viewingSent"
          >
            <p class="card-text">
              Destinado a
              {{ alerta.recipient.nombre + alerta.recipient.apellido }}
            </p>
            <p class="card-text">
              Enviado el
              {{ alerta.sentAt | date : "dd/MM/yyyy HH:mm" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noAlertas>
  <div class="emptyState" *ngIf="!isLoading">
    <span> No hay alertas pendientes de envío </span>
    <button type="button" class="btn btn-primary">Sincronizar</button>
  </div>
</ng-template>
