import { Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ClienteModel } from '../../../entidades/models/clientes.model';
import { PaisModel } from '../../../entidades/models/pais.model';
import { LukeService } from '../../../panelServices/luke.service';


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
})
export class ClientesComponent implements OnInit {
  clientes: ClienteModel[];
  paises: PaisModel[];
  selectedPais: PaisModel;
  clienteForm: UntypedFormGroup;
  cliente: ClienteModel;

  constructor(private lukeService: LukeService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.lukeService.getPaises().subscribe((res: PaisModel[]) => {
      this.paises = res;
    });
    this.lukeService.getClientes().subscribe((res:ClienteModel[]) => {
      this.clientes = res;
    });
  }


  clientesForm() {
    this.clienteForm = this.formBuilder.group({
      id: this.cliente?.id,
      nombreCliente: this.cliente?.nombreCliente,
      isActive: this.cliente?.isActive ?? true,
      serviciosContratados: this.cliente?.serviciosContratados,
      serviciosDashboard: this.cliente?.serviciosDashboard
    })
  }

  editCliente(cliente) {
    this.cliente = cliente;
    this.clientesForm();
  }

  get isActive() {
    return this.clienteForm.get('isActive') as UntypedFormControl;
  }

  changeActive(value) {
    this.clienteForm.controls['isActive'].setValue(value);
  }

  submitCliente() {
    const formValue = this.clienteForm.value;
    if (formValue.id) {
      this.lukeService.putCliente(formValue).subscribe((res:any) => {
        Swal.fire({
          title: 'Cliente modificado',
          text: 'Se actualizó correctamente',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
        }).then((res:any)=>{
          return
        });
      })
    } else {
      delete formValue.id;
      this.lukeService.postCliente(formValue).subscribe((res:any) => {
        Swal.fire({
          title: 'Cliente agregado',
          text: 'Se actualizó correctamente',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
        }).then((res:any)=>{
          return
        });
      })
    }
  }


  onDelete(id: string) {
    Swal.fire({
      title: 'Borrar cliente',
      text: '¿Está seguro que quiere borrar este cliente?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService.deleteCliente(id).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'El cliente fue borrado', 'success');
            this.lukeService.getClientes().subscribe((res:ClienteModel[]) => {
              this.clientes = res;
            });
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo',
            });
          }
        );
      } else {
        return;
      }
    });
  }
}
