export enum PaisesEnum {
    None,
    Argentina,
    Bolivia,
    Brasil,
    Chile,
    Colombia,
    CostaRica,
    Cuba,
    Ecuador,
    ElSalvador,
    EstadosUnidosdeAmerica,
    Guatemala,
    Guyana,
    Honduras,
    Jamaica,
    México,
    Nicaragua,
    Panamá,
    Paraguay,
    Perú,
    PuertoRico,
    RepublicaDominicana,
    SinPais,
    Suriname,
    TrinidadyTobago,
    Uruguay,
    Venezuela
}