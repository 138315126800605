import { EstadosModel } from "./estados.model";

export class MovimientoModel {
    id: number;
    fecha: string;
    estado: EstadosModel;
    estadoId: number;
    descripcion: string;
    link: string;
    documento: string;
    entidadTipo: number;
}