import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, of, zip } from 'rxjs';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import { S3UploadService } from 'src/app/core/services/s3-upload.service';
import { AnteProyectosMapper } from 'src/utils/anteProyectosMapper';
import Swal from 'sweetalert2';
import { LukeService } from '../../../panelServices/luke.service';
import { AlertasFile } from '../../models/alertasFile.interface';
import { AnteProyectoModel } from '../../models/anteproyecto.model';
import { AutoresModel } from '../../models/autores.model';
import { BloqueModel } from '../../models/bloque.model';
import { CamaraModel } from '../../models/camaras.model';
import { CategoriaModel } from '../../models/categorias.model';
import { ClienteModel } from '../../models/clientes.model';
import { EstadosModel } from '../../models/estados.model';
import { MovimientoModel } from '../../models/movimiento.model';
import { NivelesGobiernoModel } from '../../models/nivelesgobierno.model';
import { NotaModel } from '../../models/nota.model';
import { OrigenesModel } from '../../models/origenes.model';
import { PagedResponse } from '../../models/pagedResponse.interface';
import { PaisModel } from '../../models/pais.model';
import { Posicion } from '../../models/posicion.model';
import { ProyectosTiposModel } from '../../models/proyectosTipos.model';
import { TemaModel } from '../../models/tema.model';

@Component({
  selector: 'app-anteproyectos-show',
  templateUrl: 'anteproyectos.component.html',
  styleUrls: ['./anteproyectos.component.css'],
})
export class AnteProyectoShowComponent implements OnInit {
  loading: boolean = false;
  anteproyectosLoading: boolean = false;
  notasLoading: boolean = false;
  newFileView: boolean = true;
  anteproyecto: any;
  anteproyectoId: number;
  anteproyectos: PagedResponse<AnteProyectoModel>;
  form: UntypedFormGroup;
  autorForm: UntypedFormGroup;
  paises: PaisModel[] = [];
  estados: EstadosModel[];
  temas: TemaModel[] = [];
  camaras: CamaraModel[];
  niveles: NivelesGobiernoModel[];
  tipos: ProyectosTiposModel[];
  clientes: ClienteModel[];
  autores: AutoresModel[];
  autor: AutoresModel;
  origenes: OrigenesModel[];
  categorias: CategoriaModel[];
  notas: PagedResponse<NotaModel>;
  expedienteFile: File;
  existingFiles: AlertasFile[] = [];
  probAvance = [
    { id: 1, value: 'Alta' },
    { id: 2, value: 'Media' },
    { id: 3, value: 'Baja' },
  ];
  impactoDict = [
    { id: 1, value: 'Alta' },
    { id: 2, value: 'Media' },
    { id: 3, value: 'Baja' },
  ];
  selectedPais: number;
  movimiento: MovimientoModel;
  movimientosRes: any;
  movForm: UntypedFormGroup;
  bloques: BloqueModel[];
  posiciones = [
    { id: Posicion.Oficialismo, name: 'Oficialismo' },
    { id: Posicion.Oposicion, name: 'Oposición' },
  ];
  formatDate = formatDate;

  constructor(
    private lukeService: LukeService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private s3Service: S3UploadService
  ) {
    this.route.params.subscribe((params) => {
      params['id'] ? (this.anteproyectoId = params['id']) : this.createForm();
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      titulo: [this.anteproyecto?.titulo, Validators.required],
      pais: [this.anteproyecto?.paisId, Validators.required],
      fechaIngreso: [this.anteproyecto?.fechaIngreso, Validators.required],
      nivelGobierno: [this.anteproyecto?.nivelGobiernoId, Validators.required],
      distrito: this.anteproyecto?.distrito,
      clientes: [
        this.anteproyecto?.clientesAsociados.map((cliente) => cliente.id) ?? [],
        Validators.required,
      ],
      temas: [
        this.anteproyecto?.temas.map((tema) => tema.id) ?? [],
        Validators.required,
      ],
      sintesis: [this.anteproyecto?.sintesis, Validators.required],
      categorias: [
        this.anteproyecto?.categorias.map((cat) => cat.id),
        Validators.required,
      ],
      origen: [this.anteproyecto?.origenId, Validators.required],
      camara: this.anteproyecto?.camaraId,
      anteproyectoTipo: [this.anteproyecto?.tipoId, Validators.required],
      autores: [
        this.anteproyecto?.autores.map((autor) => autor.id) ?? [],
        Validators.required,
      ],
      probabilidadAvance: [
        this.anteproyecto?.probabilidadSancionId,
        Validators.required,
      ],
      impacto: [this.anteproyecto?.impactoId, Validators.required],
      isPublic: this.anteproyecto?.isPublic ?? false,
      expedienteNumero: [
        this.anteproyecto?.expedienteNumero,
        Validators.required,
      ],
      expedienteUrl: [this.anteproyecto?.expedienteUrl],
      anteproyectosAsociados: [
        this.anteproyecto?.anteProyectosRelacionados.map((ap) => ap.id) ?? [],
      ],
      notasAsociadas: [
        this.anteproyecto?.notasRelacionadas.map((not) => not.id) ?? [],
      ],
      movimientos: this.formBuilder.array([]),
    });
    this.startArrayControls();

    this.form.controls['pais'].valueChanges.subscribe((value) =>
      this.changeDependencies(value)
    );

    this.form.controls['clientes'].valueChanges.subscribe((value) => {
      this.changeCliente(value);
    });
  }

  getAnteproyecto(id: number): Observable<any> {
    return this.lukeService.getAnteproyecto(id).pipe(
      tap((res) => {
        this.anteproyecto = res;
        this.anteproyecto.fechaIngreso = formatDate(
          res?.fechaIngreso,
          'yyyy-MM-dd',
          'en-US'
        );
        this.createForm();
        this.getMovimientos(0, id);
        this.changeDependencies(res.paisId);
        this.changeCliente(res.clientesAsociados.map((val) => val.id));
      })
    );
  }

  getMovimientos(entidadTipoId, entidadId) {
    this.lukeService
      .getMovimientos(entidadTipoId, entidadId)
      .subscribe((res) => {
        this.movimientosRes = res;
        this.startArrayControls();
      });
  }

  startArrayControls() {
    if (this.movimientosRes && this.movimientosRes.length > 0) {
      const sortedMovimientos = this.movimientosRes.sort((mov1, mov2) => {
        return (
          new Date(mov1.fechaMovimiento).getTime() -
          new Date(mov2.fechaMovimiento).getTime()
        );
      });
      sortedMovimientos.forEach((movimiento) => {
        this.addMovimiento(
          movimiento.fechaMovimiento,
          movimiento.estado,
          movimiento.estadoId,
          movimiento.descripcion,
          movimiento.link,
          movimiento.id
        );
      });
    } else {
      return;
    }
  }

  scrollToEnd(entity: string) {
    if (entity === 'anteproyectos') {
      this.anteproyectosLoading = true;
      this.lukeService
        .getAnteproyectos({
          pageNumber: this.anteproyectos.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((ap: PagedResponse<AnteProyectoModel>) => {
          this.anteproyectos.items = [...this.anteproyectos.items, ...ap.items];
          this.anteproyectos.pageNumber = ap.pageNumber;
          this.anteproyectosLoading = false;
        });
    } else if (entity === 'notas') {
      this.notasLoading = true;
      this.lukeService
        .getNotas({
          pageNumber: this.notas.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((notas: PagedResponse<NotaModel>) => {
          this.notas.items = [...this.notas.items, ...notas.items];
          this.notas.pageNumber = notas.pageNumber;
          this.notasLoading = false;
        });
    }
  }

  trackById(item: any): any {
    return item.id;
  }

  private addMovimiento(
    fecha = null,
    estado = null,
    estadoId = null,
    descripcion = '',
    link = '',
    id = 0,
    entidadTipo = 0
  ) {
    this.movimientos.push(
      this.formBuilder.group({
        fecha,
        estado,
        estadoId,
        descripcion,
        link,
        id,
        entidadTipo,
      })
    );
  }

  ngOnInit(): void {
    this.loading = true;
    Swal.fire({ text: 'Cargando...' });
    Swal.showLoading();

    forkJoin({
      paises: this.lukeService.getPaises(),
      niveles: this.lukeService.getNiveles(),
      clientes: this.lukeService.getClientes(),
      categorias: this.lukeService.getCategorias(),
      anteproyectos: this.lukeService.getAnteproyectos({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
      origenes: this.lukeService.getOrigenes(),
      tipos: this.lukeService.getTipos(),
      estados: this.lukeService.getEstados({ isAnteproyecto: true }),
      notas: this.lukeService.getNotas({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
    })
      .pipe(
        tap((results: any) => {
          this.paises = results.paises;
          this.niveles = results.niveles;
          this.clientes = results.clientes;
          this.categorias = results.categorias;
          this.anteproyectos = results.anteproyectos;
          this.origenes = results.origenes;
          this.tipos = results.tipos;
          this.estados = results.estados;
          this.notas = results.notas;
          this.loading = false;
        }),
        concatMap(() => {
          if (this.anteproyectoId) {
            return this.getAnteproyecto(this.anteproyectoId);
          } else {
            return of(null);
          }
        }),
        tap((anteproyecto) => {
          if (anteproyecto) {
            this.anteproyecto = anteproyecto;
            if (this.anteproyecto) {
              this.anteproyectos.items = [
                ...this.anteproyectos.items,
                ...this.anteproyecto.anteProyectosRelacionados,
              ];
              this.notas.items = [
                ...this.notas.items,
                ...this.anteproyecto.notasRelacionadas,
              ];
            }
          }
          Swal.close();
        })
      )
      .subscribe();
  }

  changeDependencies(value) {
    this.lukeService
      .getClientesFiltered(value, 2, 3)
      .subscribe((res: ClienteModel[]) => {
        this.clientes = res;
      });
    this.lukeService
      .getAutores({ paisId: value })
      .subscribe((res: AutoresModel[]) => {
        this.autores = res;
      });
    this.lukeService.getCamaras(value).subscribe((res: CamaraModel[]) => {
      this.camaras = res;
    });
    this.lukeService
      .getEntityByQueryParams('bloques', { paisId: value })
      .subscribe((res: BloqueModel[]) => {
        this.bloques = res;
      });
  }

  changeCliente(value) {
    if (!value || !this.form.get('pais').value) {
      return;
    }
    this.showLoading();

    const anteproyectos$ = this.lukeService
      .getAnteproyectos({
        clienteId: value,
        paises: [this.form.get('pais').value],
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const notas$ = this.lukeService
      .getNotas({ clienteId: value, paises: [this.form.get('pais').value] })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const temas$ = this.lukeService
      .getEntityByQueryParams('temas', { clienteId: value })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    zip(anteproyectos$, notas$, temas$)
      .pipe(finalize(() => Swal.close()))
      .subscribe(([anteproyectos, notas, temas]) => {
        this.anteproyectos = anteproyectos;
        this.notas = notas;
        this.temas = temas;
      });
  }

  get movimientos() {
    return this.form.get('movimientos') as UntypedFormArray;
  }

  private showLoading() {
    Swal.fire({
      title: 'Cargando...',
      icon: 'info',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  movimientoForm() {
    this.movForm = this.formBuilder.group({
      id: this.movimiento?.id,
      fecha: this.movimiento?.fecha,
      estado: this.movimiento?.estado,
      estadoId: this.movimiento?.estadoId,
      descripcion: this.movimiento?.descripcion,
      link: this.movimiento?.link,
      entidadTipo: 0,
    });
  }

  autoresForm() {
    this.autorForm = this.formBuilder.group({
      id: this.autor?.id,
      nombreApellido: [this.autor?.nombreApellido, Validators.required],
      pais: { value: this.form.get('pais').value ?? 'none', disabled: true },
      camaraId: [this.autor?.camara.id, Validators.required],
      posicion: [this.autor?.posicion, Validators.required],
      bloqueId: [this.autor?.bloqueId, Validators.required],
      perfilOficial: this.autor?.perfilOficial,
    });
  }

  submitAutor() {
    const formValue = this.autorForm.value;
    formValue.pais = this.form.get('pais').value;
    this.lukeService.postAutor(formValue).subscribe((res: any) => {
      Swal.fire({
        title: 'Autor agregado',
        text: 'Se actualizó correctamente',
        icon: 'success',
        showConfirmButton: true,
        confirmButtonText: 'Cerrar',
      });
      this.lukeService
        .getAutores({ paisId: this.form.controls['pais'].value })
        .subscribe((res: AutoresModel[]) => {
          this.autores = res;
        });
    });
  }

  cleanMov() {
    this.movimiento = new MovimientoModel();
  }

  submitMov() {
    const formValue = this.movForm.value;
    if (formValue.id) {
      this.lukeService
        .putMovimiento(formValue, this.anteproyecto.id, 0)
        .subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un error, revisa los campos e intentalo de nuevo.',
            });
          } else {
            this.form.controls.movimientos = this.formBuilder.array([]);
            this.getMovimientos(0, this.anteproyecto.id);
            this.cleanMov();
            Swal.fire({
              icon: 'success',
              title: 'Listo!',
              text: 'El movimiento fue modificado',
            });
          }
        });
    } else if (this.anteproyecto) {
      this.lukeService
        .postMovimiento(formValue, this.anteproyecto.id, 0)
        .subscribe((res: any) => {
          this.addMovimiento(
            formValue.fecha ?? null,
            formValue.estado.descripcionEstado ?? '',
            formValue.estado.id ?? null,
            formValue.descripcion ?? '',
            formValue.link ?? '',
            res.id ?? 0
          );
        });
    } else {
      this.cleanMov();
      this.addMovimiento(
        formValue.fecha ?? null,
        formValue.estado.descripcionEstado ?? '',
        formValue.estado.id ?? null,
        formValue.descripcion ?? '',
        formValue.link ?? '',
        formValue.id ?? 0,
        formValue.entidadTipo
      );
    }
  }

  editMov(mov) {
    this.movimiento = mov.value;
    this.movimiento.fecha = this.movimiento.fecha.split('T')[0];
    this.movimientoForm();
  }

  delMov(id, index) {
    if (this.anteproyecto) {
      Swal.fire({
        title: 'Borrar movimiento',
        text: '¿Está seguro que quiere borrar este movimiento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.lukeService.deleteMovimiento(id).subscribe(
            (res) => {
              Swal.showLoading();
              Swal.fire('Borrado!', 'El movimiento fue borrado', 'success');
              this.removeMovimiento(index);
            },
            (error) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salió mal, intenta de nuevo',
              });
            }
          );
        } else {
          return;
        }
      });
    } else {
      this.removeMovimiento(index);
    }
  }

  removeMovimiento(index) {
    this.movimientos.removeAt(index);
  }

  changePublic(value) {
    this.form.controls['isPublic'].setValue(value);
  }

  generateFileRead() {
    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([reader.result], {
        type: this.expedienteFile.type,
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    };
    reader.readAsArrayBuffer(this.expedienteFile);
  }

  addExistingFileView() {
    this.newFileView = false;
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();
    this.s3Service.getUploadedFiles().subscribe((res) => {
      this.existingFiles = res.Contents.map((file) => {
        return {
          name: file.Key,
          url: `https://alertas-v2.directoriolegislativo.org/${file.Key}`,
        };
      });
      Swal.close();
    });
  }

  async submitForm() {
    if (this.form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Tenes que llenar todos los campos marcados con un asterisco (*).',
      });
      return;
    }
    if (this.movimientos.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No puede subirse sin ningun movimiento. Agrega un movimiento y volve a intentar.',
      });
      return;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando anteproyecto',
      icon: 'info',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    const formValue = this.form.value;
    if (this.expedienteFile && this.newFileView) {
      const uploadedFile = await this.s3Service
        .uploadFile(this.expedienteFile)
        .toPromise();
      formValue.expedienteUrl = `https://alertas-v2.directoriolegislativo.org/${
        uploadedFile.key ? uploadedFile.key : uploadedFile.Key
      }`;
    } else if (this.expedienteFile && !this.newFileView) {
      formValue.expedienteUrl = this.expedienteFile['url'];
    }
    let mappedValues = AnteProyectosMapper.mapToRequest(formValue);

    this.route.params.subscribe((params) => {
      if (params['id']) {
        mappedValues.id = params['id'];
        this.lukeService.putAnteproyecto(mappedValues).subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else
            Swal.fire({
              title: 'Anteproyecto modificado',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                this.router.navigateByUrl('/dashboard/anteproyectos');
              },
            });
        });
      } else {
        mappedValues['movimientosParlamentarios'] = formValue.movimientos;
        this.lukeService
          .postAnteProyecto(mappedValues)
          .subscribe((res: any) => {
            if (res.error) {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Algo no salió bien, revisá los datos del formulario',
              });
            } else {
              Swal.fire({
                title: 'Anteproyecto agregado',
                text: 'Se actualizó correctamente',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonText: 'Cerrar',
                willClose: () => {
                  this.router.navigateByUrl('/dashboard/anteproyectos');
                },
              });
            }
          });
      }
    });
  }
}
