import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByClienteId'
})
export class GroupByClienteIdPipe implements PipeTransform {
  transform(selectedUsuarios: any[]): any {
    if (!selectedUsuarios) return [];

    const groupedData = new Map<number, any[]>();

    selectedUsuarios.forEach(usuario => {
      const clienteId = usuario.clienteId;

      if (!groupedData.has(clienteId)) {
        groupedData.set(clienteId, []);
      }

      groupedData.get(clienteId).push(usuario);
    });

    return Array.from(groupedData.values());
  }
}
