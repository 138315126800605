import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientesMapper } from 'src/utils/clientesMapper';
import Swal from 'sweetalert2';
import { CategoriaModel } from '../../../entidades/models/categorias.model';
import { ClienteModel } from '../../../entidades/models/clientes.model';
import { PaisModel } from '../../../entidades/models/pais.model';
import { LukeService } from '../../../panelServices/luke.service';
import showLoading from 'src/utils/showLoading';

interface Selectable {
  id: number;
  name: string;
}

@Component({
  selector: 'app-cliente',
  templateUrl: 'cliente.component.html',
  styleUrls: ['./cliente.component.css'],
})
export class ClienteComponent implements OnInit {
  cliente: ClienteModel;
  paises: Selectable[];
  form: UntypedFormGroup;
  categorias: CategoriaModel[];
  alertas: Selectable[] = [];
  scraper: Selectable[] = [];
  newsletter: Selectable[] = [];
  dashboard: Selectable[] = [];
  dashboardNotas: Selectable[] = [];
  dashboardNormativas: Selectable[] = [];
  dashboardProyectos: Selectable[] = [];

  constructor(
    private lukeService: LukeService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {
    this.route.params.subscribe((params) => {
      params['id'] ? this.getCliente(params['id']) : this.createForm();
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      id: this.cliente?.id,
      nombreCliente: [this.cliente?.nombreCliente, Validators.required],
      isActive: [this.cliente?.isActive, Validators.required],
      categorias: [
        this.cliente?.categorias.map((cat) => cat.id),
        Validators.required,
      ],
    });
  }

  getCliente(id: string) {
    this.lukeService.getCliente(id).subscribe((res: ClienteModel) => {
      this.cliente = res;
      res.serviciosContratados.forEach((servicio) => {
        if (servicio.paises.length > 0) {
          const mappedPaises = servicio.paises.map((pais) => ({
            id: pais.id,
            name: pais.nombre,
          }));
          switch (servicio.servicioId) {
            case 1:
              this.alertas = mappedPaises;
              break;
            case 2:
              this.dashboard = mappedPaises;
              break;
            case 3:
              this.newsletter = mappedPaises;
              break;
            case 4:
              this.scraper = mappedPaises;
              break;
          }
        }
      });

      if (res.serviciosDashboard.length > 0) {
        const notas = res.serviciosDashboard.find(
          (servicio) => servicio.servicio === 'Notas'
        );
        const proyectos = res.serviciosDashboard.find(
          (servicio) => servicio.servicio === 'Proyectos'
        );
        const normativas = res.serviciosDashboard.find(
          (servicio) => servicio.servicio === 'Normativas'
        );
        if (notas) {
          this.dashboardNotas = notas.paises.map((pais) => {
            return { id: pais.id, name: pais.nombre };
          });
        }
        if (normativas) {
          this.dashboardNormativas = normativas.paises.map((pais) => {
            return { id: pais.id, name: pais.nombre };
          });
        }
        if (proyectos) {
          this.dashboardProyectos = proyectos.paises.map((pais) => {
            return { id: pais.id, name: pais.nombre };
          });
        }
      }

      this.createForm();
    });
  }

  ngOnInit(): void {
    this.lukeService.getPaises().subscribe((res: PaisModel[]) => {
      this.paises = res.map((pais) => {
        return { id: pais.id, name: pais.name };
      });
    });
    this.lukeService.getCategorias().subscribe((res: CategoriaModel[]) => {
      this.categorias = res;
    });
  }

  handleUsuarioMod(data: any) {
    this.lukeService.getCliente(data).subscribe((res: ClienteModel) => {
      this.cliente = res;
    });
  }

  get isActive() {
    return this.form.get('isActive') as UntypedFormControl;
  }

  changeActive(value) {
    this.form.controls['isActive'].setValue(value);
  }

  objExists(pais, caso) {
    switch (caso) {
      case 'alertas':
        return this.alertas?.some((obj) => obj.id === pais.id);
      case 'newsletter':
        return this.newsletter?.some((obj) => obj.id === pais.id);
      case 'scraper':
        return this.scraper?.some((obj) => obj.id === pais.id);
      case 'dashboard':
        return this.dashboard?.some((obj) => obj.id === pais.id);
      case 'dashboardNotas':
        return this.dashboardNotas?.some((obj) => obj.id === pais.id);
      case 'dashboardNormativas':
        return this.dashboardNormativas?.some((obj) => obj.id === pais.id);
      case 'dashboardProyectos':
        return this.dashboardProyectos?.some((obj) => obj.id === pais.id);
    }
  }

  addRemoveAlerta(pais, caso) {
    switch (caso) {
      case 'alertas':
        if (this.objExists(pais, caso))
          this.alertas = this.alertas.filter((obj) => obj.id !== pais.id);
        else this.alertas.push(pais);
        break;
      case 'newsletter':
        if (this.objExists(pais, caso))
          this.newsletter = this.newsletter.filter((obj) => obj.id !== pais.id);
        else this.newsletter.push(pais);
        break;
      case 'scraper':
        if (this.objExists(pais, caso))
          this.scraper = this.scraper.filter((obj) => obj.id !== pais.id);
        else this.scraper.push(pais);
        break;
      case 'dashboard':
        if (this.objExists(pais, caso))
          this.dashboard = this.dashboard.filter((obj) => obj.id !== pais.id);
        else this.dashboard.push(pais);
        break;
      case 'dashboardNotas':
        if (this.objExists(pais, caso))
          this.dashboardNotas = this.dashboardNotas.filter(
            (obj) => obj.id !== pais.id
          );
        else this.dashboardNotas.push(pais);
        break;
      case 'dashboardNormativas':
        if (this.objExists(pais, caso))
          this.dashboardNormativas = this.dashboardNormativas.filter(
            (obj) => obj.id !== pais.id
          );
        else this.dashboardNormativas.push(pais);
        break;
      case 'dashboardProyectos':
        if (this.objExists(pais, caso))
          this.dashboardProyectos = this.dashboardProyectos.filter(
            (obj) => obj.id !== pais.id
          );
        else this.dashboardProyectos.push(pais);
        break;
    }
  }

  submitForm() {
    showLoading('Guardando Cliente...');
    const formValue = this.form.value;
    formValue.alertas = this.alertas;
    formValue.newsletter = this.newsletter;
    formValue.scraper = this.scraper;
    formValue.dashboard = this.dashboard;
    formValue.dashboardNotas = this.dashboardNotas;
    formValue.dashboardNormativas = this.dashboardNormativas;
    formValue.dashboardProyectos = this.dashboardProyectos;
    let mappedValues = ClientesMapper.mapToRequest(formValue);
    if (formValue.id) {
      this.lukeService.putCliente(mappedValues).subscribe((res: any) => {
        if (res.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Algo no salio bien, revisa los datos del formulario',
          });
        } else
          Swal.fire({
            title: 'Cliente agregado',
            text: 'Se actualizo correctamente',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Cerrar',
            willClose: () => {
              this.router.navigateByUrl('/dashboard/clientes');
            },
          });
      });
    } else {
      this.lukeService.postCliente(mappedValues).subscribe((res: any) => {
        if (res.error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Algo no salió bien, revisá los datos del formulario',
          });
        } else {
          Swal.fire({
            title: 'Cliente agregado',
            text: 'Se actualizó correctamente',
            icon: 'success',
            showConfirmButton: true,
            confirmButtonText: 'Cerrar',
            willClose: () => {
              this.router.navigateByUrl('/dashboard/clientes');
            },
          });
        }
      });
    }
    return 'ok';
  }
}
