import { Component, EventEmitter, Output } from '@angular/core';
import { S3UploadService } from 'src/app/core/services/s3-upload.service';
import { AlertasFile } from 'src/app/panel/entidades/models/alertasFile.interface';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styles: [
  ]
})
export class UploadComponent {


  selectedFile: File = null;
  loading = false;

  @Output() onUpload = new EventEmitter<AlertasFile>();

  constructor(
    private s3Service: S3UploadService,
  ) {}

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.loading = true;
    this.s3Service.uploadFile(this.selectedFile).subscribe((res) => {

      this.loading = false;
      this.onUpload.emit({
        name: res.Key,
        url: `https://alertas-v2.directoriolegislativo.org/${res.Key}`,
        lastModified: new Date(),
      });
    });
  }

}
