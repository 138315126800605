import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { QuillModule } from 'ngx-quill';
import { DateRangeFilterPipe } from '../core/pipes/date-range.pipe';
import { FilterUniquePipe } from '../core/pipes/filter-unique.pipe';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { GroupByClienteIdPipe } from '../core/pipes/group-usuarios.pipe';
import { FilterAutoresPipe } from '../core/pipes/search-autor.pipe';
import { FilterCamaraPipe } from '../core/pipes/search-camara.pipe';
import { SearchFilterPipe } from '../core/pipes/search-filter.pipe';
import { SearchNormativasPipe } from '../core/pipes/search-normativas.pipe';
import { SearchProyectosPipe } from '../core/pipes/search-proyectos.pipe';
import { SearchTemasPipe } from '../core/pipes/search-tema.pipe';
import { SearchcountryPipe } from '../core/pipes/searchcountry.pipe';
import { TruncatePipe } from '../core/pipes/truncate.pipe';
import { NgbdSortableHeader } from '../shared/directives/sortable.directive';
import { SharedModule } from '../shared/shared.module';
import { UploadComponent } from '../shared/upload/upload.component';
import { AutoresComponent } from './autores/autores.component';
import { BloquesComponent } from './bloques/bloques.component';
import { ClientesModule } from './clientes/clientes.module';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { ConfigViewComponent } from './configview/configview.component';
import { EmailHistoryComponent } from './email-history/email-history.component';
import { AnteProyectoComponent } from './entidades/anteproyectos/list/anteproyectos.component';
import { AnteProyectoShowComponent } from './entidades/anteproyectos/show/anteproyectos.component';
import { EntidadesComponent } from './entidades/entidades.component';
import { LeyesComponent } from './entidades/leyes/list/leyes.component';
import { LeyesShowComponent } from './entidades/leyes/show/leyes.component';
import { NormativasComponent } from './entidades/normativas/list/normativas.component';
import { NormativasShowComponent } from './entidades/normativas/show/normativas.component';
import { NotaComponent } from './entidades/notas/list/notas.component';
import { NotaShowComponent } from './entidades/notas/show/notas.component';
import { ProyectoComponent } from './entidades/proyecto/list/proyecto.component';
import { ProyectoShowComponent } from './entidades/proyecto/show/proyecto.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ClientSelectComponent } from './newsletter/formPanels/client-select/client-select.component';
import { EmailPreviewComponent } from './newsletter/formPanels/email-preview/email-preview.component';
import { EmailSendComponent } from './newsletter/formPanels/email-send/email-send.component';
import { EntitySelectComponent } from './newsletter/formPanels/entity-select/entity-select.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { PremadeComponent } from './newsletter/premade/premade.component';
import { PagesComponent } from './pages.component';
import { ProfileComponent } from './profile/profile.component';
import { ScrapingTablesComponent } from './scraping/scraping-tables/scraping-tables.component';
import { ScrapingViewComponent } from './scraping/scraping-view/scraping-view.component';
import { ScrapingComponent } from './scraping/scraping.component';
import { TemasComponent } from './temas/temas.component';

@NgModule({
  declarations: [
    ComisionesComponent,
    AutoresComponent,
    PagesComponent,
    EntidadesComponent,
    AnteProyectoComponent,
    AnteProyectoShowComponent,
    UploadComponent,
    ProfileComponent,
    ProyectoComponent,
    NormativasComponent,
    NormativasShowComponent,
    LeyesComponent,
    LeyesShowComponent,
    ProyectoShowComponent,
    NotaComponent,
    NotaShowComponent,
    SearchFilterPipe,
    SearchcountryPipe,
    FilterUniquePipe,
    ScrapingComponent,
    ScrapingViewComponent,
    ScrapingTablesComponent,
    SearchProyectosPipe,
    SearchNormativasPipe,
    SearchTemasPipe,
    DateRangeFilterPipe,
    FilterAutoresPipe,
    FilterCamaraPipe,
    FilterPipe,
    TruncatePipe,
    ConfigViewComponent,
    TemasComponent,
    BloquesComponent,
    NewsletterComponent,
    ClientSelectComponent,
    EntitySelectComponent,
    EmailPreviewComponent,
    FileUploadComponent,
    EmailSendComponent,
    PremadeComponent,
    EmailHistoryComponent,
    GroupByClienteIdPipe,
  ],

  exports: [
    PagesComponent,
    EntidadesComponent,
    EmailHistoryComponent,
    GroupByClienteIdPipe,
  ],

  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    NgxPaginationModule,
    FontAwesomeModule,
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['link'],
          ['clean'],
        ],
      },
    }),
    ClientesModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgbPaginationModule,
  ],

  providers: [DatePipe, NgbdSortableHeader],
})
export class PanelModule {}
