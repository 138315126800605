import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { AutoresModel } from '../entidades/models/autores.model';
import { BloqueModel } from '../entidades/models/bloque.model';
import { CamaraModel } from '../entidades/models/camaras.model';
import { PaisModel } from '../entidades/models/pais.model';
import { Posicion } from '../entidades/models/posicion.model';
import { LukeService } from '../panelServices/luke.service';

@Component({
  selector: 'app-autores',
  templateUrl: './autores.component.html',
  styleUrls: ['./autores.component.css'],
})
export class AutoresComponent implements OnInit {
  autores: AutoresModel[];
  paises: PaisModel[];
  selectedPais: PaisModel;
  camaras: CamaraModel[];
  autorForm: UntypedFormGroup;
  autor: AutoresModel;
  bloques: BloqueModel[];
  posiciones = [
    { id: Posicion.Oficialismo, name: 'Oficialismo' },
    { id: Posicion.Oposicion, name: 'Oposición' },
  ];

  constructor(
    private lukeService: LukeService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.lukeService.getPaises().subscribe((res: PaisModel[]) => {
      this.paises = res;
    });
  }

  changeDependencies(value) {
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();
    forkJoin([
      this.lukeService.getAutores(value),
      this.lukeService.getCamaras(value),
      this.lukeService.getEntityByQueryParams('bloques', { paisId: value }),
    ]).subscribe(
      ([autores, camaras, bloques]: [
        AutoresModel[],
        CamaraModel[],
        BloqueModel[]
      ]) => {
        this.autores = autores;
        this.camaras = camaras;
        this.bloques = bloques;
        Swal.close();
      }
    );
  }

  autoresForm() {
    this.autorForm = this.formBuilder.group({
      id: this.autor?.id,
      nombreApellido: [this.autor?.nombreApellido, Validators.required],
      pais: [{ value: this.selectedPais.id ?? 'none', disabled: true }],
      camaraId: [this.autor?.camara.id, Validators.required],
      posicion: [this.autor?.posicion, Validators.required],
      bloqueId: [this.autor?.bloqueId, Validators.required],
      perfilOficial: this.autor?.perfilOficial,
    });
  }

  editAutor(autor) {
    this.autor = autor;
    this.autoresForm();
  }

  getBloqueString(id) {
    return this.bloques.find((b) => b.id === id)
      ? this.bloques.find((b) => b.id === id).descripcionBloque
      : 'Sin bloque.';
  }

  getPosicionString(id) {
    if (id === 0) {
      return 'Oficialismo';
    } else if (id === 1) {
      return 'Oposición';
    } else return 'Sin posición definida.';
  }

  resetAutor() {
    this.autorForm.reset();
    this.autor = null;
  }

  submitAutor() {
    const formValue = this.autorForm.value;
    formValue.pais = this.selectedPais.id;
    if (formValue.id) {
      this.lukeService.putAutor(formValue).subscribe((res: any) => {
        Swal.fire({
          title: 'Autor modificado',
          text: 'Se actualizó correctamente',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
        }).then((res: any) => {
          this.lukeService
            .getAutores({ paisId: this.selectedPais.id })
            .subscribe((res: AutoresModel[]) => {
              this.autores = res;
            });
          this.resetAutor();
        });
      });
    } else {
      delete formValue.id;
      this.lukeService.postAutor(formValue).subscribe((res: any) => {
        Swal.fire({
          title: 'Autor agregado',
          text: 'Se actualizó correctamente',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
        }).then((res: any) => {
          this.lukeService
            .getAutores({ paisId: this.selectedPais.id })
            .subscribe((res: AutoresModel[]) => {
              this.autores = res;
            });
          this.resetAutor();
        });
      });
    }
  }

  onDelete(id: string) {
    Swal.fire({
      title: 'Borrar autor',
      text: '¿Está seguro que quiere borrar este autor?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService.deleteAutor(id).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'El autor fue borrado', 'success');
            this.lukeService
              .getAutores({ paisId: this.selectedPais.id })
              .subscribe((res: AutoresModel[]) => {
                this.autores = res;
              });
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo',
            });
          }
        );
      } else {
        return;
      }
    });
  }
}
