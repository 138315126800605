import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, forkJoin, of, zip } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  tap,
} from 'rxjs/operators';
import { NotasMapper } from 'src/utils/notaMapper';
import showLoading from 'src/utils/showLoading';
import Swal from 'sweetalert2';
import { LukeService } from '../../../panelServices/luke.service';
import { AnteProyectoModel } from '../../models/anteproyecto.model';
import { CategoriaModel } from '../../models/categorias.model';
import { ClienteModel } from '../../models/clientes.model';
import { LeyModel } from '../../models/ley.model';
import { NivelesGobiernoModel } from '../../models/nivelesgobierno.model';
import { NormativaModel } from '../../models/normativa.model';
import { PagedResponse } from '../../models/pagedResponse.interface';
import { PaisModel } from '../../models/pais.model';
import { ProyectoModel } from '../../models/proyecto.model';
import { TemaModel } from '../../models/tema.model';

@Component({
  selector: 'app-notas-show',
  templateUrl: 'notas.component.html',
  styleUrls: ['./notas.component.css'],
  host: {
    '[style.overflow]': '"auto"',
  },
})
export class NotaShowComponent implements OnInit {
  @Input() modalInput?: number;
  loading: boolean = false;
  nota: any;
  leyes: LeyModel[] = [];
  anteproyectos: AnteProyectoModel[] = [];
  proyectos: ProyectoModel[] = [];
  normativas: NormativaModel[] = [];
  anteproyectosLoading: boolean = false;
  proyectosLoading: boolean = false;
  normativasLoading: boolean = false;
  leyesLoading: boolean = false;
  form: UntypedFormGroup;
  paises: PaisModel[];
  temas: TemaModel[];
  niveles: NivelesGobiernoModel[];
  clientes: ClienteModel[];
  searchTerm$ = new Subject<{ term: string; entityType: string }>();
  categorias: CategoriaModel[];
  notaId: number = null;
  formatDate = formatDate;

  constructor(
    private lukeService: LukeService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal
  ) {
    this.route.params.subscribe((params) => {
      params['id'] ? (this.notaId = params['id']) : this.createForm();
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      titulo: [this.nota?.titulo, Validators.required],
      sintesis: this.nota?.sintesis,
      expedienteUrl: this.nota?.expedienteUrl,
      expedienteNumero: this.nota?.expedienteNumero,
      fechaIngreso: this.nota?.fechaIngreso,
      contenido: this.nota?.contenido,
      nivelGobiernoId: this.nota?.nivelGobiernoId,
      paisId: this.nota?.paisId,
      clientes: [
        this.nota?.clientes.map((cliente) => cliente.id) ?? [],
        Validators.required,
      ],
      temas: [
        this.nota?.temas.map((tema) => tema.id) ?? [],
        Validators.required,
      ],
      categorias: [
        this.nota?.categorias.map((cat) => cat.id),
        Validators.required,
      ],
      apRelacionados: [
        this.nota?.anteProyectosRelacionados?.map((ap) => ap.id) ?? [],
      ],
      leyesRelacionadas: [
        this.nota?.leyesRelacionadas?.map((ley) => ley.id) ?? [],
      ],
      proyectosRelacionados: [
        this.nota?.proyectosRelacionados?.map((proy) => proy.id) ?? [],
      ],
      normativasRelacionadas: [
        this.nota?.normativasRelacionadas?.map((norm) => norm.id) ?? [],
      ],
      isPublic: [this.nota?.isPublic ?? false, Validators.required],
    });

    this.form.controls['paisId'].valueChanges.subscribe((value) =>
      this.changeDependencies(value)
    );

    this.form.controls['clientes'].valueChanges.subscribe((value) => {
      this.changeCliente(value);
    });
  }

  getNota(id: number): Observable<any> {
    return this.lukeService.getNota(id).pipe(
      tap((res) => {
        this.nota = res;
        this.nota.fechaIngreso = formatDate(res?.fecha, 'yyyy-MM-dd', 'en-US');
        this.createForm();
        this.changeDependencies(res.paisId);
        this.changeCliente(res.clientes.map((val) => val.id));
      })
    );
  }

  ngOnInit(): void {
    this.loading = true;
    Swal.fire({ text: 'Cargando...' });
    Swal.showLoading();

    this.searchTerm$
      .pipe(
        debounceTime(800), // Add your preferred debounce time here in milliseconds
        distinctUntilChanged(
          (prev, curr) =>
            prev.term === curr.term && prev.entityType === curr.entityType
        )
      )
      .subscribe(({ term, entityType }) => {
        switch (entityType) {
          case 'anteproyectos':
            this.anteproyectosLoading = true;
            this.lukeService
              .getAnteproyectos({
                keyword: term,
                clienteId: this.form.get('clientes').value,
                paises: this.form.get('paisId').value,
              })
              .subscribe((res: PagedResponse<AnteProyectoModel>) => {
                this.anteproyectos = res.items;
                this.anteproyectosLoading = false;
              });
            break;
          case 'proyectos':
            this.proyectosLoading = true;
            this.lukeService
              .getProyectos({
                keyword: term,
                clienteId: this.form.get('clientes').value,
                paises: this.form.get('paisId').value,
              })
              .subscribe((res: PagedResponse<ProyectoModel>) => {
                this.proyectos = res.items;
                this.proyectosLoading = false;
              });
            break;
          case 'normativas':
            this.normativasLoading = true;
            this.lukeService
              .getNormativas({
                keyword: term,
                clienteId: this.form.get('clientes').value,
                paises: this.form.get('paisId').value,
              })
              .subscribe((res: PagedResponse<NormativaModel>) => {
                this.normativas = res.items;
                this.normativasLoading = false;
              });
            break;
          case 'leyes':
            this.leyesLoading = true;
            this.lukeService
              .getLeyes({
                keyword: term,
                clienteId: this.form.get('clientes').value,
                paises: this.form.get('paisId').value,
              })
              .subscribe((res: PagedResponse<LeyModel>) => {
                this.leyes = res.items;
                this.leyesLoading = false;
              });
            break;
        }
      });

    forkJoin({
      paises: this.lukeService.getPaises(),
      niveles: this.lukeService.getNiveles(),
      clientes: this.lukeService.getClientes(),
      categorias: this.lukeService.getCategorias(),
    })
      .pipe(
        tap((results: any) => {
          this.paises = results.paises;
          this.niveles = results.niveles;
          this.clientes = results.clientes;
          this.categorias = results.categorias;
          this.loading = false;
        }),
        concatMap(() => {
          if (this.notaId) {
            return this.getNota(this.notaId);
          } else {
            return of(null);
          }
        }),
        tap((nota) => {
          if (nota) {
            this.nota = nota;
            if (this.nota) {
              this.anteproyectos = [
                ...this.anteproyectos,
                ...this.nota.anteProyectosRelacionados,
              ];
              this.proyectos = [
                ...this.proyectos,
                ...this.nota.proyectosRelacionados,
              ];
              this.normativas = [
                ...this.normativas,
                ...this.nota.normativasRelacionadas,
              ];
              this.leyes = [...this.leyes, ...this.nota.leyesRelacionadas];
            }
          }
          Swal.close();
        })
      )
      .subscribe();

    if (this.modalInput) {
      this.getNota(this.modalInput).subscribe();
    }
  }

  search(event: any, entityType: string) {
    this.searchTerm$.next({ term: event.term, entityType: entityType });
  }

  trackById(item: any): any {
    return item.id;
  }

  changeDependencies(value) {
    this.lukeService
      .getClientesFiltered(value, 2, 1)
      .subscribe((res: ClienteModel[]) => {
        this.clientes = res;
      });
  }

  changeCliente(value) {
    if (!value || !this.form.get('paisId').value) {
      return;
    }
    showLoading();

    // const anteproyectos$ = this.lukeService
    //   .getAnteproyectos({
    //     clienteId: value,
    //     paises: [this.form.get('paisId').value],
    //   })
    //   .pipe(
    //     map((res: any) => {
    //       return res;
    //     })
    //   );

    // const proyectos$ = this.lukeService
    //   .getProyectos({
    //     clienteId: value,
    //     paises: [this.form.get('paisId').value],
    //   })
    //   .pipe(
    //     map((res: any) => {
    //       return res;
    //     })
    //   );

    // const leyes$ = this.lukeService
    //   .getLeyes({ clienteId: value, paises: [this.form.get('paisId').value] })
    //   .pipe(
    //     map((res: any) => {
    //       return res;
    //     })
    //   );

    // const normativas$ = this.lukeService
    //   .getNormativas({
    //     clienteId: value,
    //     paises: [this.form.get('paisId').value],
    //   })
    //   .pipe(
    //     map((res: any) => {
    //       return res;
    //     })
    //   );

    const temas$ = this.lukeService
      .getEntityByQueryParams('temas', { clienteId: value })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    zip(temas$)
      .pipe(finalize(() => Swal.close()))
      .subscribe(([temas]) => {
        this.temas = temas;
        // this.anteproyectos = anteproyectos;
        // this.leyes = leyes;
        // this.proyectos = proyectos;
        // this.normativas = normativas;
      });

    Swal.close();
  }

  changePublic(value) {
    this.form.controls['isPublic'].setValue(value);
  }

  submitForm() {
    if (this.form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Tenes que llenar todos los campos marcados con un asterisco (*).',
      });
      return;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando nota',
      icon: 'info',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    const formValue = this.form.value;
    let mappedValues = NotasMapper.mapToRequest(formValue);
    this.route.params.subscribe((params) => {
      if (params['id'] || this.modalInput) {
        mappedValues.id = params['id'] ?? this.modalInput;
        this.lukeService.putNota(mappedValues).subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else
            Swal.fire({
              title: 'Nota modificada',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                if (this.modalInput) {
                  this.activeModal.close('edited');
                } else {
                  this.router.navigateByUrl('/dashboard/notas');
                }
              },
            });
        });
      } else {
        this.lukeService.postNota(mappedValues).subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else {
            Swal.fire({
              title: 'Nota agregada',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                this.router.navigateByUrl('/dashboard/notas');
              },
            });
          }
        });
      }
    });
  }
}
