import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { ClienteComponent } from "./pages/cliente/cliente.component";
import { NgModule } from "@angular/core";
import { ClientesRoutingModule } from "./clientes-routing.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { UsuariosComponent } from "./components/usuarios/usuarios.component";
import { StaffComponent } from './components/staff/staff.component';




@NgModule({
  declarations: [
    ClientesComponent,
    ClienteComponent,
    UsuariosComponent,
    StaffComponent,
  ],
  exports: [],
  imports: [
    ClientesRoutingModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
  ]
})
export class ClientesModule { }
