<section id="basic-horizontal-layouts">
  <div class="row match-height">
    <div class="col-xl-12 col-md-12 col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ cliente?.id ? 'Editar' : 'Nuevo' }} Cliente
          </h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
              <div class="row">
                <div class="col-md-4">
                  <label for="title">Nombre del Cliente</label
                  ><span class="red">*</span>
                  <input
                    placeholder="Debe ingresar un nombre"
                    type="text"
                    formControlName="nombreCliente"
                    name="titulo"
                    class="form-control"
                    id="titulo"
                    required
                  />
                </div>
                <div class="col-md-4">
                  <label for="categorias">Categoria</label
                  ><span class="red">*</span>
                  <ng-select
                    formControlName="categorias"
                    name="categorias"
                    bindLabel="categorias"
                    placeholder="Selecciona alguna categoría"
                    appendTo="body"
                    multiple="true"
                    bindLabel="descripcionCat"
                    bindValue="id"
                    [items]="categorias"
                    [searchable]="true"
                    [clearable]="true"
                  >
                  </ng-select>
                </div>
                <div class="col-md-4">
                  <label for="estado">Estado</label>
                  <fieldset name="estado">
                    <button
                      *ngIf="isActive.value"
                      (click)="changeActive(false)"
                      class="btn btn-success"
                      type="button"
                    >
                      Activo
                    </button>
                    <button
                      *ngIf="!isActive.value"
                      (click)="changeActive(true)"
                      class="btn btn-danger"
                      type="button"
                    >
                      Inactivo
                    </button>
                  </fieldset>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-md-4">
                  <label>Servicios</label>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-xl-12">
                  <div class="accordion" id="accordionExample">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left"
                                type="button"
                                data-toggle="collapse"
                                data-target="#alertas"
                                aria-expanded="true"
                                aria-controls="alertas"
                              >
                                Alertas
                              </button>
                            </h2>
                          </div>
                          <div
                            id="alertas"
                            class="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(pais, 'alertas')
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="addRemoveAlerta(pais, 'alertas')"
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#newsletter"
                                aria-expanded="false"
                                aria-controls="newsletter"
                              >
                                Newsletter
                              </button>
                            </h2>
                          </div>
                          <div
                            id="newsletter"
                            class="collapse"
                            aria-labelledby="headingOne"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(pais, 'newsletter')
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="
                                      addRemoveAlerta(pais, 'newsletter')
                                    "
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#scraper"
                                aria-expanded="false"
                                aria-controls="scraper"
                              >
                                Scraper
                              </button>
                            </h2>
                          </div>
                          <div
                            id="scraper"
                            class="collapse"
                            aria-labelledby="scraper"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(pais, 'scraper')
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="addRemoveAlerta(pais, 'scraper')"
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#dashboard"
                                aria-expanded="false"
                                aria-controls="dashboard"
                              >
                                Dashboard
                              </button>
                            </h2>
                          </div>
                          <div
                            id="dashboard"
                            class="collapse"
                            aria-labelledby="dashboard"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(pais, 'dashboard')
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="addRemoveAlerta(pais, 'dashboard')"
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#dashboardNotas"
                                aria-expanded="false"
                                aria-controls="dashboardNotas"
                              >
                                Dashboard - Notas
                              </button>
                            </h2>
                          </div>
                          <div
                            id="dashboardNotas"
                            class="collapse"
                            aria-labelledby="dashboardNotas"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(pais, 'dashboardNotas')
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="
                                      addRemoveAlerta(pais, 'dashboardNotas')
                                    "
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#dashboardNormativas"
                                aria-expanded="false"
                                aria-controls="dashboardNormativas"
                              >
                                Dashboard - Normativas
                              </button>
                            </h2>
                          </div>
                          <div
                            id="dashboardNormativas"
                            class="collapse"
                            aria-labelledby="dashboardNormativas"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(
                                        pais,
                                        'dashboardNormativas'
                                      )
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="
                                      addRemoveAlerta(
                                        pais,
                                        'dashboardNormativas'
                                      )
                                    "
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card">
                          <div class="card-header" id="headingThree">
                            <h2 class="mb-0">
                              <button
                                class="btn btn-link btn-block text-left collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target="#dashboardProyectos"
                                aria-expanded="false"
                                aria-controls="dashboardProyectos"
                              >
                                Dashboard - Proyectos
                              </button>
                            </h2>
                          </div>
                          <div
                            id="dashboardProyectos"
                            class="collapse"
                            aria-labelledby="dashboardProyectos"
                            data-parent="#accordionExample"
                          >
                            <div class="card-body">
                              <div class="btn-toolbar" role="toolbar">
                                <div class="btn-group flex-wrap" role="group">
                                  <button
                                    type="button"
                                    class="btn btn-outline-success"
                                    [ngClass]="{
                                      active: objExists(
                                        pais,
                                        'dashboardProyectos'
                                      )
                                    }"
                                    *ngFor="let pais of paises"
                                    aria-pressed="true"
                                    (click)="
                                      addRemoveAlerta(
                                        pais,
                                        'dashboardProyectos'
                                      )
                                    "
                                  >
                                    {{ pais.name }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row" id="usuarios" *ngIf="this.cliente">
                <app-usuarios (usuarioMod)="handleUsuarioMod($event)" [isStaff]="false" [usuarios]="cliente?.usuariosRelacionados" [serviciosContratados]="cliente?.serviciosContratados" [serviciosDashboard]="cliente?.serviciosDashboard" [clienteId]="cliente?.id" class="col-12"></app-usuarios>
              </div>
              <div class="row mt-4 px-3 justify-content-end">
                  <div class="btn-group form-group" role="group">
                    <button
                    routerLink=".."
                    type="button"
                    class="btn btn-outline-secondary"
                  >
                    Volver
                  </button>
                    <button
                      type="button"
                      class="btn btn-outline-primary"
                      (click)="submitForm()"
                    >
                      Guardar Cambios
                    </button>

                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
