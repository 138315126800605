<h3>Envío de Alertas - Selección de Destinatarios</h3>
<button
  type="button"
  class="btn btn-primary btn-sm ml-1"
  (click)="navigateBack()"
>
  Volver
</button>
<hr />

<ng-container *ngIf="alertas.length > 0 && !viewingSent">
  <div class="row" *ngIf="!viewingSent">
    <button
      class="btn btn-primary btn-sm mr-2 ml-2 mb-2"
      [disabled]="selectedAlertas.length == alertas.length"
      (click)="selectAll()"
    >
      Seleccionar todas
    </button>
    <button
      class="btn btn-primary btn-sm mb-2"
      [disabled]="selectedAlertas.length < 1"
      (click)="unSelectAll()"
    >
      Deseleccionar todas
    </button>
    <button
      class="btn btn-primary btn-sm mr-2 ml-2 mb-2"
      [disabled]="selectedAlertas.length < 1"
      (click)="sendAllAlertas()"
    >
      Enviar todos los seleccionados
    </button>
  </div>
  <div
    *ngFor="let group of alertas | groupBy : 'recipient.clienteId' | keyvalue"
    class="row"
  >
    <div class="col-12 mb-1" *ngIf="!viewingSent">
      <div class="d-flex align-items-center">
        <h4 class="mb-0 mr-2">{{ getClientName(group.key) }}</h4>
        <button
          class="btn btn-secondary btn-sm"
          (click)="selectAllClient(group)"
        >
          Seleccionar todo el cliente
        </button>
        <button
          class="btn btn-secondary btn-sm ml-1"
          (click)="unSelectAllClient(group)"
        >
          Deseleccionar todo el cliente
        </button>
        <button
          class="btn btn-secondary btn-sm ml-1"
          (click)="openMultipleReplyToModal(group.key)"
        >
          Seleccionar remitentes para todo el cliente
        </button>
      </div>
    </div>
    <div *ngFor="let alerta of group.value" class="col-sm-3 card-container">
      <div class="card w-100 h-100">
        <div class="card-body">
          <h6 class="card-title">{{ alerta.entity.titulo }}</h6>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ alerta.entity.pais }}
          </h6>
          <div class="d-flex flex-column justify-content-around">
            <p class="card-text">
              Destinado a
              {{ alerta.recipient.nombre + alerta.recipient.apellido }}
            </p>
            <p class="card-text" *ngIf="viewingSent">
              Enviado el
              {{ alerta.sentAt | date : "dd/MM/yyyy HH:mm" }}
            </p>
          </div>
          <p class="card-text" *ngIf="!viewingSent">
            <a (click)="openReplyToModal(alerta)">Ver/Modificar remitentes</a>
          </p>
          <div class="d-flex justify-content-between" *ngIf="!viewingSent">
            <button
              type="button"
              (click)="selectAlerta(alerta)"
              *ngIf="!checkIfSelected(alerta)"
              class="btn btn-outline-success w-100 mr-2"
            >
              Seleccionar
            </button>
            <button
              type="button"
              (click)="removeAlerta(alerta)"
              *ngIf="checkIfSelected(alerta)"
              class="btn btn-outline-secondary w-100 mr-2"
            >
              Quitar
            </button>
            <button
              type="button"
              (click)="handleSendButtonClick(alerta)"
              class="btn btn-outline-info w-100"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</ng-container>

<ng-template #noAlertas *ngIf="!viewingSent && alertas.length == 0">
  <div class="emptyState" *ngIf="!isLoading">
    <span> No hay alertas pendientes de envío </span>
    <button type="button" (click)="syncAlertas()" class="btn btn-primary">
      Sincronizar
    </button>
  </div>
</ng-template>

<ng-template #viewingSent *ngIf="viewingSent && alertas.length > 0">
  <div *ngFor="let alerta of alertas" class="col-sm-3 card-container">
    <div class="card w-100 h-100">
      <div class="card-body">
        <h6 class="card-title">{{ alerta.entity.titulo }}</h6>
        <h6 class="card-subtitle mb-2 text-muted">
          {{ alerta.entity.pais }}
        </h6>

        <div
          class="d-flex flex-column justify-content-around"
          *ngIf="viewingSent"
        >
          <p class="card-text">
            Destinado a
            {{ alerta.recipient.nombre + alerta.recipient.apellido }}
          </p>
          <p class="card-text">
            Enviado el
            {{ alerta.sentAt | date : "dd/MM/yyyy HH:mm" }}
          </p>
        </div>
      </div>
    </div>
    <hr />
  </div>
</ng-template>
