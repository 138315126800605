import { Pipe, PipeTransform } from '@angular/core';
import { ScrapingModel } from '../../panel/models/scraping.model';

@Pipe({
  name: 'searchcountry'
})
export class SearchcountryPipe implements PipeTransform {

  transform(Pais: ScrapingModel[], searchValuev2: string): ScrapingModel[] {
    if (!Pais || !searchValuev2) {
      return Pais;
    }
    return Pais.filter(pais => pais.name.toLocaleLowerCase().includes(searchValuev2.toLocaleLowerCase()));
  }

}
