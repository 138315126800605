<div class="card-content" id="mainCard">
  <div class="card-body">
    <div class="table-responsive">
        <div class="input-group mb-3">
            <input [(ngModel)]="searchTerm" type="text" class="form-control" placeholder="Buscar o Crear Nuevo" aria-label="Buscar" aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button type="button" (click)="onSubmit(searchTerm)" [disabled]="!searchTerm" class="btn btn-light btn-md">Crear Nuevo</button>
            </div>
        </div>
      <table class="table">
        <thead>
          <tr>
            <th>Nombre/Descripcion</th>
            <th>Cliente(s)</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tema of temas | searchTemas: this.searchTerm; let i = index">
            <td *ngIf="i !== this.editingIndex">
              {{ tema.descripcionTema }}
            </td>
            <td *ngIf="i !== this.editingIndex">
              {{getClientesString(tema.temasClientes)}}
            </td>
            <td *ngIf="i == this.editingIndex">
              <input class="form-control" type="text" [(ngModel)]="tema.descripcionTema" />
            </td>
            <td *ngIf="i == this.editingIndex">
              <ng-select
              placeholder="Seleccioná algún cliente"
              appendTo="body"
              multiple="true"
              [items]="clientes"
              [searchable]="true"
              [clearable]="true"
              [(ngModel)]="tema.clientesRelacionados"
              name="clientes"
              bindLabel="nombreCliente"
              bindValue="id"
              >                
              </ng-select>
            </td>
            <td *ngIf="i !== this.editingIndex">
              <a (click)="this.editingIndex = i; this.oldDesc = tema.descripcionTema; this.oldClientesRelacionados = tema.clientesRelacionados"
                ><i
                  class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
                ></i
              ></a>
              <a (click)="onDelete(tema.id)"
                ><i
                  class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                ></i
              ></a>
            </td>
            <td *ngIf="i == this.editingIndex">
              <a (click)="onSave(tema)"
                ><i
                  class="badge-circle badge-circle-light-secondary bx bx-save font-medium-1 mr-1"
                ></i
              ></a>
              <a (click)="onCancel(tema)"
                ><i
                  class="badge-circle badge-circle-light-secondary bx bx-x font-medium-1"
                ></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
