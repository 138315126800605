import Swal from "sweetalert2";

function showLoading(msg='Cargando...') {
    Swal.fire({
      title: `${msg}`,
      icon: 'info',
      allowOutsideClick: false,
      didOpen: () =>{
        Swal.showLoading();
      }
    });
  }

export default showLoading;