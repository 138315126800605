export class NotasMapper {
  public static mapToRequest(formData) {
    let notaRequest = {
      id: formData?.id,
      titulo: formData?.titulo,
      sintesis: formData?.sintesis,
      expedienteUrl: formData?.expedienteUrl,
      expedienteNumero: formData?.expedienteNumero,
      fechaIngreso: formData?.fechaIngreso,
      contenido: formData?.contenido,
      nivelGobiernoId: formData?.nivelGobiernoId,
      paisId: formData?.paisId,
      clientes:
        formData?.clientes.map((cliente) => {
          return { id: cliente, nombreCliente: 'a' };
        }) ?? [],
      temas: formData?.temas.map((tema) => {
        return { id: tema, descripcionTema: 'a' };
      }) ?? [],
      categorias: formData?.categorias.map((cat) => {
        return { id: cat, descripcionCat: 'a' };
      }) ?? [],
      apRelacionados: formData?.apRelacionados ?? [],
      proyectosRelacionados: formData?.proyectosRelacionados ?? [],
      leyesRelacionadas: formData?.leyesRelacionadas ?? [],
      normativasRelacionadas: formData?.normativasRelacionadas ?? [],
      isPublic: formData?.isPublic ?? false,
      isTranslated: true
    };
    return notaRequest;
  }
}
