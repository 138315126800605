<div class="row" id="basic-table">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <!-- head -->
        <h5 class="card-title">ABM de Autores</h5>
        <div class="heading-elements">
          <ul class="list-group list-group-horizontal">
            <li class="list-group-item">
              <select
                placeholder="Selecciona algún pais"
                class="form-control"
                [(ngModel)]="selectedPais"
                (change)="changeDependencies(selectedPais.id)"
              >
                <option *ngFor="let pais of paises" [ngValue]="pais">
                  {{ pais.name }}
                </option>
              </select>
            </li>
            <li>
              <button
                type="button"
                class="btn btn-light btn-md"
                data-toggle="modal"
                data-target="#autorModal"
                style="margin-top: 10%"
                (click)="autoresForm()"
                [attr.disabled]="selectedPais == undefined ? 'disabled' : null"
              >
                Crear Nuevo
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-content" id="mainCard">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombre y Apellido</th>
                  <th>País</th>
                  <th>Bloque</th>
                  <th>Posicion</th>
                  <th>Camara</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let autor of autores; let i = index">
                  <td style="width: 33%">{{ autor.nombreApellido }}</td>
                  <td>{{ autor.pais.name }}</td>
                  <td>{{ getBloqueString(autor.bloqueId) }}</td>
                  <td>{{ getPosicionString(autor.posicion) }}</td>
                  <td>{{ autor.camara.nombreCamara }}</td>
                  <td>
                    <a href="{{ autor.perfilOficial }}"
                      ><i
                        class="badge-circle badge-circle-light-secondary bx bx-show font-medium-1 mr-1"
                      ></i
                    ></a>
                    <a
                      data-target="#autorModal"
                      data-toggle="modal"
                      (click)="editAutor(autor)"
                      ><i
                        class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
                      ></i
                    ></a>
                    <a (click)="onDelete(autor.id)"
                      ><i
                        class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                      ></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- AUTOR Modal -->
<div
  class="modal fade text-left"
  id="autorModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel1">Agregar Autor</h3>
        <button
          type="button"
          class="close rounded-pill"
          data-dismiss="modal"
          aria-label="Close"
          (click)="resetAutor()"
        >
          <i class="bx bx-x"></i>
        </button>
      </div>
      <form
        *ngIf="autorForm"
        [formGroup]="autorForm"
        (ngSubmit)="submitAutor()"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <label>Nombre y Apellido </label>
              <span class="red">*</span>
              <div class="form-group">
                <input
                  type="text"
                  placeholder=""
                  class="form-control"
                  formControlName="nombreApellido"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Bloque</label>
              <span class="red">*</span>
              <ng-select
                appendTo="body"
                [items]="bloques"
                [searchable]="true"
                [clearable]="true"
                formControlName="bloqueId"
                name="bloques"
                bindLabel="descripcionBloque"
                bindValue="id"
              >
              </ng-select>
            </div>
            <div class="col-md-6">
              <label>Posición</label>
              <span class="red">*</span>
              <ng-select
                [items]="posiciones"
                formControlName="posicion"
                bindLabel="name"
                bindValue="id"
                name="posicion"
              >
              </ng-select>
            </div>
            <div class="col-md-6">
              <label>Perfil Oficial</label>
              <input
                type="text"
                class="form-control"
                formControlName="perfilOficial"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>País</label>
              <span class="red">*</span>
              <select
                formControlName="pais"
                class="custom-select d-block w-100 form-control"
              >
                <option value="none">
                  Seleccione un pais en el menu principal
                </option>
                <option *ngFor="let pais of paises" [ngValue]="pais.id">
                  {{ pais.name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Cámara</label>
              <span class="red">*</span>
              <select
                formControlName="camaraId"
                class="custom-select d-block w-100"
              >
                <option *ngFor="let camara of camaras" [value]="camara.id">
                  {{ camara.nombreCamara }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-secondary"
          data-dismiss="modal"
          (click)="resetAutor()"
        >
          <i class="bx bx-x d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Cerrar</span>
        </button>
        <button
          type="button"
          class="btn btn-primary ml-1"
          data-dismiss="modal"
          [disabled]="this.autorForm && this.autorForm.invalid == true"
          (click)="submitAutor()"
        >
          <i class="bx bx-check d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Guardar</span>
        </button>
      </div>
    </div>
  </div>
</div>
