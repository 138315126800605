<div class="card-content" id="mainCard">
  <div class="card-body">
    <div class="table-responsive">
      <div class="d-flex mb-3 align-items-center">
        <input
          [(ngModel)]="searchTerm"
          type="text"
          class="form-control mr-3"
          placeholder="Buscar"
          aria-label="Buscar"
          aria-describedby="basic-addon2"
        />
        <div class="col-6 col-md-4 col-lg-3 col-xl-2">
          <app-upload (onUpload)="onUpload($event)"></app-upload>
        </div>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Acciones</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let file of files | filter : searchTerm"
            id="file-{{ cleanName(file.name) }}"
          >
            <td>
              <a (click)="onDelete(file.name)"
                ><i
                  class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                ></i
              ></a>
            </td>

            <td>
              <a href="{{ file.url }}" target="_blank">{{ file.url }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
