import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { UploadComponent } from '../shared/upload/upload.component';
import { AutoresComponent } from './autores/autores.component';
import { StaffComponent } from './clientes/components/staff/staff.component';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { ConfigViewComponent } from './configview/configview.component';
import { EmailHistoryComponent } from './email-history/email-history.component';
import { AnteProyectoComponent } from './entidades/anteproyectos/list/anteproyectos.component';
import { AnteProyectoShowComponent } from './entidades/anteproyectos/show/anteproyectos.component';
import { EntidadesComponent } from './entidades/entidades.component';
import { LeyesComponent } from './entidades/leyes/list/leyes.component';
import { LeyesShowComponent } from './entidades/leyes/show/leyes.component';
import { NormativasComponent } from './entidades/normativas/list/normativas.component';
import { NormativasShowComponent } from './entidades/normativas/show/normativas.component';
import { NotaComponent } from './entidades/notas/list/notas.component';
import { NotaShowComponent } from './entidades/notas/show/notas.component';
import { ProyectoComponent } from './entidades/proyecto/list/proyecto.component';
import { ProyectoShowComponent } from './entidades/proyecto/show/proyecto.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { PagesComponent } from './pages.component';
import { ScrapingTablesComponent } from './scraping/scraping-tables/scraping-tables.component';
import { ScrapingViewComponent } from './scraping/scraping-view/scraping-view.component';
import { ScrapingComponent } from './scraping/scraping.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'clientes',
        loadChildren: () =>
          import('./clientes/clientes.module').then((m) => m.ClientesModule),
      },
      { path: 'comisiones', component: ComisionesComponent },
      { path: 'autores', component: AutoresComponent },
      { path: 'entidades', component: EntidadesComponent },
      { path: 'uploads', component: UploadComponent },
      { path: 'normativas', component: NormativasComponent },
      { path: 'normativas/nuevo', component: NormativasShowComponent },
      { path: 'normativas/:id', component: NormativasShowComponent },
      { path: 'leyes', component: LeyesComponent },
      { path: 'leyes/nuevo', component: LeyesShowComponent },
      { path: 'leyes/:id', component: LeyesShowComponent },
      { path: 'anteproyectos', component: AnteProyectoComponent },
      { path: 'anteproyectos/nuevo', component: AnteProyectoShowComponent },
      { path: 'anteproyectos/:id', component: AnteProyectoShowComponent },
      { path: 'proyectos', component: ProyectoComponent },
      { path: 'proyectos/nuevo', component: ProyectoShowComponent },
      { path: 'proyectos/:id', component: ProyectoShowComponent },
      { path: 'notas', component: NotaComponent },
      { path: 'notas/nuevo', component: NotaShowComponent },
      { path: 'notas/:id', component: NotaShowComponent },
      { path: 'scraping', component: ScrapingComponent },
      { path: 'scraping/:id', component: ScrapingTablesComponent },
      { path: 'scraping/normativas/:id', component: ScrapingViewComponent },
      { path: 'scraping/proyectos/:id', component: ScrapingViewComponent },
      { path: 'file-upload', component: FileUploadComponent },
      { path: 'staff', component: StaffComponent },
      { path: 'newsletter', component: NewsletterComponent },
      {
        path: 'alertas',
        loadChildren: () =>
          import('./alertas/alertas.module').then((m) => m.AlertasModule),
      },
      { path: 'email-history', component: EmailHistoryComponent },
      { path: 'config', component: ConfigViewComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PanelRoutingModule {}
