<section id="basic-horizontal-layouts" *ngIf="!loading">
  <div class="row match-height">
    <div class="col-xl-12 col-md-12 col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Nuevo Proyecto</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label>País</label><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona algún pais"
                    appendTo="body"
                    [items]="paises"
                    [searchable]="true"
                    [clearable]="true"
                    name="pais"
                    formControlName="paisId"
                    bindLabel="name"
                    bindValue="id"
                    (change)="changeDependencies(this.form.get('paisId').value)"
                  >
                  </ng-select>
                </div>
                <div class="col-6 mb-2">
                  <label for="clientes">Clientes</label
                  ><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona algún cliente"
                    appendTo="body"
                    multiple="true"
                    [items]="clientes"
                    [closeOnSelect]="false"
                    [searchable]="true"
                    [clearable]="true"
                    name="clientes"
                    formControlName="clientes"
                    bindLabel="nombreCliente"
                    bindValue="id"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label for="title">Titulo</label><span class="red">*</span>
                  <input
                    placeholder="Debe ingresar un título válido"
                    type="text"
                    formControlName="titulo"
                    name="titulo"
                    class="form-control white-textbox"
                    id="titulo"
                    required
                  />
                </div>
                <div class="col-md-3 mb-2">
                  <label for="title">Numero de expediente</label
                  ><span class="red">*</span>
                  <input
                    placeholder="Numero de expediente"
                    type="text"
                    class="form-control white-textbox"
                    formControlName="expedienteNumero"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label for="distritos">Distrito</label>
                  <input
                    formControlName="distrito"
                    placeholder="Distritos"
                    type="text"
                    class="form-control white-textbox"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Sintesis</label
                    ><span class="red">*</span>
                    <textarea
                      formControlName="sintesis"
                      class="form-control white-textbox"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label>Expediente/Adjunto</label
                  ><small
                    ><a
                      (click)="
                        addExistingFileView(); this.expedienteFile = null
                      "
                    >
                      Buscar existente</a
                    ></small
                  ><small *ngIf="!this.newFileView"
                    ><a
                      (click)="
                        this.newFileView = true; this.expedienteFile = null
                      "
                      >/Volver</a
                    ></small
                  >
                  <div *ngIf="!this.newFileView">
                    <ng-select
                      *ngIf="existingFiles"
                      placeholder="Seleccioná una opción"
                      [items]="existingFiles"
                      bindLabel="name"
                      ngValue="file"
                      [(ngModel)]="expedienteFile"
                      [ngModelOptions]="{ standalone: true }"
                    ></ng-select>
                    <button
                      type="button"
                      class="btn btn-link ml-2 my-0 white-textbox"
                      *ngIf="expedienteFile"
                    >
                      <a href="{{ this.expedienteFile['url'] }}" target="_blank"
                        >Previsualizar</a
                      >
                    </button>
                  </div>
                  <div *ngIf="this.newFileView">
                    <input
                      *ngIf="
                        !this.form.get('expedienteUrl').value &&
                          !this.expedienteFile &&
                          this.newFileView;
                        else fileUploaded
                      "
                      type="file"
                      class="form-control"
                      (change)="this.expedienteFile = $event.target.files[0]"
                    />
                    <ng-template #fileUploaded>
                      <div
                        class="w-100 d-flex justify-content-between align-items-center"
                      >
                        <a
                          href="{{ this.proyecto?.expedienteUrl }}"
                          target="_blank"
                          *ngIf="
                            this.proyecto?.expedienteUrl &&
                              !this.expedienteFile &&
                              this.newFileView;
                            else generateFilePreview
                          "
                        >
                          Ver Archivo
                        </a>
                        <ng-template #generateFilePreview>
                          <button
                            type="button"
                            class="btn btn-link"
                            (click)="this.generateFileRead()"
                          >
                            Ver Archivo
                          </button>
                        </ng-template>
                        <button
                          type="button"
                          class="btn btn-light btn-sm ml-2 my-0"
                          (click)="
                            this.form.get('expedienteUrl').setValue(null);
                            this.expedienteFile = null
                          "
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <label for="categorias">Categoria</label
                  ><span class="red">*</span>
                  <ng-select
                    formControlName="categorias"
                    name="categorias"
                    bindLabel="categorias"
                    placeholder="Selecciona alguna categoría"
                    appendTo="body"
                    multiple="true"
                    bindLabel="descripcionCat"
                    bindValue="id"
                    [items]="categorias"
                    [searchable]="true"
                    [clearable]="true"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Tipo de Proyecto</label><span class="red">*</span>
                  <ng-select
                    placeholder="Tipo de proyecto"
                    appendTo="body"
                    bindLabel="descriptionTipo"
                    bindValue="id"
                    [items]="tipos"
                    [searchable]="true"
                    [clearable]="true"
                    name="tipoProyecto"
                    formControlName="proyectoTipoId"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Temas</label><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona algún tema"
                    appendTo="body"
                    multiple="true"
                    [items]="temas"
                    [searchable]="true"
                    [clearable]="true"
                    name="temas"
                    formControlName="temas"
                    bindLabel="descripcionTema"
                    bindValue="id"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="public">Publico/Privado</label>
                  <fieldset name="public">
                    <button
                      *ngIf="this.form.controls['isPublic'].value == true"
                      (click)="changePublic(false)"
                      class="btn btn-success"
                      type="button"
                    >
                      Publico
                    </button>
                    <button
                      *ngIf="this.form.controls['isPublic'].value == false"
                      (click)="changePublic(true)"
                      class="btn btn-danger"
                      type="button"
                    >
                      Privado
                    </button>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <label>Camara</label><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    bindLabel="nombreCamara"
                    bindValue="id"
                    formControlName="camaraId"
                    [items]="camaras"
                    (change)="
                      getComisionesByCamaraId(this.form.get('camaraId').value)
                    "
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Comisiones</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="nombre"
                    bindValue="id"
                    formControlName="comisiones"
                    [items]="comisiones"
                    [searchable]="true"
                    [clearable]="true"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Nivel de Gobierno</label><span class="red">*</span>
                  <ng-select
                    formControlName="nivelGobiernoId"
                    bindLabel="descripcionNG"
                    bindValue="id"
                    [items]="niveles"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Provincia</label>
                  <input
                    [disabled]="form.value.nivelGobierno != 2"
                    type="text"
                    class="form-control white-textbox"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <label>Notas Vinculadas</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="notas.items"
                    [trackByFn]="trackById"
                    [loading]="notasLoading"
                    (scrollToEnd)="scrollToEnd('notas')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="notasRelacionadas"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Anteproyectos Vinculados</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="anteproyectos.items"
                    [trackByFn]="trackById"
                    [loading]="anteproyectosLoading"
                    (scrollToEnd)="scrollToEnd('anteproyectos')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="anteproyectosAsociados"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Proyectos Vinculados</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="proyectos.items"
                    [trackByFn]="trackById"
                    [loading]="proyectosLoading"
                    (scrollToEnd)="scrollToEnd('proyectos')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="proyectosAsociados"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Leyes Vinculadas</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="leyes.items"
                    [trackByFn]="trackById"
                    [loading]="leyesLoading"
                    (scrollToEnd)="scrollToEnd('leyes')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="leyesAsociadas"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-3">
                  <label>Fecha de Ingreso</label><span class="red">*</span>
                  <input
                    type="date"
                    class="form-control white-textbox"
                    formControlName="fechaIngreso"
                  />
                </div>
                <div class="col-md-4 mb-3">
                  <label for="tiempos">Probabilidad de Avance</label
                  ><span class="red">*</span>
                  <ng-select
                    formControlName="probabilidadAvanceId"
                    bindValue="id"
                    bindLabel="value"
                    [items]="probAvance"
                  >
                  </ng-select>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="tiempos">Impacto en el Sector</label
                  ><span class="red">*</span>
                  <ng-select
                    [items]="impactoDict"
                    formControlName="impactoId"
                    bindValue="id"
                    bindLabel="value"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 mb-3">
                  <label>Autores</label><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona algún autor"
                    appendTo="body"
                    multiple="true"
                    bindLabel="nombreApellido"
                    bindValue="id"
                    [items]="autores"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="autores"
                    name="autores"
                  >
                  </ng-select>
                </div>
                <div class="col-md-2 mb-2">
                  <button
                    type="button"
                    class="btn btn-light btn-md"
                    data-toggle="modal"
                    data-target="#autorModal"
                    style="margin-top: 10%"
                    (click)="autoresForm()"
                  >
                    Agregar nuevo
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <label>Movimientos y Tramitaciones</label>
                </div>
                <div class="col-xl-8">
                  <button
                    type="button"
                    class="btn btn-light btn-sm pull-right"
                    data-toggle="modal"
                    data-target="#movimientoModal"
                    (click)="movimientoForm()"
                    style="margin-left: 85%; margin-bottom: 5px"
                  >
                    <span> Agregar Nuevo </span>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div formArrayName="movimientos">
                    <table class="table" id="movimientos">
                      <thead class="thead-dark">
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Tipo</th>
                          <th scope="col">Descripcion</th>
                          <th scope="col">Documentos Adjuntos</th>
                          <th scope="col">Enlace</th>
                          <th scope="col">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container>
                          <tr
                            *ngFor="
                              let movimiento of movimientos.controls;
                              let i = index
                            "
                          >
                            <td>
                              {{
                                formatDate(
                                  movimiento.value.fecha,
                                  "dd-MM-yyyy",
                                  "en-US"
                                )
                              }}
                            </td>
                            <td>
                              {{ movimiento.value.estado }}
                            </td>
                            <td>{{ movimiento.value.descripcion }}</td>
                            <td>Documento placeholder</td>
                            <td>{{ movimiento.value.link }}</td>
                            <td>
                              <div class="row">
                                <i
                                  class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
                                  (click)="editMov(movimiento)"
                                  data-toggle="modal"
                                  data-target="#movimientoModal"
                                ></i>
                                <i
                                  class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                                  (click)="delMov(movimiento.value.id, i)"
                                ></i>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="btn-group form-group" role="group">
                  <button type="button" class="btn btn-outline-primary">
                    Convertir en Proyecto
                  </button>
                  <button type="button" class="btn btn-outline-primary">
                    Convertir en Normativa
                  </button>
                  <button type="button" class="btn btn-outline-primary">
                    Convertir en Ley
                  </button>
                  <button type="button" class="btn btn-outline-primary">
                    Pasar a Archivo
                  </button>
                  <button type="button" class="btn btn-outline-primary">
                    Limpiar
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="submitForm()"
                  >
                    Guardar Cambios
                  </button>
                  <button
                    routerLink=".."
                    type="button"
                    class="btn btn-outline-primary"
                  >
                    Volver
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- MOVIMIENTO MODAL -->

<div
  class="modal fade text-left"
  id="movimientoModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Agregar Movimiento</h3>
        <button
          type="button"
          class="close rounded-pill"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cleanMov()"
        >
          <i class="bx bx-x"></i>
        </button>
      </div>
      <form *ngIf="movForm" [formGroup]="movForm" (ngSubmit)="submitMov()">
        <div class="modal-body">
          <div class="tab-content">
            <div
              class="tab-pane active"
              id="home-center"
              aria-labelledby="home-tab-center"
              role="tabpanel"
            >
              <div class="row">
                <div class="col col-md-6">
                  <label>Fecha</label><span class="red">*</span>
                  <input
                    class="form-control white-textbox"
                    type="date"
                    placeholder="Fecha"
                    formControlName="fecha"
                  />
                </div>
                <div class="col col-md-6">
                  <label>Tipo</label><span class="red">*</span>
                  <ng-select
                    formControlName="estado"
                    name="tipo"
                    bindLabel="tipo"
                    appendTo="body"
                    [searchable]="true"
                    [clearable]="true"
                  >
                    <ng-option
                      *ngFor="let estado of estados"
                      [value]="estado"
                      >{{ estado.descripcionEstado }}</ng-option
                    >
                  </ng-select>
                </div>
              </div>
              <div class="row" style="padding-top: 5px">
                <div class="col-xl-12 col-md-12 mb-2">
                  <div class="form-group">
                    <label>Descripcion</label><span class="red">*</span>
                    <textarea
                      class="form-control white-textbox"
                      rows="3"
                      formControlName="descripcion"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-md-12 mb-2">
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" />
                    <label class="custom-file-label white-textbox"
                      >Agregar Archivo</label
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-md-12 mb-2">
                  <label>Enlace</label>
                  <input
                    class="form-control white-textbox"
                    placeholder="Enlace"
                    formControlName="link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light-secondary"
            (click)="cleanMov()"
            data-dismiss="modal"
          >
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Cerrar</span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-1"
            data-dismiss="modal"
            (click)="submitMov()"
          >
            <i class="bx bx-check d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Agregar</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- AUTOR Modal -->
<div
  class="modal fade text-left"
  id="autorModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel1">Agregar Autor</h3>
        <button
          type="button"
          class="close rounded-pill"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="bx bx-x"></i>
        </button>
      </div>
      <form
        *ngIf="autorForm"
        [formGroup]="autorForm"
        (ngSubmit)="submitAutor()"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <label>Nombre y Apellido </label>
              <span class="red">*</span>
              <div class="form-group">
                <input
                  type="text"
                  placeholder=""
                  class="form-control"
                  formControlName="nombreApellido"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Bloque</label>
              <span class="red">*</span>
              <ng-select
                appendTo="body"
                [items]="bloques"
                [searchable]="true"
                [clearable]="true"
                formControlName="bloqueId"
                name="bloques"
                bindLabel="descripcionBloque"
                bindValue="id"
              >
              </ng-select>
            </div>
            <div class="col-md-6">
              <label>Posición</label>
              <span class="red">*</span>
              <ng-select
                [items]="posiciones"
                formControlName="posicion"
                bindLabel="name"
                bindValue="id"
                name="posicion"
              >
              </ng-select>
            </div>
            <div class="col-md-6">
              <label>Perfil Oficial</label>
              <input
                type="text"
                class="form-control"
                formControlName="perfilOficial"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>País</label>
              <select
                formControlName="pais"
                class="custom-select d-block w-100 form-control"
              >
                <option value="none">
                  Seleccione un pais en el menu principal
                </option>
                <option *ngFor="let pais of paises" [ngValue]="pais.id">
                  {{ pais.name }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label>Cámara</label>
              <span class="red">*</span>
              <select
                formControlName="camaraId"
                class="custom-select d-block w-100"
              >
                <option *ngFor="let camara of camaras" [value]="camara.id">
                  {{ camara.nombreCamara }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-secondary"
          data-dismiss="modal"
        >
          <i class="bx bx-x d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Cerrar</span>
        </button>
        <button
          type="button"
          class="btn btn-primary ml-1"
          data-dismiss="modal"
          [disabled]="this.autorForm && this.autorForm.invalid == true"
          (click)="submitAutor()"
        >
          <i class="bx bx-check d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Guardar</span>
        </button>
      </div>
    </div>
  </div>
</div>
