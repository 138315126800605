export class NormativasMapper {
  public static mapToRequest(formData) {
    let normativaRequest = {
      id: formData?.id ?? null,
      paisId: formData?.paisId,
      titulo: formData?.titulo,
      sintesis: formData?.sintesis,
      expedienteUrl: formData?.expedienteUrl,
      expedienteNumero: formData?.expedienteNumero,
      fechaIngreso: formData?.fechaPublicacion,
      organismoDictaNormativa: formData?.organismoDictaNormativa,
      nivelGobiernoId: formData?.nivelGobiernoId,
      impactoId: formData?.impactoTipoId,
      clientes:
        formData?.clientes?.map((cliente) => {
          return { id: cliente, nombreCliente: 'a' };
        }) ?? [],
      temas: formData?.temas?.map((tema) => {
        return { id: tema, descripcionTema: 'a' };
      }) ?? [],
      apRelacionados: formData?.apRelacionados,
      notasRelacionadas: formData?.notasRelacionadas,
      leyesRelacionadas: formData?.leyesRelacionados,
      isPublic: formData?.isPublic
    };
    return normativaRequest;
  }
}
