<section id="basic-horizontal-layouts" *ngIf="!loading">
  <div class="row match-height">
    <div class="col-xl-12 col-md-12 col-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Nueva Nota</h4>
        </div>
        <div class="card-content">
          <div class="card-body">
            <form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()">
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label>País</label><span class="red">*</span>
                  <select
                    formControlName="paisId"
                    class="custom-select d-block w-100 white-textbox"
                    required
                  >
                    <option value="defaultPais">Seleccione un país</option>
                    <option *ngFor="let pais of paises" [ngValue]="pais.id">
                      {{ pais.name }}
                    </option>
                  </select>
                </div>
                <div class="col-6 mb-2">
                  <label for="clientes">Clientes</label
                  ><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona algún cliente"
                    appendTo="body"
                    multiple="true"
                    [items]="clientes"
                    [searchable]="true"
                    [closeOnSelect]="false"
                    [clearable]="true"
                    name="clientes"
                    formControlName="clientes"
                    bindLabel="nombreCliente"
                    bindValue="id"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label for="title">Titulo</label><span class="red">*</span>
                  <input
                    placeholder="Debe ingresar un título válido"
                    type="text"
                    formControlName="titulo"
                    name="titulo"
                    class="form-control white-textbox"
                    id="titulo"
                    required
                  />
                </div>
                <div class="col-md-6 mb-3">
                  <label>Fecha</label><span class="red">*</span>
                  <input
                    type="date"
                    class="form-control white-textbox"
                    formControlName="fechaIngreso"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">Sintesis</label
                    ><span class="red">*</span>
                    <quill-editor
                      formControlName="sintesis"
                      id="sintesis"
                    ></quill-editor>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mb-2">
                  <div class="form-group">
                    <label for="contenido">Contenido</label
                    ><span class="red">*</span>
                    <quill-editor
                      formControlName="contenido"
                      id="contenido"
                    ></quill-editor>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <label for="categorias">Categoria</label
                  ><span class="red">*</span>
                  <ng-select
                    formControlName="categorias"
                    name="categorias"
                    bindLabel="categorias"
                    placeholder="Selecciona alguna categoría"
                    appendTo="body"
                    multiple="true"
                    bindLabel="descripcionCat"
                    bindValue="id"
                    [items]="categorias"
                    [searchable]="true"
                    [clearable]="true"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Temas</label><span class="red">*</span>
                  <ng-select
                    placeholder="Selecciona algún tema"
                    appendTo="body"
                    multiple="true"
                    [items]="temas"
                    [searchable]="true"
                    [clearable]="true"
                    name="temas"
                    formControlName="temas"
                    bindLabel="descripcionTema"
                    bindValue="id"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Link a la nota</label>
                  <input
                    type="text"
                    class="form-control white-textbox"
                    formControlName="expedienteUrl"
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label>Nivel de Gobierno</label><span class="red">*</span>
                  <ng-select
                    formControlName="nivelGobiernoId"
                    bindLabel="descripcionNG"
                    bindValue="id"
                    [items]="niveles"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <label>Normativas Vinculadas</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="normativas"
                    [trackByFn]="trackById"
                    [loading]="normativasLoading"
                    (search)="search($event, 'normativas')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="normativasRelacionadas"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Anteproyectos Vinculados</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [trackByFn]="trackById"
                    [loading]="anteproyectosLoading"
                    [items]="anteproyectos"
                    (search)="search($event, 'anteproyectos')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="apRelacionados"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Proyectos Vinculados</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="proyectos"
                    [trackByFn]="trackById"
                    [loading]="proyectosLoading"
                    (search)="search($event, 'proyectos')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="proyectosRelacionados"
                  >
                  </ng-select>
                </div>
                <div class="col-md-3 mb-3">
                  <label>Leyes Vinculadas</label>
                  <ng-select
                    placeholder="Selecciona alguna opción"
                    appendTo="body"
                    multiple="true"
                    bindLabel="titulo"
                    bindValue="id"
                    [items]="leyes"
                    [trackByFn]="trackById"
                    [loading]="leyesLoading"
                    (search)="search($event, 'leyes')"
                    [searchable]="true"
                    [clearable]="true"
                    formControlName="leyesRelacionadas"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 mb-3">
                  <label>Numeración Interna</label>
                  <input
                    type="text"
                    class="form-control white-textbox"
                    formControlName="expedienteNumero"
                    matTooltip="Para usarse en caso de notas con títulos idénticos."
                  />
                </div>
                <div class="col-md-3 mb-3">
                  <label for="public">Publico/Privado</label>
                  <fieldset name="public">
                    <button
                      *ngIf="this.form.controls['isPublic'].value == true"
                      (click)="changePublic(false)"
                      class="btn btn-success"
                      type="button"
                    >
                      Publico
                    </button>
                    <button
                      *ngIf="this.form.controls['isPublic'].value == false"
                      (click)="changePublic(true)"
                      class="btn btn-danger"
                      type="button"
                    >
                      Privado
                    </button>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="btn-group form-group" role="group">
                  <button type="button" class="btn btn-outline-primary">
                    Limpiar
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="submitForm()"
                  >
                    Guardar Cambios
                  </button>
                  <button
                    routerLink=".."
                    type="button"
                    class="btn btn-outline-primary"
                    *ngIf="!this.modalInput"
                  >
                    Volver
                  </button>
                  <button
                    (click)="this.activeModal.dismiss()"
                    *ngIf="this.modalInput"
                    type="button"
                    class="btn btn-outline-primary"
                  >
                    Cerrar sin cambios
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
