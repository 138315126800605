import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { NewsletterDetailedResponse } from 'src/app/panel/models/newsletter-detailed.interface';
import { imageMap } from 'src/utils/imageMap';

@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.css'],
  host: {
    '[style.overflow]': '"auto"',
  },
})
export class EmailPreviewComponent implements OnInit {
  @Input() entityData: NewsletterDetailedResponse;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faLinkedinIn = faLinkedinIn;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faEnvelope = faEnvelope;
  imageMap = imageMap;
  dateRange: string;
  @Output() backDataEvent = new EventEmitter<any>();

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    let dates = this.entityData.notas.map((nota) => {
      return nota.fecha ? new Date(nota.fecha) : null;
    });

    dates = dates.filter(Boolean);

    let oldestDate = new Date(Math.min(...dates.map((date) => date.getTime())));
    let currentDate = new Date();

    this.dateRange = `${this.formatDate(oldestDate)} al ${this.formatDate(
      currentDate
    )}`;
  }

  parseDate(fecha) {
    return formatDate(fecha, 'dd-MM-yyyy', 'en-US');
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('es', options);
  }
}
