import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateRangeFilter'
})
export class DateRangeFilterPipe implements PipeTransform {

  transform(items: any[], startDate: Date, endDate: Date): any[] {
    if (!items || !Array.isArray(items) || !startDate || !endDate) {
      return items;
    }

    return items.filter(item => {
      const fechaIngreso = new Date(item.fechaIngreso);
      return fechaIngreso >= startDate && fechaIngreso <= endDate;
    });
  }

}