import { Component, OnInit } from '@angular/core';
import { TemaModel } from '../entidades/models/tema.model';
import Swal from 'sweetalert2';
import { ClienteModel } from '../entidades/models/clientes.model';
import { S3UploadService } from 'src/app/core/services/s3-upload.service';
import { AlertasFile } from '../entidades/models/alertasFile.interface';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent implements OnInit {

  files: AlertasFile[];

  upload = false;
  temas: TemaModel[];
  editingIndex = -1;
  oldDesc: string;
  oldClientesRelacionados: number[];
  searchTerm: string;
  clientes: ClienteModel[];

  constructor(
    private s3Service: S3UploadService,
    ) {}

  ngOnInit(): void {
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();
    this.s3Service.getUploadedFiles().subscribe((res) => {
      this.files = res.Contents.map((file) => {
        return {
          name: file.Key,
          url: `https://alertas-v2.directoriolegislativo.org/${file.Key}`,
          lastModified: new Date(file.LastModified),
        };
      });
      Swal.close();
    });

  }



  onUpload(event: AlertasFile) {
    this.files = [event, ...this.files];
    Swal.fire({
      icon: 'success',
      title: 'Archivo subido',
      text: 'El archivo fue subido correctamente',
    }).then(() => {
      const element = document.getElementById(`file-${this.cleanName(event.name)}`);
      element.scrollIntoView({ behavior: 'smooth' });
      element.style.backgroundColor = '#020406';
      setTimeout(() => {
        element.style.backgroundColor = 'transparent';
      }, 5000);
    });
  }

  cleanName(name: string) {
    return name.replace(' ', '-').toLowerCase();
  }

  onDelete(name: string) {
    Swal.fire({
      title: 'Borrar archivo',
      text: '¿Está seguro que quiere borrar este archivo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.s3Service.deleteFile(name).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'El archivo fue borrado', 'success');
            this.files = this.files.filter((file) => file.name !== name);
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo',
            });
          }
        );
      } else {
        return;
      }
    });
  }

  parseDate(fecha) {
    return formatDate(
      fecha,
      'dd-MM-yyyy',
      'en-US'
    );
  }
}
