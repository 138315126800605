export const imageMap: { [key: string]: string } = {
    'Argentina': 'Argentina',
    'Bolivia': 'Bolivia',
    'Brasil': 'Brazil',
    'Chile': 'Chile',
    'Colombia': 'Colombia',
    'Costa Rica': 'CostaRica',
    'Cuba': 'Cuba',
    'Ecuador': 'Ecuador',
    'El Salvador': 'ElSalvador',
    'Estados Unidos de America': 'EstadosUnidos',
    'Guatemala': 'Guatemala',
    'Guyana': 'Guyana',
    'Honduras': 'Honduras',
    'Jamaica': 'Jamaica',
    'México': 'Mexico',
    'Nicaragua': 'Nicaragua',
    'Panamá': 'Panama',
    'Paraguay': 'Paraguay',
    'Perú': 'Peru',
    'Puerto Rico': 'PuertoRico',
    'República Dominicana': 'RepublicaDominicana',
    'Sin Pais': 'SinPais',
    'Suriname': 'Suriname',
    'Trinidad y Tobago': 'Trinidad_Tobago',
    'Uruguay': 'Uruguay',
    'Venezuela': 'Venezuela',
    'Internacionales': 'Internacionales'
  };
  