import { Component, OnInit } from '@angular/core';
import { faScaleBalanced, faGavel, faRectangleList, faChartLine } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-entidades',
  templateUrl: './entidades.component.html',
  styleUrls: ['./entidades.component.css']
})
export class EntidadesComponent implements OnInit {

  constructor() { }
  faScale = faScaleBalanced;
  faGavel = faGavel;
  faRectangleList = faRectangleList;
  faFileArchive = faChartLine;
  ngOnInit(): void {
  }

}
