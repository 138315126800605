import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { CookieService } from 'ngx-cookie-service'
import { Observable } from "rxjs"
import { environment } from "src/environments/environment"


interface LoginResponse {
    access_token: string
    refresh_token: string
}

@Injectable({
    providedIn: "root"
})
export class LoginService {
    constructor(
      private httpClient: HttpClient,
      private cookieService: CookieService,
    ) { }

    private url = "/Auth/"

    private backend_url = environment.backend_url + this.url

    login(email: string, password: string): Observable<LoginResponse> {
        return this.httpClient.post<LoginResponse>(this.backend_url, { email, password })
    }

    refreshToken(): Observable<LoginResponse> {
      const refreshToken = this.cookieService.get('refreshToken')
      const headers = new HttpHeaders({
        Authorization: `Bearer ${refreshToken}`
      })
      return this.httpClient.post<LoginResponse>(this.backend_url + 'refresh', {}, {
        headers,
        })
    }
}
