// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  scraper_url: 'https://scrapper.directoriolegislativo.org/scrapper/api',
  backend_url: 'https://dev.luke.directoriolegislativo.org/api',
  client_frontend_url: 'https://d3.directoriolegislativo.org/#',
  s3AccessKeyId: 'AKIA3LL25G5L6GN2FDEI',
  s3SecretKey: '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',
  mailer_url: 'https://dev.mailer.api.directoriolegislativo.org',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
