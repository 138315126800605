import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { LoginService } from '../services/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private loginService: LoginService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let token = this.cookieService.get('token');
    if (req.url.includes('/Auth/') && !token) {
      return next.handle(req);
    }
    if (!token && !this.isRefreshing) {
      this.isRefreshing = true;
      return this.loginService.refreshToken().pipe(
        switchMap((response) => {
          this.isRefreshing = false;
          token = response.access_token;
          const tokenPayload = JSON.parse(atob(token.split('.')[1]));
          const expirationDate = new Date(tokenPayload.exp * 1000);
          this.cookieService.set('token', token, expirationDate);
          const cloned = this.addToken(req, token);
          return next.handle(cloned);
        }),
        catchError(this.handleError)
      );
    } else if (token) {
      const cloned = this.addToken(req, token);
      return next.handle(cloned).pipe(
        catchError(this.handleError)
      );
    } else {
      return next.handle(req);
    }
  }

  private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.isRefreshing = false;
    console.log(error);
    if (error.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("user-data");
      this.cookieService.delete('token');
      this.cookieService.delete('refreshToken');
      this.router.navigate(["/login"]);
    }
    return throwError(error);
  }
}
