export interface EmailActivityQP {
  to_email?: string;
  status?: EmailActivityStatus;
  template_id?: string;
  subject?: string;
  start_date?: string;
  end_date?: string;
}

export enum EmailActivityStatus {
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED',
  PROCESSING = 'PROCESSING',
}
