import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/core/services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor(
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private cookieService: CookieService,
  ) {}

  showLoading() {
    Swal.fire({
      title: 'Ingresando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.showLoading();

    this.loginService
      .login(this.form.value.email!, this.form.value.password!)
      .subscribe(
        (response) => {
          if (response.access_token) {
            const tokenPayload = JSON.parse(
              atob(response.access_token.split('.')[1])
            );
            if (
              tokenPayload.project === 'dashboard-v3' &&
              tokenPayload.role === 1
            ) {
              this.cookieService.set('token', response.access_token, new Date(tokenPayload.exp * 1000));
              this.cookieService.set('refreshToken', response.refresh_token, 7);
              this.router.navigate(['/dashboard/entidades']);
            } else {
              this.form.setErrors({
                unauthorized: true,
              });
            }

            Swal.close();
          }
        },
        (error) => {
          this.form.setErrors({
            invalidCredentials: true,
          });

          Swal.close();
        }
      );
  }
}
