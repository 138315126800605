<div class="row" id="basic-table">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <!-- head -->
        <h5 class="card-title">{{ title }}</h5>
        <!-- Single Date Picker and button -->
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li></li>
            <li>
              <button
                class="btn btn-primary"
                type="button"
                data-toggle="collapse"
                data-target="#filtrar"
                aria-expanded="false"
                aria-controls="filtrar"
              >
                Filtrar
              </button>
            </li>
            <li class="ml-2">
              <button
                class="btn btn-success round"
                routerLink="/dashboard/notas/nuevo"
              >
                Crear Nuevo
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="collapse" id="filtrar" style="margin-top: 30px">
        <form *ngIf="form" [formGroup]="form">
          <div class="card card-body">
            <div class="row">
              <div class="col-md-3 mb-3">
                <label>Cliente(s)</label>
                <ng-select
                  appendTo="body"
                  [items]="clientes"
                  [searchable]="true"
                  [clearable]="true"
                  [multiple]="true"
                  formControlName="clienteId"
                  name="clientes"
                  bindLabel="nombreCliente"
                  bindValue="id"
                >
                </ng-select>
              </div>
              <div class="col-md-3 mb-3">
                <label>País</label>
                <ng-select
                  appendTo="body"
                  [items]="paises"
                  [searchable]="true"
                  [clearable]="true"
                  [multiple]="true"
                  formControlName="paises"
                  name="paises"
                  bindLabel="name"
                  bindValue="id"
                >
                </ng-select>
              </div>
              <div class="col-md-3 mb-3">
                <label>Titulo</label>
                <input
                  type="text"
                  formControlName="titulo"
                  name="titulo"
                  class="form-control white-textbox"
                  id="titulo"
                />
              </div>
              <div class="col-md-3 mb-3">
                <label>Palabra Clave</label>
                <input
                  type="text"
                  formControlName="keyword"
                  name="keyword"
                  class="form-control white-textbox"
                  id="keyword"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-1 mb-1">
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="applyFilter()"
                >
                  Aplicar
                </button>
              </div>
              <div class="col-md-1 mb-1">
                <button
                  class="btn btn-primary"
                  type="button"
                  [disabled]="!this.form.value"
                  (click)="resetFilter()"
                >
                  Reestablecer
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-content">
        <div class="card-body">
          <!-- <p class="card-text">Using the most basic table up, here’s how
                        <code>.table</code>-based tables look in Bootstrap. You can use any example of below table for your table and it can be use with any type of bootstrap tables.</p>
                    <p><span class="text-bold-600">Example 1:</span> Table with outer spacing</p> -->
          <!-- Table with outer spacing -->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Acciones</th>
                  <th>Título</th>
                  <th scope="col" sortable="PaisId" (sort)="onSort($event)">
                    País<i
                      *ngIf="sortOrder == 'asc' && sortBy == 'PaisId'"
                      class="bx bx-chevron-up"
                    ></i
                    ><i
                      *ngIf="sortOrder == 'desc' && sortBy == 'PaisId'"
                      class="bx bx-chevron-down"
                    ></i>
                    <span *ngIf="sortBy != 'PaisId'"> -</span>
                  </th>
                  <th
                    scope="col"
                    sortable="FechaIngreso"
                    (sort)="onSort($event)"
                  >
                    Fecha
                    <i
                      *ngIf="sortOrder == 'asc' && sortBy == 'FechaIngreso'"
                      class="bx bx-chevron-up"
                    ></i
                    ><i
                      *ngIf="sortOrder == 'desc' && sortBy == 'FechaIngreso'"
                      class="bx bx-chevron-down"
                    ></i>
                    <span *ngIf="sortBy != 'FechaIngreso'"> -</span>
                  </th>
                  <th>Tema(s)</th>
                  <th scope="col" sortable="ClienteId" (sort)="onSort($event)">
                    Cliente(s)<i
                      *ngIf="sortOrder == 'asc' && sortBy == 'ClienteId'"
                      class="bx bx-chevron-up"
                    ></i
                    ><i
                      *ngIf="sortOrder == 'desc' && sortBy == 'ClienteId'"
                      class="bx bx-chevron-down"
                    ></i>
                    <span *ngIf="sortBy != 'ClienteId'"> -</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let nota of notas; let i = index">
                  <td>
                    <div class="action-badges">
                      <a href="{{ getFrontendUrl(nota.id) }}"
                        ><i
                          class="badge-circle badge-circle-light-secondary bx bx-show font-medium-1 mr-1"
                        ></i
                      ></a>
                      <a [routerLink]="['/dashboard/notas', nota.id]"
                        ><i
                          class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
                        ></i
                      ></a>
                      <a (click)="onDelete(nota.id)"
                        ><i
                          class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                        ></i
                      ></a>
                    </div>
                  </td>
                  <td>{{ nota.titulo }}</td>
                  <td>{{ nota.pais }}</td>
                  <td>{{ parseDate(nota.fecha) }}</td>
                  <td>{{ parseTemas(nota.temas) }}</td>
                  <td>{{ parseClientes(nota.clientes) }}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <ngb-pagination
                [collectionSize]="totalCount"
                (pageChange)="navigate($event)"
                [pageSize]="pageSize"
                [(page)]="pageNumber"
                [maxSize]="5"
                [rotate]="true"
                [boundaryLinks]="true"
              ></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
