export class LeyMapper {
  public static mapToRequest(formData) {
    let leyRequest = {
      id: formData?.id ?? 0,
      titulo: formData?.titulo,
      sintesis: formData?.sintesis,
      expedienteUrl: formData?.expedienteUrl,
      expedienteNumero: formData?.expedienteNumero,
      fechaIngreso: formData?.fechaIngreso,
      impactoId: formData?.impacto,
      nivelGobiernoId: formData?.nivelGobierno,
      paisId: formData?.pais,
      //provinciaId
      clientes:
        formData?.clientes.map((cliente) => {
          return { id: cliente, nombreCliente: 'a' };
        }) ?? [],
      temas: formData?.temas.map((tema) => {
        return { id: tema, descripcionTema: 'a' };
      }),
      apRelacionados: formData?.anteproyectosRelacionados,
      notasRelacionadas: formData?.notasRelacionadas,
      leyesRelacionadas: formData?.leyesRelacionadas,
      normativasRelacionadas: formData?.normativasRelacionadas,
      proyectosRelacionados: formData?.proyectosRelacionados,
      isPublic: formData?.isPublic
    };

    return leyRequest;
  }
}
