<section id="default-chips">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div
            class="row bg-primary bg-lighten-5 rounded mb-2 mx-25 text-center"
          >
            <div class="col-12 col-sm-12 p-2">
              <h6 class="text-primary mb-0">
                <span class="font-large-1 align-middle">{{
                  entidad?.titulo
                }}</span>
              </h6>
            </div>
          </div>
          <div class="col-12" *ngIf="this.type == 1">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td>Síntesis:</td>
                  <td class="users-view-username">{{ entidad?.sintesis }}</td>
                </tr>
                <tr>
                  <td>Cámara:</td>
                  <td class="users-view-username">
                    {{
                      camarasArray[entidad?.camaraId]?.nombreCamara
                        ? camarasArray[entidad?.camaraId]?.nombreCamara
                        : "-"
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Autores:</td>
                  <td class="users-view-username">
                    <span *ngFor="let autor of entidad?.proyectosAutores"
                      >{{ autor.autor.nombreApellido }} |
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Fecha de Ingreso:</td>
                  <td class="users-view-name">
                    {{
                      entidad?.fechaIngreso
                        ? parseDate(entidad?.fechaIngreso)
                        : parseDate(entidad?.fechaPublicacion)
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Fecha de modificacion:</td>
                  <td
                    class="users-view-email"
                    *ngIf="entidad?.fechaUltimaModificacion"
                  >
                    {{ parseDate(entidad?.fechaUltimaModificacion) }}
                  </td>
                  <td
                    class="users-view-email"
                    *ngIf="!entidad?.fechaUltimaModificacion"
                  >
                    No hubo modificaciones/Campo no disponible.
                  </td>
                </tr>
                <tr>
                  <td>Número de expediente:</td>
                  <td>
                    {{
                      entidad?.expedienteNumero
                        ? entidad?.expedienteNumero
                        : entidad?.numeroNormativa
                    }}
                  </td>
                </tr>
                <tr>
                  <td>Expediente:</td>
                  <td>
                    <a
                      target="_blank"
                      [attr.href]="
                        entidad?.expedienteUrl
                          ? entidad?.expedienteUrl
                          : entidad?.link
                      "
                      >Link</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12" *ngIf="this.type == 2">
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <td>Titulo:</td>
                  <td class="users-view-username">{{ entidad?.titulo }}</td>
                </tr>
                <tr>
                  <td>Numero de Normativa:</td>
                  <td class="users-view-username">
                    {{ entidad?.numeroNormativa }}
                  </td>
                </tr>
                <tr>
                  <td>Contenido:</td>
                  <td>
                    <small id="passwordHelpBlock" class="form-text text-muted">
                      Este contenido no se pasara a la base. Si se desea
                      conservar, se debe copiar en un lugar externo.
                    </small>
                  </td>
                </tr>
                <tr>
                  <td>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#contenidoNormativa"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      Mostrar/Esconder contenido.
                    </button>
                  </td>
                  <div class="collapse" id="contenidoNormativa">
                    <div class="card card-body">
                      <p class="text-justify">
                        {{ entidad?.contenido }}
                      </p>
                    </div>
                  </div>
                </tr>
                <tr>
                  <td>Organismo que dicta:</td>
                  <td class="users-view-username">
                    {{ entidad?.organismoDictaNormativa }}
                  </td>
                </tr>
                <tr>
                  <td>Fecha de publicacion:</td>
                  <td class="users-view-name">
                    {{
                      entidad?.fechaPublicacion
                        ? parseDate(entidad?.fechaPublicacion)
                        : parseDate(entidad?.fechaPublicacion)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <button
              class="btn mb-1 btn-danger btn-lg btn-block"
              (click)="uploadEntidad()"
            >
              Subir a la base
            </button>
            <button
              class="btn mb-1 btn-success btn-lg btn-block"
              routerLink="/dashboard/scrapingv3"
            >
              Volver
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
