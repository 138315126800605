<div class="card">
  <div class="card-header">
    <h5 class="card-title">Historial de envío de correos (SendGrid)</h5>
    <div class="heading-elements">
      <ul class="list-inline mb-0">
        <li>
          <button
            class="btn btn-primary"
            type="button"
            data-toggle="collapse"
            data-target="#filtrar"
            aria-expanded="false"
            aria-controls="filtrar"
          >
            Filtrar
          </button>
        </li>
      </ul>
    </div>
    <div class="collapse" id="filtrar" style="margin-top: 30px">
      <form *ngIf="filterForm" [formGroup]="filterForm">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-3 mb-3">
              <label>Producto</label>
              <ng-select
                appendTo="body"
                [items]="productos"
                formControlName="template_id"
                name="productos"
                bindLabel="value"
                bindValue="id"
              >
              </ng-select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Destinatario</label>
              <input
                type="text"
                class="form-control"
                formControlName="to_email"
              />
            </div>
            <div class="col-md-3 mb-3">
              <label>Estado</label>
              <ng-select
                [items]="emailStatus"
                bindLabel="value"
                bindValue="id"
              ></ng-select>
            </div>
            <div class="col-md-3 mb-3">
              <label>Asunto</label>
              <input
                type="text"
                formControlName="subject"
                class="form-control"
              />
            </div>
            <div class="col-md-3 mb-3">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="startDateSearchPicker"
                  formControlName="start_date"
                  placeholder="desde"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startDateSearchPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDateSearchPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="endDateSearchPicker"
                  formControlName="end_date"
                  placeholder="hasta"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="endDateSearchPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endDateSearchPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-primary"
                type="button"
                (click)="applyFilter()"
              >
                Aplicar
              </button>
            </div>
            <div class="col-md-1 mb-1">
              <button
                class="btn btn-primary"
                type="button"
                [disabled]="!this.filterForm.value"
                (click)="resetFilter()"
              >
                Reestablecer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="card-body"
    *ngIf="!isLoading && sentEmails.length > 0; else noResults"
  >
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Remitente</th>
            <th scope="col">Destinatario</th>
            <th scope="col">Asunto</th>
            <th scope="col">Estado</th>
            <th scope="col">Ult. Interacción</th>
            <th scope="col">Veces abierto</th>
            <th scope="col">Clicks</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let email of filteredEmails">
            <td>{{ email.from_email }}</td>
            <td>{{ email.to_email }}</td>
            <td>{{ email.subject }}</td>
            <td>{{ email.status }}</td>
            <td>{{ email.last_event_time | date : "dd-MM-yyyy HH:mm:ss" }}</td>
            <td>{{ email.opens_count }}</td>
            <td>{{ email.clicks_count }}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <ngb-pagination
          [collectionSize]="totalCount"
          (pageChange)="onPageChange($event)"
          [pageSize]="pageSize"
          [(page)]="pageNumber"
          [maxSize]="5"
          [rotate]="true"
          [boundaryLinks]="true"
        ></ngb-pagination>
      </div>
    </div>
  </div>
  <ng-template #noResults>
    <div class="card-body" *ngIf="!isLoading">
      <span>No se encontraron resultados para esta búsqueda.</span>
    </div>
  </ng-template>
</div>
