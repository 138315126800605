<ng-container>
    <div class="modal-header">
      <h4 class="modal-title">Remitentes</h4>
      <button type="button" class="close" (click)="dismissModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Cliquea en los usuarios para seleccionarlos. El mail saldrá desde tu casilla pero cuando el destinatario quiera contestar el mail, contestará a los usuarios que elijas en esta pantalla.
      </p>
      <table class="table table-hover">
        <tbody>
          <tr *ngFor="let usuario of usuarios" (click)="toggleUserSelection(usuario)">
            <td>
              <span [ngClass]="{'selected-user': this.selectedUsuarios && checkIfSelected(usuario)}">
                {{ usuario.nombre }} {{ usuario.apellido }} ({{ usuario.email }})
              </span>
              <span *ngIf="this.selectedUsuarios && checkIfSelected(usuario)" class="checkmark">&#10004;</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="closeModal()">Guardar</button>
    </div>
  </ng-container>
  