<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html
  data-editor-version="2"
  class="sg-campaigns"
  xmlns="http://www.w3.org/1999/xhtml"
>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
    />
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <!--<![endif]-->
    <!--[if (gte mso 9)|(IE)]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <!--[if (gte mso 9)|(IE)]>
      <style type="text/css">
        body {
          width: 600px;
          margin: 0 auto;
        }
        table {
          border-collapse: collapse;
        }
        table,
        td {
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
        img {
          -ms-interpolation-mode: bicubic;
        }
      </style>
    <![endif]-->
    <style type="text/css">
      body,
      p,
      div {
        font-family: arial, helvetica, sans-serif;
        font-size: 14px;
      }
      body {
        color: #000000;
      }
      body a {
        color: #000000;
        text-decoration: none;
      }
      p {
        margin: 0;
        padding: 0;
      }
      table.wrapper {
        width: 100% !important;
        table-layout: fixed;
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
      img.max-width {
        max-width: 100% !important;
      }
      .column.of-2 {
        width: 50%;
      }
      .column.of-3 {
        width: 33.333%;
      }
      .column.of-4 {
        width: 25%;
      }
      ul ul ul ul {
        list-style-type: disc !important;
      }
      ol ol {
        list-style-type: lower-roman !important;
      }
      ol ol ol {
        list-style-type: lower-latin !important;
      }
      ol ol ol ol {
        list-style-type: decimal !important;
      }
      @media screen and (max-width: 480px) {
        .preheader .rightColumnContent,
        .footer .rightColumnContent {
          text-align: left !important;
        }
        .preheader .rightColumnContent div,
        .preheader .rightColumnContent span,
        .footer .rightColumnContent div,
        .footer .rightColumnContent span {
          text-align: left !important;
        }
        .preheader .rightColumnContent,
        .preheader .leftColumnContent {
          font-size: 80% !important;
          padding: 5px 0;
        }
        table.wrapper-mobile {
          width: 100% !important;
          table-layout: fixed;
        }
        img.max-width {
          height: auto !important;
          max-width: 100% !important;
        }
        a.bulletproof-button {
          display: block !important;
          width: auto !important;
          font-size: 80%;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        .columns {
          width: 100% !important;
        }
        .column {
          display: block !important;
          width: 100% !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
        .social-icon-column {
          display: inline-block !important;
        }
      }
    </style>
    <!--user entered Head Start-->
    <!--End Head user entered-->
  </head>
  <body>
    <div
      class="wrapper"
      data-link-color="#000000"
      data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#ffffff;"
    >
      <div class="webkit">
        <table
          cellpadding="0"
          cellspacing="0"
          border="0"
          width="100%"
          class="wrapper"
          bgcolor="#ffffff"
        >
          <tr>
            <td valign="top" bgcolor="#ffffff" width="100%">
              <table
                width="100%"
                role="content-container"
                class="outer"
                align="center"
                cellpadding="0"
                cellspacing="0"
                border="0"
              >
                <tr>
                  <td width="100%">
                    <table
                      width="100%"
                      cellpadding="0"
                      cellspacing="0"
                      border="0"
                    >
                      <tr>
                        <td>
                          <!--[if mso]>
    <center>
    <table><tr><td width="600">
  <![endif]-->
                          <table
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            border="0"
                            style="width: 100%; max-width: 600px"
                            align="center"
                          >
                            <tr>
                              <td
                                role="modules-container"
                                style="
                                  padding: 0px 0px 0px 0px;
                                  color: #000000;
                                  text-align: left;
                                "
                                bgcolor="#ffffff"
                                width="100%"
                                align="left"
                              >
                                <table
                                  class="module preheader preheader-hide"
                                  role="module"
                                  data-type="preheader"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="
                                    display: none !important;
                                    visibility: hidden;
                                    opacity: 0;
                                    color: transparent;
                                    height: 0;
                                    width: 0;
                                  "
                                >
                                  <tr>
                                    <td role="module-content">
                                      <p></p>
                                    </td>
                                  </tr>
                                </table>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="center"
                                  width="100%"
                                  role="module"
                                  data-type="columns"
                                  style="padding: 20px 0px 20px 0px"
                                  bgcolor="#ffffff"
                                  data-distribution="1"
                                >
                                  <tbody>
                                    <tr role="module-content">
                                      <td height="100%" valign="top">
                                        <table
                                          width="600"
                                          style="
                                            width: 600px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 0px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="wrapper"
                                                  role="module"
                                                  data-type="image"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="9f29c991-6500-41ef-9f0e-d56cb5dc1238"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          font-size: 6px;
                                                          line-height: 10px;
                                                          padding: 0px 0px px
                                                            0px;
                                                        "
                                                        valign="top"
                                                        align="left"
                                                      >
                                                        <img
                                                          class="max-width"
                                                          border="0"
                                                          style="
                                                            display: block;
                                                            color: #000000;
                                                            text-decoration: none;
                                                            font-family: Helvetica,
                                                              arial, sans-serif;
                                                            font-size: 16px;
                                                            max-width: 45% !important;
                                                            width: 38%;
                                                            height: auto !important;
                                                          "
                                                          width="150"
                                                          alt=""
                                                          data-proportionally-constrained="true"
                                                          data-responsive="true"
                                                          src="http://cdn.mcauto-images-production.sendgrid.net/9f2c6e2c40cc844d/d93ad58a-e33b-4346-b84a-51bee7a81eef/1080x1080.png"
                                                        />
                                                      </td>
                                                      <td
                                                        style="
                                                          text-align: right;
                                                        "
                                                      ></td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="module"
                                  role="module"
                                  data-type="text"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="table-layout: fixed"
                                  data-muid="b35b8ff4-8b3c-4b35-9ed3-f9f25170affc"
                                  data-mc-module-version="2019-10-22"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          padding: 18px 30px 18px 30px;
                                          line-height: 22px;
                                          text-align: inherit;
                                          background-color: #ffffff;
                                        "
                                        height="100%"
                                        valign="top"
                                        bgcolor="#f6f6f6"
                                        role="module-content"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-family: inherit;
                                              text-align: inherit;
                                            "
                                          >
                                            <span
                                              style="
                                                font-size: 16px;
                                                font-family: 'lucida sans unicode',
                                                  'lucida grande', sans-serif;
                                                color: #2f4858;
                                              "
                                            >
                                              <img
                                                [src]="
                                                  'assets/images/flag/' +
                                                  imageMap[nota.pais] +
                                                  '.webp'
                                                "
                                                style="
                                                  width: 32px;
                                                  height: 20px;
                                                "
                                              />
                                              <strong>
                                                {{ nota.titulo }}</strong
                                              ></span
                                            >
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="module"
                                  role="module"
                                  data-type="divider"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="table-layout: fixed"
                                  data-muid="a91b8905-a535-4207-bb6e-854d8f8bfead"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="padding: 0px 0px 0px 0px"
                                        role="module-content"
                                        height="100%"
                                        valign="top"
                                        bgcolor=""
                                      >
                                        <table
                                          border="0"
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="center"
                                          width="100%"
                                          height="2px"
                                          style="
                                            line-height: 2px;
                                            font-size: 2px;
                                          "
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="padding: 0px 0px 2px 0px"
                                                bgcolor="#415481"
                                              ></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="center"
                                  width="100%"
                                  role="module"
                                  data-type="columns"
                                  style="padding: 0px 20px 20px 20px"
                                  bgcolor="#FFFFFF"
                                  data-distribution="1"
                                >
                                  <tbody>
                                    <tr role="module-content">
                                      <td height="100%" valign="top">
                                        <table
                                          width="560"
                                          style="
                                            width: 560px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 0px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="a4cb7b03-7cc7-45b2-b035-b48f5042b307"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 18px 0px 18px
                                                            0px;
                                                          line-height: 22px;
                                                          text-align: inherit;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor=""
                                                        role="module-content"
                                                      >
                                                        <div>
                                                          <div
                                                            style="
                                                              font-family: inherit;
                                                            "
                                                          ></div>
                                                          <div></div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="
                                                    table-layout: fixed;
                                                    margin-bottom: 10px;
                                                  "
                                                  data-muid="3cfcd060-6f0a-47e2-9865-855bcde54de7"
                                                  data-mc-module-version="2019-10-22"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 18px 30px
                                                            18px 30px;
                                                          line-height: 22px;
                                                          text-align: inherit;
                                                          background-color: #f6f6f6;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor="#f6f6f6"
                                                        role="module-content"
                                                      >
                                                        <div
                                                          style="
                                                            font-family: inherit;
                                                            text-align: justify;
                                                          "
                                                        >
                                                          <span
                                                            style="
                                                              font-family: 'lucida sans unicode',
                                                                'lucida grande',
                                                                sans-serif;
                                                              font-size: 12px;
                                                              color: #2f4858;
                                                            "
                                                            [innerHTML]="
                                                              sanitizeHtml(
                                                                nota.sintesis
                                                              )
                                                            "
                                                          ></span>
                                                          <span
                                                            style="
                                                              font-family: 'lucida sans unicode',
                                                                'lucida grande',
                                                                sans-serif;
                                                              font-size: 12px;
                                                              color: #2f4858;
                                                            "
                                                            [innerHTML]="
                                                              sanitizeHtml(
                                                                nota.contenido
                                                              )
                                                            "
                                                          ></span>
                                                        </div>
                                                        <div
                                                          style="
                                                            font-family: inherit;
                                                            text-align: right;
                                                            padding-top: 5px;
                                                          "
                                                        >
                                                          <a
                                                            href="https://d3.directoriolegislativo.org/#/show;type=4;id={{
                                                              nota.id
                                                            }}"
                                                            ><button
                                                              style="
                                                                border-radius: 0px;
                                                                background-color: #fe9448;
                                                                border: 0px;
                                                                padding: 10px
                                                                  10px 10px 10px;
                                                              "
                                                            >
                                                              <span
                                                                style="
                                                                  color: white;
                                                                "
                                                                >Leer más</span
                                                              >
                                                            </button></a
                                                          >
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="center"
                                  width="100%"
                                  role="module"
                                  data-type="columns"
                                  style="padding: 0px 0px 10px 0px"
                                  bgcolor="#FFFFFF"
                                  data-distribution="1,1,1,1"
                                >
                                  <tbody>
                                    <tr role="module-content">
                                      <td height="100%" valign="top">
                                        <table
                                          width="135"
                                          style="
                                            width: 135px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 10px 0px 0px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="982d7f9d-23bd-4de9-a7c1-a877e69c0015"
                                                >
                                                  <th
                                                    style="
                                                      padding: 5px 5px 5px 5px;
                                                      line-height: 15px;
                                                      text-align: inherit;
                                                      background-color: #e9e3e6;
                                                    "
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 12px;
                                                        font-family: georgia,
                                                          serif;
                                                        color: #fe6b00;
                                                      "
                                                      >Anteproyectos</span
                                                    >
                                                  </th>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 5px 5px 5px
                                                            5px;
                                                          line-height: 20px;
                                                          text-align: inherit;
                                                          background-color: #f6f6f6;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor=""
                                                        role="module-content"
                                                      >
                                                        <div>
                                                          <div
                                                            style="
                                                              font-family: inherit;
                                                            "
                                                          >
                                                            <a
                                                              href="https://d3.directoriolegislativo.org/#/search;regulationType=0"
                                                            >
                                                              <span
                                                                style="
                                                                  font-family: 'lucida sans unicode',
                                                                    'lucida grande',
                                                                    sans-serif;
                                                                  font-size: 10px;
                                                                  color: #2f4858;
                                                                "
                                                                >Haga clic aqui
                                                                para ver sus
                                                                anteproyectos en
                                                                seguimiento.</span
                                                              >
                                                            </a>
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          width="135"
                                          style="
                                            width: 135px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 10px 0px 10px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-1"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="982d7f9d-23bd-4de9-a7c1-a877e69c0015"
                                                >
                                                  <th
                                                    style="
                                                      padding: 5px 5px 5px 5px;
                                                      line-height: 15px;
                                                      text-align: inherit;
                                                      background-color: #e9e3e6;
                                                    "
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 12px;
                                                        font-family: georgia,
                                                          serif;
                                                        color: #fe6b00;
                                                      "
                                                      >Proyectos</span
                                                    >
                                                  </th>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 5px 5px 5px
                                                            5px;
                                                          line-height: 20px;
                                                          text-align: inherit;
                                                          background-color: #f6f6f6;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor=""
                                                        role="module-content"
                                                      >
                                                        <div>
                                                          <div
                                                            style="
                                                              font-family: inherit;
                                                            "
                                                          >
                                                            <a
                                                              href="https://d3.directoriolegislativo.org/#/search;regulationType=1"
                                                            >
                                                              <span
                                                                style="
                                                                  font-family: 'lucida sans unicode',
                                                                    'lucida grande',
                                                                    sans-serif;
                                                                  font-size: 10px;
                                                                  color: #2f4858;
                                                                "
                                                                >Haga clic aqui
                                                                para ver sus
                                                                proyectos en
                                                                seguimiento.</span
                                                              >
                                                            </a>
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          width="135"
                                          style="
                                            width: 135px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 10px 0px 10px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-2"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="982d7f9d-23bd-4de9-a7c1-a877e69c0015"
                                                >
                                                  <th
                                                    style="
                                                      padding: 5px 5px 5px 5px;
                                                      line-height: 15px;
                                                      text-align: inherit;
                                                      background-color: #e9e3e6;
                                                    "
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 12px;
                                                        font-family: georgia,
                                                          serif;
                                                        color: #fe6b00;
                                                      "
                                                      >Leyes</span
                                                    >
                                                  </th>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 5px 5px 5px
                                                            5px;
                                                          line-height: 20px;
                                                          text-align: inherit;
                                                          background-color: #f6f6f6;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor=""
                                                        role="module-content"
                                                      >
                                                        <div>
                                                          <div
                                                            style="
                                                              font-family: inherit;
                                                            "
                                                          >
                                                            <a
                                                              href="https://d3.directoriolegislativo.org/#/search;regulationType=3"
                                                            >
                                                              <span
                                                                style="
                                                                  font-family: 'lucida sans unicode',
                                                                    'lucida grande',
                                                                    sans-serif;
                                                                  font-size: 10px;
                                                                  color: #2f4858;
                                                                "
                                                                >Haga clic aqui
                                                                para ver sus
                                                                leyes en
                                                                seguimiento.</span
                                                              >
                                                            </a>
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          width="135"
                                          style="
                                            width: 135px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 10px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-3"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="text"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="982d7f9d-23bd-4de9-a7c1-a877e69c0015"
                                                >
                                                  <th
                                                    style="
                                                      padding: 5px 5px 5px 5px;
                                                      line-height: 15px;
                                                      text-align: inherit;
                                                      background-color: #e9e3e6;
                                                    "
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 12px;
                                                        font-family: georgia,
                                                          serif;
                                                        color: #fe6b00;
                                                      "
                                                      >Normativas</span
                                                    >
                                                  </th>
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          padding: 5px 5px 5px
                                                            5px;
                                                          line-height: 20px;
                                                          text-align: inherit;
                                                          background-color: #f6f6f6;
                                                        "
                                                        height="100%"
                                                        valign="top"
                                                        bgcolor=""
                                                        role="module-content"
                                                      >
                                                        <div>
                                                          <div
                                                            style="
                                                              font-family: inherit;
                                                            "
                                                          >
                                                            <a
                                                              href="https://d3.directoriolegislativo.org/#/search;regulationType=2"
                                                            >
                                                              <span
                                                                style="
                                                                  font-family: 'lucida sans unicode',
                                                                    'lucida grande',
                                                                    sans-serif;
                                                                  font-size: 10px;
                                                                  color: #2f4858;
                                                                "
                                                                >Haga clic aqui
                                                                para ver sus
                                                                normativas en
                                                                seguimiento.</span
                                                              >
                                                            </a>
                                                          </div>
                                                          <div></div>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  class="wrapper"
                                  role="module"
                                  data-type="image"
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  width="100%"
                                  style="table-layout: fixed"
                                  data-muid="073aad02-0f68-4bf7-ae40-86f7e0e76147"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style="
                                          font-size: 6px;
                                          line-height: 10px;
                                          padding: 0px 0px 10px 0px;
                                        "
                                        valign="top"
                                        align="center"
                                      >
                                        <a
                                          href="https://d3.directoriolegislativo.org"
                                        >
                                          <img
                                            class="max-width"
                                            border="0"
                                            style="
                                              display: block;
                                              color: #000000;
                                              text-decoration: none;
                                              font-family: Helvetica, arial,
                                                sans-serif;
                                              font-size: 16px;
                                              max-width: 100% !important;
                                              width: 100%;
                                              height: auto !important;
                                            "
                                            width="600"
                                            alt=""
                                            data-proportionally-constrained="true"
                                            data-responsive="true"
                                            src="http://cdn.mcauto-images-production.sendgrid.net/9f2c6e2c40cc844d/7a0907ad-2f57-45a0-a96a-dd90d4495bee/1080x240.png"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <table
                                  border="0"
                                  cellpadding="0"
                                  cellspacing="0"
                                  align="center"
                                  width="100%"
                                  role="module"
                                  data-type="columns"
                                  style="padding: 0px 0px 0px 0px"
                                  bgcolor="#FFFFFF"
                                  data-distribution="1,1"
                                >
                                  <tbody>
                                    <tr role="module-content">
                                      <td height="100%" valign="top">
                                        <table
                                          width="290"
                                          style="
                                            width: 290px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 10px 0px 0px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-0"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="wrapper"
                                                  role="module"
                                                  data-type="image"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="95a83c3b-b871-4483-bad4-d01973f06dbf"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        style="
                                                          font-size: 6px;
                                                          line-height: 10px;
                                                          padding: 0px 0px 0px
                                                            0px;
                                                        "
                                                        valign="top"
                                                        align="center"
                                                      >
                                                        <img
                                                          class="max-width"
                                                          border="0"
                                                          style="
                                                            display: block;
                                                            color: #000000;
                                                            text-decoration: none;
                                                            font-family: Helvetica,
                                                              arial, sans-serif;
                                                            font-size: 16px;
                                                            max-width: 100% !important;
                                                            width: 100%;
                                                            height: auto !important;
                                                          "
                                                          width="290"
                                                          alt=""
                                                          data-proportionally-constrained="true"
                                                          data-responsive="true"
                                                          src="http://cdn.mcauto-images-production.sendgrid.net/1e9ac4a2a922a838/1e8ffdb3-8c79-46a8-845a-5fb44420f943/3744x1409.png"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <table
                                          width="290"
                                          style="
                                            width: 290px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 10px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-1"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="social"
                                                  align="center"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="b370bdc4-6357-4f63-8140-6eb55f69383c.1.1"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        valign="top"
                                                        style="
                                                          padding: 41px 0px 0px
                                                            0px;
                                                          font-size: 6px;
                                                          line-height: 10px;
                                                        "
                                                        align="center"
                                                      >
                                                        <table
                                                          align="center"
                                                          style="
                                                            -webkit-margin-start: auto;
                                                            -webkit-margin-end: auto;
                                                          "
                                                        >
                                                          <tbody>
                                                            <tr align="center">
                                                              <td
                                                                style="
                                                                  padding: 0px
                                                                    5px;
                                                                "
                                                                class="social-icon-column"
                                                              >
                                                                <a
                                                                  role="social-icon-link"
                                                                  href="https://www.facebook.com/DireLegislativo"
                                                                  target="_blank"
                                                                  alt="Facebook"
                                                                  title="Facebook"
                                                                  style="
                                                                    display: inline-block;
                                                                    background-color: #3b579d;
                                                                    height: 21px;
                                                                    width: 21px;
                                                                  "
                                                                >
                                                                  <img
                                                                    role="social-icon"
                                                                    alt="Facebook"
                                                                    title="Facebook"
                                                                    src="https://mc.sendgrid.com/assets/social/white/facebook.png"
                                                                    style="
                                                                      height: 21px;
                                                                      width: 21px;
                                                                    "
                                                                    height="21"
                                                                    width="21"
                                                                  />
                                                                </a>
                                                              </td>
                                                              <td
                                                                style="
                                                                  padding: 0px
                                                                    5px;
                                                                "
                                                                class="social-icon-column"
                                                              >
                                                                <a
                                                                  role="social-icon-link"
                                                                  href="https://twitter.com/DireLegislativo"
                                                                  target="_blank"
                                                                  alt="Twitter"
                                                                  title="Twitter"
                                                                  style="
                                                                    display: inline-block;
                                                                    background-color: #7ac4f7;
                                                                    height: 21px;
                                                                    width: 21px;
                                                                  "
                                                                >
                                                                  <img
                                                                    role="social-icon"
                                                                    alt="Twitter"
                                                                    title="Twitter"
                                                                    src="https://mc.sendgrid.com/assets/social/white/twitter.png"
                                                                    style="
                                                                      height: 21px;
                                                                      width: 21px;
                                                                    "
                                                                    height="21"
                                                                    width="21"
                                                                  />
                                                                </a>
                                                              </td>
                                                              <td
                                                                style="
                                                                  padding: 0px
                                                                    5px;
                                                                "
                                                                class="social-icon-column"
                                                              >
                                                                <a
                                                                  role="social-icon-link"
                                                                  href="https://www.instagram.com/direlegislativo/"
                                                                  target="_blank"
                                                                  alt="Instagram"
                                                                  title="Instagram"
                                                                  style="
                                                                    display: inline-block;
                                                                    background-color: #7f4b30;
                                                                    height: 21px;
                                                                    width: 21px;
                                                                  "
                                                                >
                                                                  <img
                                                                    role="social-icon"
                                                                    alt="Instagram"
                                                                    title="Instagram"
                                                                    src="https://mc.sendgrid.com/assets/social/white/instagram.png"
                                                                    style="
                                                                      height: 21px;
                                                                      width: 21px;
                                                                    "
                                                                    height="21"
                                                                    width="21"
                                                                  />
                                                                </a>
                                                              </td>
                                                              <td
                                                                style="
                                                                  padding: 0px
                                                                    5px;
                                                                "
                                                                class="social-icon-column"
                                                              >
                                                                <a
                                                                  role="social-icon-link"
                                                                  href="https://www.youtube.com/@DireLegislativo"
                                                                  target="_blank"
                                                                  alt="Youtube"
                                                                  title="Pinterest"
                                                                  style="
                                                                    display: inline-block;
                                                                    background-color: #cb2027;
                                                                    height: 21px;
                                                                    width: 21px;
                                                                  "
                                                                >
                                                                  <img
                                                                    role="social-icon"
                                                                    alt="Youtube"
                                                                    title="Youtube"
                                                                    src="http://cdn.mcauto-images-production.sendgrid.net/1e9ac4a2a922a838/e4a97cc2-f373-4a95-9a08-9af04fbea470/448x512.png"
                                                                    style="
                                                                      height: 21px;
                                                                      width: 21px;
                                                                    "
                                                                    height="21"
                                                                    width="21"
                                                                  />
                                                                </a>
                                                              </td>
                                                              <td
                                                                style="
                                                                  padding: 0px
                                                                    5px;
                                                                "
                                                                class="social-icon-column"
                                                              >
                                                                <a
                                                                  role="social-icon-link"
                                                                  href="https://www.linkedin.com/company/fundaci-n-directorio-legislativo"
                                                                  target="_blank"
                                                                  alt="LinkedIn"
                                                                  title="LinkedIn"
                                                                  style="
                                                                    display: inline-block;
                                                                    background-color: #0077b5;
                                                                    height: 21px;
                                                                    width: 21px;
                                                                  "
                                                                >
                                                                  <img
                                                                    role="social-icon"
                                                                    alt="LinkedIn"
                                                                    title="LinkedIn"
                                                                    src="https://mc.sendgrid.com/assets/social/white/linkedin.png"
                                                                    style="
                                                                      height: 21px;
                                                                      width: 21px;
                                                                    "
                                                                    height="21"
                                                                    width="21"
                                                                  />
                                                                </a>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <table
                                          width="290"
                                          style="
                                            width: 290px;
                                            border-spacing: 0;
                                            border-collapse: collapse;
                                            margin: 0px 0px 0px 10px;
                                          "
                                          cellpadding="0"
                                          cellspacing="0"
                                          align="left"
                                          border="0"
                                          bgcolor=""
                                          class="column column-1"
                                        >
                                          <tbody>
                                            <tr>
                                              <td
                                                style="
                                                  padding: 0px;
                                                  margin: 0px;
                                                  border-spacing: 0;
                                                "
                                              >
                                                <table
                                                  class="module"
                                                  role="module"
                                                  data-type="social"
                                                  align="center"
                                                  border="0"
                                                  cellpadding="0"
                                                  cellspacing="0"
                                                  width="100%"
                                                  style="table-layout: fixed"
                                                  data-muid="b370bdc4-6357-4f63-8140-6eb55f69383c"
                                                >
                                                  <tbody>
                                                    <tr>
                                                      <td
                                                        valign="top"
                                                        style="
                                                          padding: 41px 0px 0px
                                                            0px;
                                                          font-size: 6px;
                                                          line-height: 10px;
                                                        "
                                                        align="center"
                                                      >
                                                        <table
                                                          align="center"
                                                          style="
                                                            -webkit-margin-start: auto;
                                                            -webkit-margin-end: auto;
                                                          "
                                                        >
                                                          <tbody></tbody>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <!--[if mso]>
                                  </td>
                                </tr>
                              </table>
                            </center>
                            <![endif]-->
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </body>
</html>
