import { Pipe, PipeTransform } from '@angular/core';
import { TemaModel } from 'src/app/panel/entidades/models/tema.model';

@Pipe({
  name: 'searchTemas'
})
export class SearchTemasPipe implements PipeTransform {

  transform(Tema: TemaModel[], searchTema: string): TemaModel[] {
    if (!Tema || !searchTema) {
      return Tema;
    }
    return Tema.filter(tema => tema.descripcionTema.toLocaleLowerCase().includes(searchTema.toLocaleLowerCase()));
  }

}