import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { ClienteModel } from 'src/app/panel/entidades/models/clientes.model';
import { UsuarioModel } from 'src/app/panel/entidades/models/usuario.model';
import { LukeService } from 'src/app/panel/panelServices/luke.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.css'],
})
export class ClientSelectComponent implements OnInit {
  clientes: ClienteModel[];
  selectedClientes: ClienteModel[] = [];
  selectedUsuarios: UsuarioModel[] = [];
  addingUsuarios: boolean = false;
  addingUsuariosCliente: ClienteModel;
  @Input() capturedBackData: any;
  @Output() clientDataEvent = new EventEmitter<any>();

  constructor(private lukeService: LukeService) {}

  ngOnInit(): void {
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();
    forkJoin([this.lukeService.getClientes({ ServicioId: 3 })]).subscribe(
      ([clientes]: [ClienteModel[]]) => {
        this.clientes = clientes;
        Swal.close();
      }
    );
    if (this.capturedBackData) {
      this.selectedClientes = this.capturedBackData[0];
      this.selectedUsuarios = this.capturedBackData[1];
    }
  }

  selectClient(cliente: any) {
    this.selectedClientes.push(cliente);
    const index = this.clientes.indexOf(cliente);
    if (index > -1) {
      this.clientes.splice(index, 1);
    }
  }

  limpiarCliente(cliente) {
    this.selectedClientes.splice(this.selectedClientes.indexOf(cliente), 1);
    this.clientes.unshift(cliente);
    this.selectedUsuarios = this.selectedUsuarios.filter((selectedUsuario) => {
      return !cliente.usuariosRelacionados.some(
        (usuario) => usuario.id === selectedUsuario.id
      );
    });
  }

  startAddingUsuarios(cliente) {
    this.lukeService
      .getUsuarios({ clienteId: cliente.id })
      .subscribe((usuarios: UsuarioModel[]) => {
        this.addingUsuarios = true;
        this.addingUsuariosCliente = cliente;
        this.addingUsuariosCliente.usuariosRelacionados = usuarios;
      });
  }

  addUsuario(usuario) {
    this.selectedUsuarios.push(usuario);
  }

  removeUsuario(usuario) {
    const index = this.selectedUsuarios.indexOf(usuario);
    if (index > -1) {
      this.selectedUsuarios.splice(index, 1);
    }
  }

  selectAll(cliente) {
    cliente.usuariosRelacionados.forEach((usuario) => {
      if (!this.selectedUsuarios.includes(usuario)) {
        this.selectedUsuarios.push(usuario);
      }
    });
  }

  unselectAll(cliente) {
    this.selectedUsuarios = this.selectedUsuarios.filter((usuario) => {
      return usuario.clienteId != cliente.id;
    });
  }

  isUsuarioSelected(usuario) {
    return this.selectedUsuarios.some(
      (selectedUsuario) => selectedUsuario.id === usuario.id
    );
  }

  emitClientData() {
    const clientData = [this.selectedClientes, this.selectedUsuarios];
    this.clientDataEvent.emit(clientData);
  }
}
