<div class="card-content" id="mainCard">
    <div class="card-body">
      <div class="table-responsive">
          <div class="input-group mb-3">
              <input [(ngModel)]="searchTerm" type="text" class="form-control" placeholder="Buscar o Crear Nuevo" aria-label="Buscar" aria-describedby="basic-addon2">
              <div class="input-group-append">
                  <button type="button" (click)="onSubmit(searchTerm)" [disabled]="!searchTerm" class="btn btn-light btn-md">Crear Nuevo</button>
              </div>
          </div>
        <table class="table">
          <thead>
            <tr>
              <th>Nombre/Descripcion</th>
              <th>País</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let bloque of bloques | appFilter: this.searchTerm; let i = index">
              <td *ngIf="i !== this.editingIndex">
                {{ bloque.descripcionBloque }}
              </td>
              <td *ngIf="i !== this.editingIndex">
                {{getPaisString(bloque.paisId) || "No disponible."}}
              </td>
              <td *ngIf="i == this.editingIndex">
                <input class="form-control" type="text" [(ngModel)]="bloque.descripcionBloque" />
              </td>
              <td *ngIf="i == this.editingIndex">
                <ng-select
                placeholder="Seleccioná algún país"
                appendTo="body"
                [items]="paises"
                [searchable]="true"
                [clearable]="true"
                [(ngModel)]="bloque.paisId"
                name="paises"
                bindLabel="name"
                bindValue="id"
                >                
                </ng-select>
              </td>
              <td *ngIf="i !== this.editingIndex">
                <a (click)="this.editingIndex = i; this.oldDesc = bloque.descripcionBloque; this.oldPais = bloque.paisId"
                  ><i
                    class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
                  ></i
                ></a>
                <a (click)="onDelete(bloque.id)"
                  ><i
                    class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                  ></i
                ></a>
              </td>
              <td *ngIf="i == this.editingIndex">
                <a (click)="onSave(bloque)"
                  ><i
                    class="badge-circle badge-circle-light-secondary bx bx-save font-medium-1 mr-1"
                  ></i
                ></a>
                <a (click)="onCancel(bloque)"
                  ><i
                    class="badge-circle badge-circle-light-secondary bx bx-x font-medium-1"
                  ></i
                ></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  