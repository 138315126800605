import { formatDate } from '@angular/common';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import {
  NgbdSortableHeader,
  SortEvent,
} from 'src/app/shared/directives/sortable.directive';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LukeService } from '../../../panelServices/luke.service';
import { ClienteModel } from '../../models/clientes.model';
import { PagedResponse } from '../../models/pagedResponse.interface';
import { PaisModel } from '../../models/pais.model';
import { ProyectoModel } from '../../models/proyecto.model';
import { TemaModel } from '../../models/tema.model';

@Component({
  selector: 'app-proyecto',
  templateUrl: 'proyecto.component.html',
  styleUrls: ['proyecto.component.css'],
})
export class ProyectoComponent implements OnInit {
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  proyectos: ProyectoModel[] = [];
  title = 'Proyectos';
  formatDate = formatDate;
  expedienteUrl: string;
  temas: TemaModel[];
  form: UntypedFormGroup;
  clientes: ClienteModel[] = [];
  paises: PaisModel[] = [];
  pageSize: number = 20;
  pageNumber: number = 1;
  totalCount: number;
  totalPages: number;
  sortBy: string = 'CreatedAt';
  sortOrder: 'desc' | 'asc' | '' = 'desc';

  constructor(
    private lukeService: LukeService,
    private formBuilder: FormBuilder
  ) {
    this.createFilterForm();
  }

  ngOnInit(): void {
    this.showLoading();
    forkJoin([
      this.lukeService.getProyectos({
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }),
      this.lukeService.getPaises(),
      this.lukeService.getClientes(),
    ]).subscribe(
      ([proyectos, paises, clientes]: [
        PagedResponse<ProyectoModel>,
        PaisModel[],
        ClienteModel[]
      ]) => {
        this.pageNumber = proyectos.pageNumber;
        this.totalCount = proyectos.totalCount;
        this.totalPages = proyectos.totalPages;
        this.proyectos = proyectos.items;
        this.paises = paises;
        this.clientes = clientes;
        Swal.close();
      }
    );
  }

  applyFilter() {
    const qp = {
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
      clienteId: this.form.get('clienteId').value,
      paises: this.form.get('paises').value,
      titulo: this.form.get('titulo').value,
      keyword: this.form.get('keyword').value,
      expedienteNumero: this.form.get('expedienteNumero').value,
      pageNumber: 1,
      pageSize: 20,
    };
    this.lukeService
      .getProyectos(qp)
      .subscribe((res: PagedResponse<ProyectoModel>) => {
        this.pageNumber = 1;
        this.pageSize = 20;
        this.proyectos = [...res.items];
        this.totalCount = res.totalCount;
        this.totalPages = res.totalPages;
      });
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction != '') {
      this.sortBy = column;
      this.sortOrder = direction;
    } else {
      this.sortBy = 'CreatedAt';
      this.sortOrder = 'desc';
    }

    this.applyFilter();
  }

  resetFilter() {
    this.form.reset();
    this.lukeService
      .getProyectos({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
        pageNumber: 1,
        pageSize: 20,
      })
      .subscribe((res: PagedResponse<ProyectoModel>) => {
        this.pageNumber = 1;
        this.pageSize = 20;
        this.proyectos = [...res.items];
        this.totalCount = res.totalCount;
        this.totalPages = res.totalPages;
      });
  }

  showLoading() {
    Swal.fire({
      title: 'Cargando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  parseTemas(temas) {
    let tem = temas.map((element) => {
      return element.descripcionTema;
    });

    return tem.join(', ');
  }

  parseClientes(clientes) {
    let cli = clientes.map((element) => {
      return element.nombreCliente;
    });

    return cli.join(', ');
  }

  parseDate(fecha) {
    return formatDate(fecha, 'dd-MM-yyyy', 'en-US');
  }

  createFilterForm() {
    this.form = this.formBuilder.group({
      titulo: new FormControl(''),
      paises: new FormControl([]),
      clienteId: new FormControl([]),
      keyword: new FormControl(null),
      expedienteNumero: new FormControl(null),
    });
  }

  navigate(pageNum: number) {
    this.showLoading();
    this.lukeService
      .getProyectos({
        sortBy: this.sortBy,
        sortOrder: this.sortOrder,
        pageSize: this.pageSize,
        pageNumber: pageNum,
        titulo: this.form.get('titulo').value,
        paises: this.form.get('paises').value,
        clienteId: this.form.get('clienteId').value,
        keyword: this.form.get('keyword').value,
        expedienteNumero: this.form.get('expedienteNumero').value,
      })
      .subscribe((res: PagedResponse<ProyectoModel>) => {
        this.pageNumber = res.pageNumber;
        this.totalCount = res.totalCount;
        this.totalPages = res.totalPages;
        this.proyectos = res.items;
        Swal.close();
      });
  }

  getFrontendUrl(id: string) {
    return `${environment.client_frontend_url}/show/1/${id}`;
  }

  onDelete(id: string) {
    Swal.fire({
      title: 'Borrar proyecto',
      text: '¿Está seguro que quiere borrar este proyecto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService.deleteProyecto(id).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'El proyecto fue borrado', 'success');
            window.location.reload();
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo',
            });
          }
        );
      } else {
        return;
      }
    });
  }
}
