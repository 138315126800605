export class ProyectosMapper {
  public static mapToRequest(formData) {
    let proyectoRequest = {
      id: formData?.id,
      titulo: formData?.titulo,
      sintesis: formData?.sintesis,
      expedienteUrl: formData?.expedienteUrl,
      expedienteNumero: formData?.expedienteNumero,
      fechaIngreso: formData?.fechaIngreso,
      camaraId: formData?.camaraId,
      impactoTipoId: formData?.impactoId,
      nivelGobiernoId: formData?.nivelGobiernoId,
      paisId: formData?.paisId,
      proyectoTipoId: formData?.proyectoTipoId,
      sancionProbabilidadId: formData?.probabilidadAvanceId,
      temas: formData?.temas?.map((tema) => {
        return { id: tema, descripcionTema: 'a' };
      }),
      clientes:
        formData?.clientes?.map((cliente) => {
          return { id: cliente, nombreCliente: 'a' };
        }) ?? [],
      autores:
        formData?.autores?.map((autor) => {
          return { id: autor, nombreApellido: 'a' };
        }) ?? [],
      categorias: formData?.categorias?.map((cat) => {
        return { id: cat, descripcionCat: 'a' };
      }),
      leyesAsociadas: formData?.leyesAsociadas?.map((ley) => {
        return { id: ley, nombreLey: 'a' };
      }),
      comisiones: formData?.comisiones?.map((com) => {
        return { id: com, nombre: 'a' };
      }),
      apRelacionados: formData?.anteproyectosAsociados,
      notasRelacionadas: formData?.notasRelacionadas,
      proyectosRelacionados: formData?.proyectosAsociados,
      isPublic: formData?.isPublic
    };

    return proyectoRequest;
  }
}
