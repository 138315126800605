import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { createId } from '@paralleldrive/cuid2';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {
  private s3Endpoint = 'alertas-v2.directoriolegislativo.org';
  private accessKey = environment.s3AccessKeyId;
  private secretKey = environment.s3SecretKey;

  private s3: AWS.S3;


  constructor() {
    this.s3 = new AWS.S3({
      accessKeyId: this.accessKey,
      secretAccessKey: this.secretKey
    });
   }

  uploadFile(file: File, bucket: string = this.s3Endpoint): Observable<any> {
    const params = {
      Bucket: bucket,
      Key: `${createId()}_${file.name}`,
      Body: file,
      ACL: 'public-read',
      ContentType: file.type,
      ContentDisposition: 'inline'
    };

    return new Observable(observer => {
      this.s3.upload(params, (err, data) => {
        if (err) {
          observer.error(err);
        } else {
          data.Location = 'https://' + bucket + '/' + file.name;
          observer.next(data);
          observer.complete();
        }
      });
    });
  }

  getUploadedFiles(bucket: string = this.s3Endpoint): Observable<AWS.S3.ListObjectsV2Output> {
    const params = {
      Bucket: bucket,
    };

    return new Observable(observer => {
      this.s3.listObjectsV2(params, (err, data) => {
        if (err) {
          observer.error(err);
        } else {
          const orderedData = data.Contents.sort((a, b) => {
            return new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime();
          });
          data.Contents = orderedData;
          observer.next(data);
          observer.complete();
        }
      });
    });
  }

  deleteFile(file: string, bucket: string = this.s3Endpoint): Observable<any> {
    const params = {
      Bucket: bucket,
      Key: file
    };

    return new Observable(observer => {
      this.s3.deleteObject(params, (err, data) => {
        if (err) {
          observer.error(err);
        } else {
          observer.next(data);
          observer.complete();
        }
      });
    });
  }
}
