import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  currentPanelIndex: number = -1;
  capturedClientData: any;
  capturedEntityData: any;
  capturedBackData: any;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  nextPanel() {
    this.currentPanelIndex++;
  }

  backPanel() {
    this.currentPanelIndex--;
  }

  handleClientData(data: any) {
    this.capturedClientData = data;
    this.nextPanel();
  }

  handleEntityData(data: any) {
    this.capturedEntityData = data;
    this.nextPanel();
  }

  handleBackData(data:any) {
    this.backPanel();
    if (this.currentPanelIndex == 0) {
      this.capturedBackData = data;
    }
  }

}
