import { Component, Input, OnInit } from '@angular/core';
import { AlertaModel } from '../../models/alerta.model';
import { UsuarioModel } from '../../entidades/models/usuario.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reply-to-modal',
  templateUrl: './reply-to-modal.component.html',
  styleUrls: ['./reply-to-modal.component.css'],
})
export class ReplyToModalComponent implements OnInit {
  selectedUsuarios: UsuarioModel[];
  usuarios: UsuarioModel[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.selectedUsuarios) {
      this.selectedUsuarios = [];
    }
  }

  closeModal() {
    this.activeModal.close({ selectedUsuarios: this.selectedUsuarios });
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  checkIfSelected(usuario) {
    return this.selectedUsuarios.some((us) => {
      return us.id == usuario.id;
    });
  }

  toggleUserSelection(usuario) {
    if (!this.checkIfSelected(usuario)) {
      this.selectedUsuarios.push(usuario);
      return;
    } else {
      let usuarioIndex = this.selectedUsuarios.indexOf(usuario);
      this.selectedUsuarios.splice(usuarioIndex, 1);
    }
  }
}
