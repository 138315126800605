import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { concatMap, map, take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClienteModel } from '../../entidades/models/clientes.model';
import { UsuarioModel } from '../../entidades/models/usuario.model';
import { NotaShowComponent } from '../../entidades/notas/show/notas.component';
import { AlertaV2Response } from '../../models/alertav2.interface';
import { SendAlertaRequest } from '../../models/send-alerta-request.interface';
import { SendAlertaResponse } from '../../models/send-alerta-response.interface';
import { LukeService } from '../../panelServices/luke.service';
import { AlertaPreviewComponent } from '../alerta-preview/alerta-preview.component';
import { ReplyToModalComponent } from '../reply-to-modal/reply-to-modal.component';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css'],
})
export class UserSelectComponent implements OnInit {
  usuariosDl: UsuarioModel[] = [];
  alertas: AlertaV2Response[] = [];
  clientes: ClienteModel[] = [];
  subscription: Subscription;
  selectedAlertas: AlertaV2Response[] = [];
  selectedSender: number;
  sent: String[] = [];
  failed: String[] = [];
  isLoading: boolean = false;
  viewingSent: boolean = false;
  selectedEntities: { entityType: number; entityId: number }[];

  constructor(
    private modalService: NgbModal,
    private lukeService: LukeService,
    private router: Router
  ) {
    this.selectedEntities = this.router.getCurrentNavigation().extras.state as {
      entityType: number;
      entityId: number;
    }[];

    if (!this.selectedEntities) {
      router.navigate(['/dashboard/alertas']);
    }
  }

  ngOnInit(): void {
    Swal.fire({
      title: 'Cargando',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        this.isLoading = true;
        Swal.showLoading();
      },
    });

    const clientes$ = this.lukeService.getClientes().pipe(take(1));
    const staff$ = this.lukeService
      .getEntityByQueryParams('usuarios', { staff: true })
      .pipe(take(1));
    const alertas$ = this.lukeService.getAlertas().pipe(take(1));

    this.subscription = forkJoin([clientes$, staff$])
      .pipe(
        concatMap(([clientes, staff]) =>
          alertas$.pipe(
            map((alertas) => ({
              clientes,
              staff,
              alertas,
            }))
          )
        )
      )
      .subscribe(
        ({ clientes, staff, alertas }) => {
          this.clientes = clientes as ClienteModel[];
          this.usuariosDl = staff as UsuarioModel[];
          this.alertas = alertas.filter((alerta) => {
            return this.selectedEntities.some((sE) => {
              return (
                sE.entityId === alerta.entityId &&
                sE.entityType === alerta.entityType
              );
            });
          });
          this.isLoading = false;
          Swal.close();
        },
        (error) => {
          console.error('Error loading data', error);
          this.isLoading = false;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
          });
        }
      );
  }

  toggleView(): void {
    this.viewingSent = !this.viewingSent;

    Swal.fire({ text: 'Cargando' });
    Swal.showLoading();

    this.lukeService.getAlertas({ isSent: this.viewingSent }).subscribe(
      (res) => {
        this.alertas = res.sort((a: AlertaV2Response, b: AlertaV2Response) => {
          const dateA = new Date(a.sentAt).getTime();
          const dateB = new Date(b.sentAt).getTime();

          return dateB - dateA;
        });
        Swal.close();
      },
      (error) => {
        console.error(error);
        Swal.fire({ text: 'Error cargando alertas', icon: 'error' });
        Swal.close();
      }
    );
  }

  selectAlerta(alerta) {
    if (!this.selectedAlertas.includes(alerta)) {
      this.selectedAlertas.push(alerta);
    }
  }

  removeAlerta(alerta) {
    const alertaIndex = this.selectedAlertas.indexOf(alerta);
    this.selectedAlertas.splice(alertaIndex, 1);
  }

  syncAlertas() {
    this.lukeService.formAlertas().subscribe((res) => {
      Swal.fire({
        title: 'Sincronización completa',
        text: `Se encontraron ${res.alertasCreated} nuevas alertas.`,
      }).then(() => {
        if (res.alertasCreated > 0) {
          Swal.showLoading();
          this.lukeService.getAlertas().subscribe((res) => {
            this.alertas = res;
            Swal.close();
          });
        }
      });
    });
  }

  deleteAlerta(alerta) {
    this.alertas = this.alertas.filter((a) => a !== alerta);
    if (this.alertas.length == 0) {
      Swal.fire({
        title: 'Enviadas',
        text: 'No hay más alertas por enviar. Haga clic para volver',
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        this.router.navigate(['dashboard/entidades']);
      });
    }
  }

  checkIfSelected(alerta) {
    return this.selectedAlertas.some(
      (sN) =>
        sN.entityId == alerta.entityId && sN.recipientId == alerta.recipientId
    );
  }

  selectAll() {
    this.alertas.forEach((alerta) => {
      if (!this.selectedAlertas.includes(alerta)) {
        this.selectedAlertas.push(alerta);
      }
    });
  }

  selectAllClient(group) {
    group.value.forEach((alerta) => {
      this.selectAlerta(alerta);
    });
  }

  unSelectAll() {
    this.selectedAlertas = [];
  }

  unSelectAllClient(group) {
    this.selectedAlertas = this.selectedAlertas.filter((sA) => {
      return sA.recipient.clienteId != group.key;
    });
  }

  getClientName(id) {
    if (this.clientes) {
      return this.clientes?.find((cli) => {
        return cli.id == id;
      }).nombreCliente;
    } else {
      return 'No disponible';
    }
  }

  openEdit(entityId) {
    const modalRef = this.modalService.open(NotaShowComponent, {
      animation: true,
      keyboard: true,
      size: 'xl',
      centered: true,
      backdrop: false,
    });

    modalRef.closed.subscribe((data) => {
      if (data == 'edited') {
        Swal.fire({ text: 'Actualizando' });
        Swal.showLoading();
        this.alertas = null;
        this.lukeService.getAlertas().subscribe(
          (res) => {
            this.alertas = res.filter((alerta) => {
              return this.selectedEntities.some((sE) => {
                return (
                  sE.entityId === alerta.entityId &&
                  sE.entityType === alerta.entityType
                );
              });
            });
            Swal.close();
          },
          (error) => {
            console.error(error);
            Swal.close();
          }
        );
      }
    });

    modalRef.componentInstance.modalInput = entityId;
  }

  openPreview(nota) {
    const modalRef = this.modalService.open(AlertaPreviewComponent, {
      animation: true,
      keyboard: true,
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: false,
    });

    modalRef.componentInstance.nota = nota;
  }

  openReplyToModal(alerta) {
    const modalRef = this.modalService.open(ReplyToModalComponent, {
      animation: true,
      keyboard: true,
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: false,
    });

    modalRef.closed.subscribe((data) => {
      alerta.replyTo = data.selectedUsuarios;
    });

    modalRef.componentInstance.selectedUsuarios = alerta.replyTo;
    modalRef.componentInstance.usuarios = this.usuariosDl;
  }

  openMultipleReplyToModal(clienteId: number) {
    const modalRef = this.modalService.open(ReplyToModalComponent, {
      animation: true,
      keyboard: true,
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: false,
    });

    modalRef.closed.subscribe((data) => {
      this.alertas.forEach((al) => {
        if (al.recipient.clienteId == clienteId) {
          al.replyTo = data.selectedUsuarios;
        }
      });
    });

    modalRef.componentInstance.usuarios = this.usuariosDl;
  }

  sendAllAlertas() {
    Swal.fire({
      title: 'Envío masivo de Alertas',
      text: `Se van a enviar ${this.selectedAlertas.length} alertas.`,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        const totalAlertas = this.selectedAlertas.length;
        let currentIndex = 0;
        let failed = [];
        let sent = [];

        const sendNextAlerta = () => {
          if (currentIndex < totalAlertas) {
            const alerta = this.selectedAlertas[currentIndex];

            Swal.fire({
              title: `Enviando ${currentIndex + 1}/${totalAlertas}`,
              allowOutsideClick: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              showLoaderOnConfirm: true,
              preConfirm: () => new Promise(() => {}),
              didOpen: async () => {
                try {
                  const res = await this.submitAlerta(alerta).toPromise();

                  if (res.sent) {
                    sent.push(alerta.recipient.email);
                    this.sent.push(alerta.recipient.email);
                    this.deleteAlerta(alerta);
                  } else {
                    failed.push(alerta.recipient.email);
                  }
                } catch (error) {
                  failed.push(alerta.recipient.email);
                } finally {
                  currentIndex++;
                  sendNextAlerta();
                }
              },
            });
          } else {
            Swal.fire({
              title: 'Envío completado',
              text: `Envío completo. Enviados: ${sent}. Errores: ${failed}`,
              icon: 'success',
            }).then((result) => {
              this.ngOnInit();
            });
          }
        };

        sendNextAlerta();
      }
    });
  }

  handleSendButtonClick(alerta) {
    Swal.fire({
      title: 'Confirmar envío de alerta',
      text: 'No es posible revertir esta acción',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.sendOneAlerta(alerta);
      },
    });
  }

  sendOneAlerta(alerta): Promise<void> {
    return new Promise<void>((resolve) => {
      if (!this.selectedAlertas.includes(alerta)) {
        Swal.fire({
          title: 'Esta alerta no está aprobada',
          text: '¿Querés enviarla igual?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aprobar y enviar',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.submitAlerta(alerta)
              .toPromise()
              .then((res) => {
                if (res.sent) {
                  Swal.fire(
                    'Enviado',
                    `La alerta dirigido a ${alerta.recipient.email} fue enviado correctamente`,
                    'success'
                  );
                  this.deleteAlerta(alerta);
                } else {
                  Swal.fire(
                    'Error',
                    `La alerta dirigida a ${alerta.recipient.email} no pudo ser enviada`,
                    'error'
                  );
                }
                resolve();
              });
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.dismiss == Swal.DismissReason.cancel) {
            Swal.close();
            resolve();
          }
        });
      } else {
        Swal.showLoading();
        this.submitAlerta(alerta).subscribe((res) => {
          resolve();
          if (res.sent) {
            Swal.fire(
              'Enviado',
              `La alerta dirigida a ${alerta.recipient.email} fue enviada correctamente`,
              'success'
            );
            this.deleteAlerta(alerta);
          } else {
            Swal.fire(
              'Error',
              `La alerta dirigida a ${alerta.recipient.email} no pudo ser enviada`,
              'error'
            );
          }
        });
      }
    });
  }

  submitAlerta(alerta): Observable<SendAlertaResponse> {
    const localStorageData = JSON.parse(localStorage.getItem('user-data'));
    const replyToList = (alerta?.replyTo ?? []).map((us) => {
      return us.id;
    });
    const payload: SendAlertaRequest = {
      from: {
        name: `${localStorageData.firstName} ${localStorageData.lastName}`,
        email: `${localStorageData.email}`,
      },
      alertaId: alerta.id,
      replyToIds: replyToList ?? null,
    };

    return this.lukeService.sendOneAlerta(payload);
  }

  navigateBack() {
    this.router.navigate(['dashboard/alertas'], {
      state: this.selectedEntities,
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
