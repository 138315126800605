import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any[] = [
    {
      titulo: 'Dashboard',
      icon: 'menu-livicon',
      submenu: [
        {titulo: 'Main', url: '/'},
        {titulo: 'Entidades', url: '/entidades'},
        {titulo: 'Clientes', url: '/clientes'}
      ]
    }
  ]

  constructor() { }
}
