import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it: any) => {
      for (const key in it) {
        if (it.hasOwnProperty(key) && it[key] != null && it[key].toString().toLocaleLowerCase().includes(searchText)) {
          return true;
        }
      }
      return false;
    });
  }
}