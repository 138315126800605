import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailHistoryComponent } from '../email-history/email-history.component';
import { EntitySelectComponent } from './entity-select/entity-select.component';
import { UserSelectComponent } from './user-select/user-select.component';
const routes: Routes = [
  { path: '', component: EntitySelectComponent },
  { path: 'seleccion-usuarios', component: UserSelectComponent },
  { path: 'email-history', component: EmailHistoryComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlertasRoutingModule {}
