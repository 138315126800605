import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EntityQueryParams } from 'src/utils/entity-qp.interface';
import { AnteproyectosQP } from '../entidades/models/anteproyectos-qp.interface';
import { NotasQP } from '../entidades/models/notas-qp.interface';
import { ProyectosQP } from '../entidades/models/proyectos-qp.interface';
import { AlertasQP } from '../models/alertas-qp.interface';
import { AlertaV2Response } from '../models/alertav2.interface';
import { NewsletterDetailedResponse } from '../models/newsletter-detailed.interface';
import { NewsletterSyncResponse } from '../models/newsletter-sync.interface';
import { NewslettersQP } from '../models/newsletters-qp.interface';
import { NewsletterResponse } from '../models/newsletters-response.interface';
import { SendAlertaRequest } from '../models/send-alerta-request.interface';
import { SendAlertaResponse } from '../models/send-alerta-response.interface';
import { TemaModel } from './../entidades/models/tema.model';
import { AutoresQP } from './interfaces/autores-qp.interface';
import { ClientesQP } from './interfaces/clientes-qp.interface';
import { EstadosQP } from './interfaces/estados-qp.interface';
import { UsuariosQP } from './interfaces/usuarios-qp.interface';
@Injectable({
  providedIn: 'root',
})
export class LukeService {
  constructor(private http: HttpClient, private cookieService: CookieService) {}

  // CLIENTES

  getClientes(qp?: ClientesQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/clientes?`, {
      params: params,
    });
  }

  getClientesFiltered(paisId, servicioId, subServicioId) {
    return this.http.get(
      `${environment.backend_url}/clientes?paisId=${paisId}&servicioId=${servicioId}&subservicioDashboardId=${subServicioId}`
    );
  }

  getCliente(id: string) {
    return this.http.get(`${environment.backend_url}/clientes/${id}`);
  }

  deleteCliente(id: string) {
    return this.http.delete(`${environment.backend_url}/clientes/${id}`);
  }

  postCliente(cliente) {
    return this.http.post(`${environment.backend_url}/clientes`, cliente).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  putCliente(cliente) {
    return this.http
      .put(`${environment.backend_url}/clientes/${cliente.id}`, cliente)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // PROYECTOS

  getClientesProyectos(paisId: string) {
    return this.http.get(
      `${environment.backend_url}/clientes?paisId=${paisId}&servicioId=2&subServicioDashboardId=3`
    );
  }

  putProyecto(proyecto: any) {
    return this.http
      .put(`${environment.backend_url}/proyectos/${proyecto.id}`, proyecto)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  postProyecto(proyecto: any) {
    return this.http
      .post(`${environment.backend_url}/proyectos`, proyecto)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getProyectos(qp?: ProyectosQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/proyectos`, {
      params: params,
    });
  }

  getProyecto(id) {
    return this.http.get(`${environment.backend_url}/proyectos/${id}`);
  }

  deleteProyecto(id: string) {
    return this.http.delete(`${environment.backend_url}/proyectos/${id}`);
  }

  // ANTEPROYECTOS

  getAnteproyectos(qp?: AnteproyectosQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/anteproyectos`, {
      params,
    });
  }

  getAnteproyecto(id) {
    return this.http.get(`${environment.backend_url}/anteproyectos/${id}`);
  }

  getOrigenes() {
    return this.http.get(
      `${environment.backend_url}/anteproyectos/origenes?sortby=descripcionOrigen`
    );
  }

  postAnteProyecto(anteproyecto: any) {
    return this.http
      .post(`${environment.backend_url}/anteproyectos`, anteproyecto)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  deleteAnteproyecto(id: string) {
    return this.http.delete(`${environment.backend_url}/anteproyectos/${id}`);
  }

  putAnteproyecto(anteproyecto: any) {
    return this.http
      .put(
        `${environment.backend_url}/anteproyectos/${anteproyecto.id}`,
        anteproyecto
      )
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // INPUTS

  getComisiones() {
    return this.http.get(`${environment.backend_url}/comisiones`);
  }

  getPaises() {
    return this.http.get(`${environment.backend_url}/paises?sortby=Name`);
  }

  getPais(id) {
    return this.http.get(`${environment.backend_url}/paises?paisId=${id}`);
  }

  getNiveles() {
    return this.http.get(`${environment.backend_url}/nivelesgobierno`);
  }

  getTemas() {
    return this.http.get<TemaModel[]>(
      `${environment.backend_url}/temas?sortby=DescripcionTema`
    );
  }

  getCategorias() {
    return this.http.get(`${environment.backend_url}/categorias`);
  }

  getEstados(qp: EstadosQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/EstadosTipos`, {
      params: params,
    });
  }

  getAutores(qp: AutoresQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/autores`, {
      params: params,
    });
  }

  getTipos() {
    return this.http.get(`${environment.backend_url}/proyectostipos`);
  }

  getCamaras(id) {
    return this.http.get(
      `${environment.backend_url}/camarasParlamentarias?paisId=${id}`
    );
  }

  // NORMATIVAS

  getNormativas(qp?: EntityQueryParams) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/normativas`, {
      params: params,
    });
  }

  getClientesNormativas(paisId: string) {
    return this.http.get(
      `${environment.backend_url}/clientes?paisId=${paisId}&servicioId=2&subServicioDashboardId=2`
    );
  }

  postNormativa(normativa: any) {
    return this.http
      .post(`${environment.backend_url}/normativas`, normativa)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  putNormativa(normativa) {
    return this.http
      .put(`${environment.backend_url}/normativas/${normativa.id}`, normativa)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  getNormativa(id) {
    return this.http.get(`${environment.backend_url}/normativas/${id}`);
  }

  deleteNormativa(id) {
    return this.http.delete(`${environment.backend_url}/normativas/${id}`);
  }

  // NOTAS
  getNotas(qp?: NotasQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/notas`, {
      params: params,
    });
  }

  getNota(id) {
    return this.http.get(`${environment.backend_url}/notas/${id}`);
  }

  getClientesNotas(paisId: string) {
    return this.http.get(
      `${environment.backend_url}/clientes?paisId=${paisId}&servicioId=2&subServicioDashboardId=1`
    );
  }

  deleteNota(id: string) {
    return this.http.delete(`${environment.backend_url}/notas/${id}`);
  }

  postNota(nota: any) {
    return this.http.post(`${environment.backend_url}/notas`, nota).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  putNota(nota: any) {
    return this.http
      .put(`${environment.backend_url}/notas/${nota.id}`, nota)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // LEYES

  getLeyes(qp?: EntityQueryParams) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http.get(`${environment.backend_url}/leyes`, {
      params: params,
    });
  }

  getLey(id) {
    return this.http.get(`${environment.backend_url}/leyes/${id}`);
  }

  deleteLey(id) {
    return this.http.delete(`${environment.backend_url}/leyes/${id}`);
  }

  postLey(ley: any) {
    return this.http.post(`${environment.backend_url}/leyes`, ley).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  putLey(ley) {
    return this.http
      .put(`${environment.backend_url}/leyes/${ley.id}`, ley)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  // MOVIMIENTOS

  getMovimientos(entidadTipoId, entidadId) {
    return this.http.get(
      `${environment.backend_url}/movimientos/?EntidadId=${entidadId}&EntidadTipoId=${entidadTipoId}`
    );
  }

  postMovimiento(movimiento, entidadId, entidadTipoId) {
    const movRequest = {
      EntidadId: entidadId, // Id del anteproyecto, proyecto, normativa, ley
      descripcion: movimiento.descripcion,
      estadoId: movimiento.estado.id, //EstadosTipos
      fecha: movimiento.fecha,
      Link: movimiento.link,
      EntidadTipo: entidadTipoId, // Enum, anteproyecto, proyecto, normativa, ley
    };
    return this.http.post(`${environment.backend_url}/movimientos`, movRequest);
  }

  putMovimiento(movimiento, entidadId, entidadTipoId) {
    const movRequest = {
      id: movimiento.id,
      EntidadId: entidadId, // Id del anteproyecto, proyecto, normativa, ley
      descripcion: movimiento.descripcion,
      estadoId: movimiento.estadoId, //EstadosTipos
      fecha: movimiento.fecha,
      Link: movimiento.link,
      EntidadTipo: entidadTipoId, // Enum, anteproyecto, proyecto, normativa, ley
    };
    return this.http.put(
      `${environment.backend_url}/movimientos/${movimiento.id}`,
      movRequest
    );
  }

  deleteMovimiento(id) {
    return this.http.delete(`${environment.backend_url}/movimientos/${id}`);
  }

  // AUTORES

  postAutor(autor) {
    const autorRequest = {
      nombreApellido: autor.nombreApellido,
      paisId: autor.pais,
      camaraId: autor.camaraId,
      bloqueId: autor.bloqueId,
      posicion: autor.posicion,
      perfilOficial: autor.perfilOficial,
    };
    return this.http.post(`${environment.backend_url}/autores`, autorRequest);
  }

  putAutor(autor) {
    const autorRequest = {
      id: autor.id,
      nombreApellido: autor.nombreApellido,
      paisId: autor.pais,
      camaraId: autor.camaraId,
      bloqueId: autor.bloqueId,
      posicion: autor.posicion,
      perfilOficial: autor.perfilOficial,
    };
    return this.http.put(
      `${environment.backend_url}/autores/${autor.id}`,
      autorRequest
    );
  }

  deleteAutor(id) {
    return this.http.delete(`${environment.backend_url}/autores/${id}`);
  }

  // COMISIONES

  putComision(comision) {
    return this.http.put(
      `${environment.backend_url}/comisiones/${comision.id}`,
      comision
    );
  }

  postComision(comision) {
    return this.http.post(`${environment.backend_url}/comisiones`, comision);
  }

  deleteComision(id) {
    return this.http.delete(`${environment.backend_url}/comisiones/${id}`);
  }

  getComisionesByCamaraId(id) {
    return this.http.get(
      `${environment.backend_url}/comisiones?camaraId=${id}`
    );
  }

  getComisionesByPaisId(id) {
    return this.http.get(`${environment.backend_url}/comisiones?paisId=${id}`);
  }

  // Usuarios

  getUsuarios(qp?: UsuariosQP) {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }

    return this.http.get(`${environment.backend_url}/usuarios`, {
      params: params,
    });
  }

  getProfile() {
    if (this.cookieService.get('token')) {
      const headerDict = {
        Authorization: this.cookieService.get('token'),
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.get(
        `${environment.backend_url}/usuarios/profile`,
        requestOptions
      );
    }
  }

  getUsuario(id) {
    return this.http.get(`${environment.backend_url}/usuarios/${id}`);
  }

  postUsuario(usuario) {
    return this.http.post(`${environment.backend_url}/usuarios`, usuario);
  }

  putUsuario(usuario) {
    return this.http.put(
      `${environment.backend_url}/usuarios/${usuario.id}`,
      usuario
    );
  }

  deleteUsuario(id) {
    return this.http.delete(`${environment.backend_url}/usuarios/${id}`);
  }

  // Temas

  deleteTema(id) {
    return this.http.delete(`${environment.backend_url}/temas/${id}`);
  }

  putTema(tema) {
    return this.http.put(`${environment.backend_url}/temas/${tema.id}`, tema);
  }

  postTema(tema) {
    return this.http.post(`${environment.backend_url}/temas`, tema);
  }

  // Bloques

  getBloques() {
    return this.http.get(`${environment.backend_url}/bloques`);
  }

  putBloque(bloque) {
    return this.http.put(
      `${environment.backend_url}/bloques/${bloque.id}`,
      bloque
    );
  }

  postBloque(bloque) {
    return this.http.post(`${environment.backend_url}/bloques`, bloque);
  }

  deleteBloque(id) {
    return this.http.delete(`${environment.backend_url}/bloques/${id}`);
  }

  // Alertas
  getAlertas(qp?: AlertasQP): Observable<AlertaV2Response[]> {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http
      .get<AlertaV2Response[]>(`${environment.backend_url}/alertas`, {
        params: params,
      })
      .pipe(
        catchError((error) => {
          console.error('Error retrieving alertas', error);
          return throwError([]);
        })
      );
  }

  formAlertas(): Observable<any> {
    return this.http.get(`${environment.backend_url}/alertas/create`).pipe(
      catchError((error) => {
        console.error('Error forming alertas', error);
        return throwError([]);
      })
    );
  }

  sendOneAlerta(payload: SendAlertaRequest): Observable<SendAlertaResponse> {
    return this.http
      .post<SendAlertaResponse>(
        `${environment.backend_url}/alertas/send`,
        payload
      )
      .pipe(
        catchError((error) => {
          console.error('Error sending alerta', error);
          return throwError([]);
        })
      );
  }

  // Newsletters
  getNewsletters(qp?: NewslettersQP): Observable<NewsletterResponse[]> {
    let params = new HttpParams();
    if (qp) {
      Object.keys(qp).forEach((key) => {
        const value = qp[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            params = params.append(key, item.toString());
          });
        } else if (value) {
          params = params.append(key, value);
        }
      });
    }
    return this.http
      .get<NewsletterResponse[]>(`${environment.backend_url}/newsletters`, {
        params: params,
      })
      .pipe(
        catchError((error) => {
          console.error('Error retrieving newsletters', error);
          return throwError([]);
        })
      );
  }

  getNewsletterById(id: number): Observable<NewsletterDetailedResponse> {
    return this.http
      .get<NewsletterDetailedResponse>(
        `${environment.backend_url}/newsletters/${id}`
      )
      .pipe(
        catchError((error) => {
          console.error('Error retrieving newsletter by id', error);
          return throwError([]);
        })
      );
  }

  getNewsletterSync(): Observable<NewsletterSyncResponse> {
    return this.http
      .get<NewsletterSyncResponse>(
        `${environment.backend_url}/newsletters/sync-status`
      )
      .pipe(
        catchError((error) => {
          console.error('Error retrieving sync status', error);
          return throwError([]);
        })
      );
  }

  deleteNewsletter(id: number): Observable<any> {
    return this.http
      .delete(`${environment.backend_url}/newsletters/${id}`)
      .pipe(
        catchError((error) => {
          console.error('Error deleting newsletter', error);
          return throwError([]);
        })
      );
  }

  sendNewsletter(id: number): Observable<any> {
    return this.http.post(
      `${environment.backend_url}/newsletters/send/${id}`,
      {}
    );
  }

  syncNewsletters(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Connection: 'Keep-Alive',
        'Keep-Alive': 'timeout=600, max=1000',
      }),
    };
    return this.http.get(
      `${environment.backend_url}/newsletters/create`,
      httpOptions
    );
  }

  // Misc

  getEntityByQueryParams(entity, params: { [key: string]: any }) {
    const queryString = Object.keys(params)
      .filter((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        return value !== null && value !== '';
      })
      .map((key) => {
        const value = params[key];
        if (Array.isArray(value)) {
          return value
            .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
            .join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return this.http.get(`${environment.backend_url}/${entity}?${queryString}`);
  }
}
