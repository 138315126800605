<div class="tab-content" id="navTabContent">
  <div
    class="tab-pane fade show active"
    id="news"
    role="tabpanel"
    aria-labelledby="news-tab"
  >
    <app-email-send
      *ngIf="!loading"
      [isPremade]="true"
      [syncRunning]="syncRunning"
      [newsletters]="newsletters"
      [clientes]="clientes"
      (syncedEvent)="handleSync()"
    ></app-email-send>
  </div>
</div>
