import { Component, OnInit } from '@angular/core';
import { LukeService } from '../panelServices/luke.service';
import { TemaModel } from '../entidades/models/tema.model';
import { SearchTemasPipe } from 'src/app/core/pipes/search-tema.pipe';
import Swal from 'sweetalert2';
import { ClienteModel } from '../entidades/models/clientes.model';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-temas',
  templateUrl: './temas.component.html',
  styleUrls: ['./temas.component.css'],
})
export class TemasComponent implements OnInit {
  temas: TemaModel[];
  editingIndex = -1;
  oldDesc: string;
  oldClientesRelacionados: number[];
  searchTerm: string;
  clientes: ClienteModel[];

  constructor(private lukeService: LukeService) {}

  ngOnInit(): void {
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();
    forkJoin([
      this.lukeService.getTemas(),
      this.lukeService.getClientes()
    ]).subscribe(([temas, clientes]: [TemaModel[], ClienteModel[]]) => {
      this.temas = temas;
      this.clientes = clientes;
      this.temas.forEach((tem) => {
        tem.clientesRelacionados = []
        return tem.temasClientes.forEach((tc) => {
          return tem.clientesRelacionados.push(tc.clienteId);
        })
      })
      Swal.close();
    });
    
  }

  refreshTemas() {
    this.lukeService.getTemas().subscribe((res: TemaModel[]) => {
      this.temas = res;
      this.temas.forEach((tem) => {
        tem.clientesRelacionados = []
        return tem.temasClientes.forEach((tc) => {
          return tem.clientesRelacionados.push(tc.clienteId);
        })
      })
    });
  }

  getClientesString(temasClientes: any[]): string {
    if (temasClientes.length === 0) {
      return "Sin cliente";
    }
  
    const clienteNames: string[] = temasClientes.map(tc => {
      const cliente = this.clientes.find(c => c.id === tc.clienteId);
      return cliente ? cliente.nombreCliente : undefined;
    });
  
    const filteredNames: string[] = clienteNames.filter(name => name !== undefined);
    return filteredNames.join(", ");
  }

  onOpenEdit(index) {
    this.editingIndex = index;
  }

  onCancel(tema) {
    this.editingIndex = -1;
    tema.descripcionTema = this.oldDesc;
    tema.clientesRelacionados = this.oldClientesRelacionados;
    this.oldDesc = '';
    this.oldClientesRelacionados = null;
  }

  onSave(tema) {
    Swal.fire({
      text: 'Agregando',
    });
    Swal.showLoading();
    const payload = tema;
    payload.DescripcionTema = tema.DescripcionTema;
    payload.clientesId = tema.clientesRelacionados;
    this.lukeService.putTema(tema).subscribe((res) => {
      this.editingIndex = -1;
      this.refreshTemas();
      Swal.fire('Guardado', 'El tema fue modificado.', 'success');
    });
  }

  onSubmit(newTema) {
    Swal.fire({
      text: 'Agregando',
    });
    Swal.showLoading();
    const payload = new TemaModel();
    payload.descripcionTema = newTema;
    payload.id = '0';
    this.lukeService.postTema(payload).subscribe((res) => {
      this.searchTerm = '';
      this.refreshTemas();
      Swal.fire('Agregado', 'El tema fue agregado', 'success');
    });
  }

  onDelete(id: string) {
    Swal.fire({
      title: 'Borrar tema',
      text: '¿Está seguro que quiere borrar este tema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService.deleteTema(id).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'El tema fue borrado', 'success');
            this.refreshTemas();
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo',
            });
          }
        );
      } else {
        return;
      }
    });
  }
}
