import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AnteProyectoModel } from 'src/app/panel/entidades/models/anteproyecto.model';
import { ClienteModel } from 'src/app/panel/entidades/models/clientes.model';
import { LeyModel } from 'src/app/panel/entidades/models/ley.model';
import { NormativaModel } from 'src/app/panel/entidades/models/normativa.model';
import { NotaModel } from 'src/app/panel/entidades/models/nota.model';
import { PagedResponse } from 'src/app/panel/entidades/models/pagedResponse.interface';
import { ProyectoModel } from 'src/app/panel/entidades/models/proyecto.model';
import { UsuarioModel } from 'src/app/panel/entidades/models/usuario.model';
import { usuarioNewsletter } from 'src/app/panel/entidades/models/usuarioNewsletter.model';
import { LukeService } from 'src/app/panel/panelServices/luke.service';
import showLoading from 'src/utils/showLoading';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.css'],
})
export class EntitySelectComponent implements OnInit {
  @Input() clientData: any;
  isEditing: boolean = false;
  isEditingId: number;
  proyectos: ProyectoModel[];
  anteproyectos: AnteProyectoModel[];
  normativas: NormativaModel[];
  leyes: LeyModel[];
  notas: NotaModel[];
  selectedProyectos: ProyectoModel[] = [];
  selectedAnteproyectos: AnteProyectoModel[] = [];
  selectedNormativas: NormativaModel[] = [];
  selectedLeyes: LeyModel[] = [];
  selectedNotas: NotaModel[] = [];
  @Output() backDataEvent = new EventEmitter<any>();
  @Output() entityDataEvent = new EventEmitter<any>();

  constructor(private lukeService: LukeService) {}

  ngOnInit(): void {
    this.clientData[1] = this.clientData[1].map((us) => {
      let user = new usuarioNewsletter();
      return (us = Object.assign(user, us));
    });
  }

  getClientName(id) {
    let client = this.clientData[0].find((cli) => cli.id == id);

    return client.nombreCliente;
  }

  getEditingEntities(usuario) {
    this.isEditing = true;
    showLoading('Recuperando entidades del cliente.');
    forkJoin([
      this.lukeService.getEntityByQueryParams('notas', {
        clienteId: usuario.clienteId,
        pageSize: 9999,
      }),
    ]).subscribe(([notas]: [PagedResponse<NotaModel>]) => {
      this.notas = notas.items;
      this.notas = this.notas.sort((a, b) => {
        const dateA = new Date(a.fecha);
        const dateB = new Date(b.fecha);
        return Number(dateB) - Number(dateA);
      });
      Swal.close();
    });
  }

  arraysAreEmpty() {
    let length = Number();
    this.clientData[1].forEach((element) => {
      length += element.selectedNotas.length;
    });
    if (length > 0) {
      return false;
    }
    return true;
  }

  toggleSelection(item: any, type: string): void {
    let user = this.clientData[1].find((us) => us.id == this.isEditingId);
    switch (type) {
      case 'proyectos':
        const proyectosIndex = this.selectedProyectos.indexOf(item);
        if (proyectosIndex > -1) {
          this.selectedProyectos.splice(proyectosIndex, 1);
        } else {
          this.selectedProyectos.push(item);
        }
        break;
      case 'normativas':
        const normativasIndex = this.selectedNormativas.indexOf(item);
        if (normativasIndex > -1) {
          this.selectedNormativas.splice(normativasIndex, 1);
        } else {
          this.selectedNormativas.push(item);
        }
        break;
      case 'anteproyectos':
        const anteproyectosIndex = this.selectedAnteproyectos.indexOf(item);
        if (anteproyectosIndex > -1) {
          this.selectedAnteproyectos.splice(anteproyectosIndex, 1);
        } else {
          this.selectedAnteproyectos.push(item);
        }
        break;
      case 'notas':
        const exists = user.selectedNotas.some(
          (nota) => JSON.stringify(nota) === JSON.stringify(item)
        );
        if (exists) {
          user.selectedNotas.splice(user.selectedNotas.indexOf(item), 1);
        } else {
          user.selectedNotas.push(item);
        }
        break;
      case 'leyes':
        const leyesIndex = this.selectedLeyes.indexOf(item);
        if (leyesIndex > -1) {
          this.selectedLeyes.splice(leyesIndex, 1);
        } else {
          this.selectedLeyes.push(item);
        }
        break;
      default:
        break;
    }
  }

  parseDate(fecha) {
    return formatDate(fecha, 'dd-MM-yyyy', 'en-US');
  }

  isSelected(item: any, type: string): boolean {
    let user = this.clientData[1].find((us) => us.id == this.isEditingId);
    switch (type) {
      case 'proyectos':
        return this.selectedProyectos.indexOf(item) > -1;
      case 'normativas':
        return this.selectedNormativas.indexOf(item) > -1;
      case 'anteproyectos':
        return this.selectedAnteproyectos.indexOf(item) > -1;
      case 'notas':
        return user.selectedNotas.some(
          (nota) => JSON.stringify(nota) === JSON.stringify(item)
        );
      case 'leyes':
        return this.selectedLeyes.indexOf(item) > -1;
      default:
        return false;
    }
  }

  emitEntityData() {
    this.clientData[1] = this.clientData[1].filter(
      (us) => us.selectedNotas.length > 0
    );
    const entityData = this.clientData;
    this.entityDataEvent.emit(entityData);
  }

  emitBackData() {
    const backData = this.clientData;
    this.backDataEvent.emit(backData);
  }
}
