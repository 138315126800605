export class UsuarioModel {
    id: number;
    clienteId: number;
    nombre: string;
    apellido: string;
    email: string;
    isActive: boolean;
    password: any;
    serviciosContratados: any;
    serviciosDashboard: any;
}
