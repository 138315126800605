export class UsuariosMapper {
  public static mapToRequest(formData, isStaff) {
    let usuarioRequest = {
      id: formData?.id ?? 0,
      nombre: formData.nombre,
      apellido: formData.apellido,
      email: formData.email,
      password: formData.password != '' ? formData.password : null,
      isActive: formData.isActive ?? true,
      clienteId: formData.clienteId,
      projects: formData.projects,
    };
    if (!isStaff) {
      usuarioRequest['serviciosPaises'] = {
        Alertas: UsuariosMapper.renamePaisProperty(formData.alertas),
        Dashboard: UsuariosMapper.renamePaisProperty(formData.dashboard),
        Scraper: UsuariosMapper.renamePaisProperty(formData.scraper),
        Newsletter: UsuariosMapper.renamePaisProperty(formData.newsletter),
      };
      usuarioRequest['serviciosDashboardPaises'] = {
        Notas: UsuariosMapper.renamePaisProperty(formData.dashboardNotas),
        Normativas: UsuariosMapper.renamePaisProperty(formData.dashboardNormativas),
        Proyectos: UsuariosMapper.renamePaisProperty(formData.dashboardProyectos),
      };
    }

    return usuarioRequest;
  }

  public static renamePaisProperty(objArray) {
    if (objArray.length > 0) {
      objArray.forEach((pais) => {
          if (pais.nombre) {
            pais.name = pais.nombre;
          }
          delete pais.nombre;
        });
    }
      

    return objArray;
  }
}
