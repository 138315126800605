export enum CamarasEnum {
    None,
    AsambleaLegislativa,
    AsambleaNacional,
    AsambleaNacionaldelPoderPopular,
    CámaradeDiputados,
    CámaradeRepresentantes,
    CámaradeSenadores,
    CongresodelaRepública,
    CongresoNacional,
    CongresoUnicameral,
    Senado,
    SenadodelaRepública,
    SenadoFederal,
    Noaplica
}