<section>
  <div class="row">
    <div class="col-xl-12 col-md-12 col-12">
      <div class="card marketing-campaigns">
        <div class="card-body">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="proyectos-tab"
                data-toggle="tab"
                data-target="#proyectos"
                type="button"
                role="tab"
                aria-controls="proyectos"
                aria-selected="true"
              >
                Proyectos
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="normativas-tab"
                data-toggle="tab"
                data-target="#normativas"
                type="button"
                role="tab"
                aria-controls="normativas"
                aria-selected="false"
              >
                Normativas
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade"
              id="normativas"
              role="tabpanel"
              aria-labelledby="normativas-tab"
            >
              <div class="col-12">
                <div class="card marketing-campaigns">
                  <div
                    class="card-header d-flex justify-content-between align-items-center pb-1"
                  >
                    <h4 class="card-title">Normativas</h4>
                  </div>
                  <div class="table-responsive ps ps--active-x">
                    <table
                      id="table-marketing-campaigns"
                      class="table table-borderless mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Título</th>
                          <th>Fecha</th>
                          <th>Ver más</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let normativa of normativas
                              | appFilter : this.searchTerm;
                            let i = index
                          "
                        >
                          <td>{{ normativa.titulo }}</td>
                          <td>{{ parseDate(normativa.fechaPublicacion) }}</td>
                          <td>
                            <button
                              class="btn btn-primary"
                              [routerLink]="[
                                '/dashboard/scraping/normativas',
                                normativa.id
                              ]"
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade show active"
              id="proyectos"
              role="tabpanel"
              aria-labelledby="proyectos-tab"
            >
              <div class="col-12">
                <div class="card marketing-campaigns">
                  <div
                    class="card-header d-flex justify-content-between align-items-center pb-1"
                  >
                    <h4 class="card-title">Proyectos de Ley</h4>
                    <button
                      class="btn btn-primary"
                      type="button"
                      data-toggle="collapse"
                      data-target="#busquedaAvanzada"
                      aria-expanded="false"
                      aria-controls="busquedaAvanzada"
                    >
                      Busqueda Avanzada
                    </button>
                  </div>
                  <div class="collapse" id="busquedaAvanzada">
                    <form
                      [formGroup]="searchForm"
                      (ngSubmit)="onSubmitSearch()"
                      class="form-group"
                    >
                      <div class="row">
                        <div class="col-md-3 mb-3">
                          <label for="titulo">Titulo:</label>
                          <input
                            class="form-control"
                            type="text"
                            formControlName="titulo"
                          />
                          <label for="camara">Parlamento:</label>
                          <ng-select
                            *ngIf="parlamentos.length > 0"
                            placeholder="Seleccione una opcion"
                            appendTo="body"
                            [items]="parlamentos"
                            [searchable]="true"
                            formControlName="camaraId"
                            bindLabel="nombreCamara"
                            bindValue="id"
                          ></ng-select>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="numeroExpediente"
                            >Numero de Expediente:</label
                          >
                          <input
                            class="form-control"
                            type="text"
                            formControlName="numero"
                          />
                          <label for="contenido">Contenido:</label>
                          <input
                            class="form-control"
                            type="text"
                            formControlName="contenido"
                          />
                        </div>
                        <div class="col-md-3 mb-3">
                          <label>Filtrar por fecha de ingreso</label>
                          <div>
                            <mat-form-field>
                              <input
                                matInput
                                [matDatepicker]="startDateSearchPicker"
                                formControlName="fechaDesde"
                                placeholder="desde"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="startDateSearchPicker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker
                                #startDateSearchPicker
                              ></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field>
                              <input
                                matInput
                                [matDatepicker]="endDateSearchPicker"
                                formControlName="fechaHasta"
                                placeholder="hasta"
                              />
                              <mat-datepicker-toggle
                                matSuffix
                                [for]="endDateSearchPicker"
                              ></mat-datepicker-toggle>
                              <mat-datepicker
                                #endDateSearchPicker
                              ></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="autores">Filtrar por Autor</label>
                          <ng-select
                            placeholder="Seleccione una opcion"
                            appendTo="body"
                            [items]="autores"
                            [searchable]="true"
                            [multiple]="true"
                            formControlName="autoresId"
                            bindLabel="nombreApellido"
                            bindValue="id"
                          ></ng-select>
                          <label for="keyword">Palabra Clave:</label>
                          <input
                            class="form-control"
                            type="text"
                            formControlName="keyword"
                          />
                        </div>
                      </div>
                    </form>
                    <div class="row">
                      <div class="col-md-3 mb-3">
                        <button
                          type="button"
                          class="btn btn-primary ml-1"
                          (click)="onSubmitSearch()"
                        >
                          <i class="bx bx-check d-block d-sm-none"></i>
                          <span class="d-none d-sm-block">Buscar</span>
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary ml-1"
                          (click)="resetFilters()"
                        >
                          <i class="bx bx-check d-block d-sm-none"></i>
                          <span class="d-none d-sm-block"
                            >Reiniciar Filtros</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive ps ps--active-x">
                    <table
                      id="table-marketing-campaigns"
                      class="table table-borderless mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Título</th>
                          <th>Fecha Ultimo Movimiento</th>
                          <th>Camara</th>
                          <th>Numero de Expediente</th>
                          <th>Fecha de Ingreso</th>
                          <th>Fecha de Creación</th>
                          <th>Fecha de Última Modificación</th>
                          <th>Ver más</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let proyecto of proyectos
                              | appFilter : this.searchTerm
                              | camaraPipe : this.camaraFilter
                              | dateRangeFilter : this.startDate : this.endDate
                              | filterAutores : this.autorFilter;
                            let i = index
                          "
                        >
                          <td>{{ proyecto.titulo }}</td>
                          <td>
                            {{
                              parseDate(proyecto.fechaUltimaModificacion) ??
                                "No disponible"
                            }}
                          </td>
                          <td>
                            {{ getNombreCamaraById(proyecto.camaraId) }}
                          </td>
                          <td>{{ proyecto.expedienteNumero }}</td>
                          <td>{{ parseDate(proyecto.fechaIngreso) }}</td>
                          <td>{{ parseDate(proyecto.createdAt) }}</td>
                          <td>{{ parseDate(proyecto.updatedAt) }}</td>
                          <td>
                            <button
                              class="btn btn-primary"
                              [routerLink]="[
                                '/dashboard/scraping/proyectos',
                                proyecto.id
                              ]"
                            >
                              +
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <tfoot>
                      <nav aria-label="paginacion">
                        <ul class="pagination justify-content-end">
                          <li class="page-item">
                            <a
                              class="page-link"
                              *ngIf="
                                this.pagination.previous.slice(-1)[0] != null
                              "
                              (click)="getNextOrPreviousPage('prev')"
                              >Anterior</a
                            >
                          </li>
                          <li class="page-item">
                            <a
                              class="page-link"
                              (click)="getNextOrPreviousPage('next')"
                              >Siguiente</a
                            >
                          </li>
                        </ul>
                      </nav>
                    </tfoot>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
