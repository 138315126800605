import { Component, OnInit } from '@angular/core';
import { UsuarioModel } from 'src/app/panel/entidades/models/usuario.model';
import { LukeService } from 'src/app/panel/panelServices/luke.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  usuarios: UsuarioModel[];

  constructor(private lukeService: LukeService) { }

  private getUsuarios() {
    Swal.fire({
      title: 'Cargando',
      didOpen: () => {
        Swal.showLoading()
      }
    });
    let promise: Promise<any>;
    promise = this.lukeService.getEntityByQueryParams("usuarios", {"staff":true, "showInactive": true}).toPromise();
    promise.then((res) => {
      this.usuarios = res;
      Swal.close();
    }).catch((error) => {
      Swal.fire({
        title: 'Error!',
        text: 'Ocurrió un error recuperando los usuarios.',
        icon: 'error'
      });
      console.log(error);
    })
  }

  ngOnInit(): void {
    this.getUsuarios();
  }

  handleUsuarioMod(data: any) {
    this.getUsuarios();
  }

}
