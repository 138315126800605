import { CamaraModel } from "./camaras.model";

export class ComisionesModel {
  id: number;
  nombre: string;
  name: string;
  camaraPaisId: number;
  camaraPais: string;
  camara: CamaraModel;
}
