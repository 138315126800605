<div class="container-fluid">
  <div class="row mb-4">
    <div class="col-md-4">
      <div>
        <button class="btn btn-primary mr-2" (click)="emitBackData()">Volver</button>
        <button class="btn btn-primary" [disabled]="arraysAreEmpty()" (click)="emitEntityData()">Previsualización</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 clientes-card-container">
      <div
        *ngFor="let selectedUsuario of clientData[1]; let i = index"
      >
        <div>
          <div
            class="card mb-3 focus move-to-top-left"
            style="margin-right: 10px"
          >
            <div class="card-body">
              <h5 class="card-title">
                {{ selectedUsuario.nombre + selectedUsuario.apellido }}
              </h5>
              <p class="card-text">{{ getClientName(selectedUsuario.clienteId) }}</p>
              <div class="d-flex justify-content-between">
                <button [disabled]="isEditing" class="btn btn-primary mr-2" (click)="clientData[1].splice(i,1)">Eliminar</button>
                <button *ngIf="!isEditing || (isEditing && isEditingId != selectedUsuario.id) " [disabled]=" isEditing && isEditingId != selectedUsuario.id" class="btn btn-primary" (click)="getEditingEntities(selectedUsuario); isEditingId=selectedUsuario.id">Editar entidades</button>
                <button *ngIf="isEditing && isEditingId == selectedUsuario.id" (click)="isEditing = false; isEditingId=null" class="btn btn-secondary">Finalizar edición</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid" *ngIf="isEditing">
  <div>
    <h5>Seleccioná las entidades que irán dentro del Newsletter del usuario</h5>
  </div>
</div>

<hr />
<div *ngIf="isEditing" class="container-fluid">
  <div class="accordion" id="accordionExample">
    <div class="card">
      <div class="card-header" id="headingFive">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFive"
            aria-expanded="false"
            aria-controls="collapseFive"
          >
            Notas
          </button>
        </h2>
      </div>
      <div
        id="collapseFive"
        class="collapse"
        aria-labelledby="headingFive"
        data-parent="#accordionExample"
      >
        <div class="card-body">
          <ul class="list-group list-group-grid">
            <li
              class="list-group-item"
              *ngFor="let nota of notas"
              [ngClass]="{ 'selected-item': isSelected(nota, 'notas') }"
              (click)="toggleSelection(nota, 'notas')"
            >
              {{ nota.titulo }} - {{ nota.pais }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
