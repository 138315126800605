<!-- BEGIN: Header-->
<div class="header-navbar-shadow"></div>
<nav class="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top navbar-dark">
    <div class="navbar-wrapper">
        <div class="navbar-container content">
            <div class="navbar-collapse" id="navbar-mobile">
                <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                    <ul class="nav navbar-nav">
                    </ul>
                </div>
                <ul class="nav navbar-nav float-right">
                    <li *ngIf="profile" class="dropdown dropdown-user nav-item">
                        <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                            <div class="user-nav d-sm-flex d-none">
                              <span class="user-name">{{profile.firstName}} {{profile.lastName}}</span>
                              <span class="user-status text-muted"><a (click)="onCloseSession()">Cerrar Sesión</a></span>
                            </div>
                            <span>
                              <img class="round" src="./assets/images/portrait/profile.jpg" alt="avatar" height="40" width="40">
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right pb-0">
                            <a class="dropdown-item" href="#"><i class="bx bx-user mr-50"></i>Perfil</a>
                            <div class="dropdown-divider mb-0"></div><a class="dropdown-item" (click)="onCloseSession()"><i class="bx bx-power-off mr-50"></i> Salir</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<!-- END: Header-->
