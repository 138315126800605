import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { PanelRoutingModule } from './panel/panel.routing';
import { AuthRoutingModule } from './auth/auth-routing.module';


const routes: Routes = [

  {path: '', redirectTo: '/dashboard/entidades', pathMatch: 'full'},
  {path: '**', component: NotFoundComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    PanelRoutingModule,
    AuthRoutingModule
    ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
