<ng-container *ngIf="newsletters && newsletters.length > 0; else noNewsletters">
  <div class="row mb-4">
    <div class="col-md-3">
      <button class="btn btn-primary mr-2" (click)="selectAll()">
        Aprobar todos
      </button>
    </div>
    <div class="col-md-3">
      <button class="btn btn-primary mr-2" (click)="removeAll()">
        Deseleccionar todos
      </button>
    </div>
    <div class="col-md-3">
      <button
        class="btn btn-primary mr-2"
        [disabled]="approvedIds.length < 1"
        (click)="sendAllApproved()"
      >
        Enviar todos los aprobados
      </button>
      <button class="btn btn-primary" *ngIf="!isPremade">Volver</button>
    </div>
    <div class="col-md-3">
      <button
        type="btn btn-primary mr-2"
        (click)="syncNewsletters()"
        *ngIf="!syncRunning"
        class="btn btn-primary"
      >
        Sincronizar
      </button>
      <button
        type="btn btn-primary mr-2 disabled"
        disabled
        *ngIf="syncRunning"
        class="btn btn-primary"
      >
        Sincronización en curso
      </button>
    </div>
  </div>
  <div class="group-container" *ngFor="let group of groupedNews | keyvalue">
    <div class="row">
      <div
        class="group-header d-flex w-100"
        data-toggle="collapse"
        [attr.data-target]="'#groupCollapse' + group.key"
      >
        <div class="mr-auto p-2 d-flex flex-row">
          <h4 class="mr-2">{{ getClientName(group.key) }}</h4>
          <span class="arrow-icon">
            <fa-icon [icon]="faChevronDown"></fa-icon>
          </span>
        </div>
        <div class="p-2">
          <button
            class="btn btn-primary ml-2"
            (click)="selectAllClient(group.key, $event)"
          >
            Seleccionar todo el cliente
          </button>
          <button
            class="btn btn-primary ml-2"
            (click)="removeAllClient(group.key, $event)"
          >
            Deseleccionar todo el cliente
          </button>
        </div>
      </div>
      <div id="{{ 'groupCollapse' + group.key }}" class="collapse row">
        <div
          class="col-md-auto"
          *ngFor="let newsletter of group.value; let i = index"
        >
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">
                {{
                  newsletter.recipient.nombre + newsletter.recipient.apellido
                }}
              </h5>
              <p class="card-text">
                {{ getClientName(newsletter.recipient.clienteId) }}
              </p>
              <div class="row mb-2">
                <div class="col">
                  <button
                    [disabled]="isPreviewing"
                    (click)="deleteNewsletter(newsletter.id, group, i)"
                    class="btn btn-primary btn-block"
                  >
                    Eliminar
                  </button>
                </div>
                <div class="col">
                  <button
                    *ngIf="
                      !isPreviewing ||
                      (isPreviewing && isPreviewingId != newsletter.id)
                    "
                    [disabled]="isPreviewing && isPreviewingId != newsletter.id"
                    class="btn btn-primary btn-block"
                    (click)="openPreview(newsletter.id)"
                  >
                    Vista Previa
                  </button>
                  <button
                    *ngIf="isPreviewing && isPreviewingId == newsletter.id"
                    (click)="isPreviewing = false; isPreviewingId = null"
                    class="btn btn-secondary btn-block"
                  >
                    Cerrar preview
                  </button>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <button
                    *ngIf="!approvedIds.includes(newsletter.id)"
                    (click)="approvedIds.push(newsletter.id)"
                    class="btn btn-success btn-block"
                  >
                    Aprobar
                  </button>
                  <button
                    *ngIf="approvedIds.includes(newsletter.id)"
                    (click)="removeOne(newsletter.id)"
                    [disabled]="isPreviewing && isPreviewingId != newsletter.id"
                    class="btn btn-secondary btn-block"
                  >
                    Quitar
                  </button>
                </div>
                <div class="col">
                  <button
                    class="btn btn-primary btn-block"
                    (click)="sendOneNewsletter(newsletter.id, group, i)"
                  >
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="group-separator"></div>
    </div>
  </div>
</ng-container>

<ng-template #noNewsletters>
  <div class="emptyState">
    <span *ngIf="!syncRunning"> No hay newsletters pendientes de envío </span>
    <span *ngIf="syncRunning">
      Se mostrarán los newsletters una vez que finalice la sincronización
    </span>
    <button
      type="button"
      (click)="syncNewsletters()"
      *ngIf="!syncRunning"
      class="btn btn-primary"
    >
      Sincronizar
    </button>
  </div>
</ng-template>
