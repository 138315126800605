import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsuarioModel } from '../../../entidades/models/usuario.model';
import { LukeService } from '../../../panelServices/luke.service';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { UsuariosMapper } from 'src/utils/usuariosMapper';
import renameProperties from 'src/utils/propertyRename';

interface Selectable {
  id: number;
  name: string;
}

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
})
export class UsuariosComponent implements OnInit {
  @Input() usuarios: UsuarioModel[];
  @Input() clienteId: number;
  @Input() isStaff: boolean;
  @Input() serviciosDashboard: any;
  @Input() serviciosContratados: any;
  usuario: UsuarioModel;
  usuarioForm?: UntypedFormGroup;
  alertas: Selectable[] = [];
  scraper: Selectable[] = [];
  newsletter: Selectable[] = [];
  dashboard: Selectable[] = [];
  dashboardNotas: Selectable[] = [];
  dashboardNormativas: Selectable[] = [];
  dashboardProyectos: Selectable[] = [];

  constructor(
    private lukeService: LukeService,
    private formBuilder: UntypedFormBuilder
  ) {}

  @Output() usuarioMod = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.isStaff) {
      this.serviciosContratados = renameProperties(this.serviciosContratados);
      this.serviciosDashboard = renameProperties(this.serviciosDashboard);
    }
    this.usuario = new UsuarioModel();
    this.usuariosForm();
  }

  getUsuario(id) {
    return new Promise<void>((resolve, reject) => {
      this.lukeService.getUsuario(id).subscribe(
        (res: UsuarioModel) => {
          if (!this.isStaff) {
            if (res.serviciosContratados) {
              res.serviciosContratados.forEach((serv) => {
                if (serv.servicioId == 1) {
                  this.alertas = serv.paises?.map((pais) => {
                    return { id: pais.id, name: pais.nombre };
                  });
                } else if (serv.servicioId == 2) {
                  this.dashboard = serv.paises?.map((pais) => {
                    return { id: pais.id, name: pais.nombre };
                  });
                } else if (serv.servicioId == 3) {
                  this.newsletter = serv.paises?.map((pais) => {
                    return { id: pais.id, name: pais.nombre };
                  });
                } else if (serv.servicioId == 4) {
                  this.scraper = serv.paises?.map((pais) => {
                    return { id: pais.id, name: pais.nombre };
                  });
                }
              });
            }
            if (res.serviciosDashboard.length > 0) {
              const notas = res.serviciosDashboard.find(
                (servicio) => servicio.servicio === 'Notas'
              );
              const proyectos = res.serviciosDashboard.find(
                (servicio) => servicio.servicio === 'Proyectos'
              );
              const normativas = res.serviciosDashboard.find(
                (servicio) => servicio.servicio === 'Normativas'
              );

              if (notas) {
                this.dashboardNotas = notas.paises?.map((pais) => {
                  return { id: pais.id, name: pais.nombre };
                });
              }

              if (normativas) {
                this.dashboardNormativas = normativas.paises?.map((pais) => {
                  return { id: pais.id, name: pais.nombre };
                });
              }

              if (proyectos) {
                this.dashboardProyectos = proyectos.paises?.map((pais) => {
                  return { id: pais.id, name: pais.nombre };
                });
              }
            }
          }
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getPaisesService(serviceName, isDashService) {
    let filtered: any = [];
    if (!isDashService) {
      filtered = this.serviciosContratados.find(
        (s) => s.servicio == serviceName
      );
    } else {
      filtered = this.serviciosDashboard.find((s) => s.servicio == serviceName);
    }

    if (!filtered) {
      return [];
    }
    return filtered.paises;
  }

  isServicePresent(serviceName) {
    return this.serviciosContratados.some((serv) => {
      return serv.servicio == serviceName;
    });
  }

  passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('password')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    if (!password || password === '') {
      return null;
    }

    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  async usuariosForm() {
    try {
      if (this.usuario.id) {
        await this.getUsuario(this.usuario.id);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }

    this.usuarioForm = this.formBuilder.group(
      {
        id: [this.usuario?.id],
        nombre: [this.usuario?.nombre, Validators.required],
        apellido: [this.usuario?.apellido, Validators.required],
        email: [this.usuario?.email, Validators.required],
        password: [this.usuario?.password],
        isActive: [this.usuario?.isActive ?? true],
        clienteId: [this.clienteId],
        confirmPassword: [null],
      },
      {
        validators: [this.passwordMatchValidator],
      }
    );
  }

  async editUsuario(usuario) {
    this.usuario = usuario;
    await this.usuariosForm();
  }

  cleanUsuario() {
    this.alertas = [];
    this.scraper = [];
    this.newsletter = [];
    this.dashboard = [];
    this.dashboardNotas = [];
    this.dashboardNormativas = [];
    this.dashboardProyectos = [];
    this.usuario = new UsuarioModel();
  }

  get isActive() {
    return this.usuarioForm.get('isActive') as UntypedFormControl;
  }

  changeActive(value) {
    this.usuarioForm.controls['isActive'].setValue(value);
  }

  objExists(pais, caso) {
    switch (caso) {
      case 'alertas':
        return this.alertas?.some((obj) => obj.id === pais.id);
      case 'newsletter':
        return this.newsletter?.some((obj) => obj.id === pais.id);
      case 'scraper':
        return this.scraper?.some((obj) => obj.id === pais.id);
      case 'dashboard':
        return this.dashboard?.some((obj) => obj.id === pais.id);
      case 'dashboardNotas':
        return this.dashboardNotas?.some((obj) => obj.id === pais.id);
      case 'dashboardNormativas':
        return this.dashboardNormativas?.some((obj) => obj.id === pais.id);
      case 'dashboardProyectos':
        return this.dashboardProyectos?.some((obj) => obj.id === pais.id);
    }
  }

  addRemoveAlerta(pais, caso) {
    switch (caso) {
      case 'alertas':
        if (this.objExists(pais, caso))
          this.alertas = this.alertas.filter((obj) => obj.id !== pais.id);
        else this.alertas.push(pais);
        break;
      case 'newsletter':
        if (this.objExists(pais, caso))
          this.newsletter = this.newsletter.filter((obj) => obj.id !== pais.id);
        else this.newsletter.push(pais);
        break;
      case 'scraper':
        if (this.objExists(pais, caso))
          this.scraper = this.scraper.filter((obj) => obj.id !== pais.id);
        else this.scraper.push(pais);
        break;
      case 'dashboard':
        if (this.objExists(pais, caso))
          this.dashboard = this.dashboard.filter((obj) => obj.id !== pais.id);
        else this.dashboard.push(pais);
        break;
      case 'dashboardNotas':
        if (this.objExists(pais, caso))
          this.dashboardNotas = this.dashboardNotas.filter(
            (obj) => obj.id !== pais.id
          );
        else this.dashboardNotas.push(pais);
        break;
      case 'dashboardNormativas':
        if (this.objExists(pais, caso))
          this.dashboardNormativas = this.dashboardNormativas.filter(
            (obj) => obj.id !== pais.id
          );
        else this.dashboardNormativas.push(pais);
        break;
      case 'dashboardProyectos':
        if (this.objExists(pais, caso))
          this.dashboardProyectos = this.dashboardProyectos.filter(
            (obj) => obj.id !== pais.id
          );
        else this.dashboardProyectos.push(pais);
        break;
    }
  }

  submitUsuario() {
    Swal.fire({
      title: 'Cargando',
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const usuario = this.usuarioForm.value;
    if (this.isStaff) {
      usuario.projects = [{ projectId: 1, role: '1' }];
    } else {
      usuario.projects = [{ projectId: 1, role: '7' }];
    }
    if (!this.isStaff) {
      usuario.alertas = this.alertas;
      usuario.newsletter = this.newsletter;
      usuario.scraper = this.scraper;
      usuario.dashboard = this.dashboard;
      usuario.dashboardNotas = this.dashboardNotas;
      usuario.dashboardNormativas = this.dashboardNormativas;
      usuario.dashboardProyectos = this.dashboardProyectos;
    }

    let mappedValues = UsuariosMapper.mapToRequest(usuario, this.isStaff);

    let promise: Promise<any>;

    if (usuario.id) {
      promise = this.lukeService.putUsuario(mappedValues).toPromise();
    } else {
      delete usuario.id;
      promise = this.lukeService.postUsuario(mappedValues).toPromise();
    }
    promise
      .then((res) => {
        this.usuarioMod.emit(this.clienteId);
        this.cleanUsuario();
        Swal.fire({
          title: 'Modificado',
          text: 'El usuario fue modificado.',
          icon: 'success',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error!',
          text: 'Ocurrio un error intentando modificar el usuario.',
          icon: 'error',
        });
        console.log(error);
      });
  }

  addUsuario() {
    this.usuario = new UsuarioModel();
    this.usuariosForm();
  }

  onDelete(id: string) {
    Swal.fire({
      title: 'Borrar usuario',
      text: 'Esta seguro que quiere borrar este usuario?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        let promise: Promise<any>;
        promise = this.lukeService.deleteUsuario(id).toPromise();
        promise
          .then((res) => {
            Swal.fire({
              title: 'Borrado',
              text: 'El usuario fue eliminado.',
              icon: 'success',
            });
            this.usuarioMod.emit(this.clienteId);
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error!',
              text: 'Ocurrio un error intentando borrar el usuario.',
              icon: 'error',
            });
            console.log(error);
          });
      }
    });
  }
}
