import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgPipesModule } from 'ngx-pipes';
import { PanelModule } from '../panel.module';
import { AlertasRoutingModule } from './alertas-routing.module';
import { ReplyToModalComponent } from './reply-to-modal/reply-to-modal.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { EntitySelectComponent } from './entity-select/entity-select.component';

@NgModule({
  declarations: [UserSelectComponent, ReplyToModalComponent, EntitySelectComponent],
  imports: [
    CommonModule,
    AlertasRoutingModule,
    PanelModule,
    NgSelectModule,
    NgPipesModule,
  ],
  providers: [NgbActiveModal],
})
export class AlertasModule {}
