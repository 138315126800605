import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, of, zip } from 'rxjs';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import { S3UploadService } from 'src/app/core/services/s3-upload.service';
import { LeyMapper } from 'src/utils/leyMapper';
import showLoading from 'src/utils/showLoading';
import Swal from 'sweetalert2';
import { LukeService } from '../../../panelServices/luke.service';
import { AlertasFile } from '../../models/alertasFile.interface';
import { AnteProyectoModel } from '../../models/anteproyecto.model';
import { CamaraModel } from '../../models/camaras.model';
import { ClienteModel } from '../../models/clientes.model';
import { EstadosModel } from '../../models/estados.model';
import { LeyModel } from '../../models/ley.model';
import { MovimientoModel } from '../../models/movimiento.model';
import { NivelesGobiernoModel } from '../../models/nivelesgobierno.model';
import { NormativaModel } from '../../models/normativa.model';
import { NotaModel } from '../../models/nota.model';
import { PagedResponse } from '../../models/pagedResponse.interface';
import { PaisModel } from '../../models/pais.model';
import { ProyectoModel } from '../../models/proyecto.model';
import { TemaModel } from '../../models/tema.model';

@Component({
  selector: 'app-leyes-show',
  templateUrl: 'leyes.component.html',
  styleUrls: ['./leyes.component.css'],
})
export class LeyesShowComponent implements OnInit {
  newFileView: boolean = true;
  loading: boolean = false;
  notasLoading: boolean = false;
  anteproyectosLoading: boolean = false;
  proyectosLoading: boolean = false;
  leyesLoading: boolean = false;
  ley: any;
  leyes: PagedResponse<LeyModel>;
  anteproyectos: PagedResponse<AnteProyectoModel>;
  proyectos: PagedResponse<ProyectoModel>;
  normativas: PagedResponse<NormativaModel>;
  notas: PagedResponse<NotaModel>;
  form: UntypedFormGroup;
  paises: PaisModel[] = [];
  estados: EstadosModel[];
  temas: TemaModel[] = [];
  camaras: CamaraModel[];
  niveles: NivelesGobiernoModel[];
  clientes: ClienteModel[];
  impactoDict = [
    { id: 1, value: 'Alta' },
    { id: 2, value: 'Media' },
    { id: 3, value: 'Baja' },
  ];
  selectedPais: number;
  movimiento: MovimientoModel;
  movimientosRes: any;
  movForm: UntypedFormGroup;
  expedienteFile: File;
  existingFiles: AlertasFile[];
  leyId: number;

  formatDate = formatDate;
  normativasLoading: boolean;

  constructor(
    private lukeService: LukeService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private s3Service: S3UploadService
  ) {
    this.route.params.subscribe((params) => {
      params['id'] ? (this.leyId = params['id']) : this.createForm();
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      titulo: [this.ley?.titulo, Validators.required],
      pais: [this.ley?.paisId, Validators.required],
      fechaIngreso: [this.ley?.fechaIngreso, Validators.required],
      nivelGobierno: [this.ley?.nivelGobiernoId, Validators.required],
      distrito: this.ley?.distrito,
      isPublic: this.ley?.isPublic ?? false,
      clientes: [
        this.ley?.clientesAsociados.map((cliente) => cliente.id) ?? [],
        Validators.required,
      ],
      temas: [
        this.ley?.temas.map((tema) => tema.id) ?? [],
        Validators.required,
      ],
      sintesis: [this.ley?.sintesis, Validators.required],
      impacto: [this.ley?.impactoId, Validators.required],
      expedienteNumero: [this.ley?.expedienteNumero, Validators.required],
      expedienteUrl: [this.ley?.expedienteUrl],
      anteproyectosRelacionados: [
        this.ley?.anteProyectosRelacionados?.map((ap) => ap.id) ?? [],
      ],
      proyectosRelacionados: [
        this.ley?.proyectosRelacionados.map((proy) => proy.id) ?? [],
      ],
      notasRelacionadas: [
        this.ley?.notasRelacionadas.map((not) => not.id) ?? [],
      ],
      normativasRelacionadas: [
        this.ley?.normativasRelacionadas.map((norm) => norm.id) ?? [],
      ],
      leyesRelacionadas: [
        this.ley?.leyesRelacionadas.map((ley) => ley.id) ?? [],
      ],
      movimientos: this.formBuilder.array([]),
    });
    this.startArrayControls();

    this.form.controls['pais'].valueChanges.subscribe((value) =>
      this.changeDependencies(value)
    );

    this.form.controls['clientes'].valueChanges.subscribe((value) => {
      this.changeCliente(value);
    });
  }

  getLey(id: number): Observable<any> {
    return this.lukeService.getLey(id).pipe(
      tap((res) => {
        this.ley = res;
        this.ley.fechaIngreso = formatDate(
          res?.fechaIngreso,
          'yyyy-MM-dd',
          'en-US'
        );
        this.getMovimientos(3, this.ley.id);
        this.createForm();
        this.changeDependencies(res.paisId);
        this.changeCliente(res.clientesAsociados.map((val) => val.id));
      })
    );
  }

  trackById(item: any): any {
    return item.id;
  }

  getMovimientos(entidadTipoId, entidadId) {
    this.lukeService
      .getMovimientos(entidadTipoId, entidadId)
      .subscribe((res) => {
        this.movimientosRes = res;
        this.startArrayControls();
      });
  }

  startArrayControls() {
    if (this.movimientosRes && this.movimientosRes.length > 0) {
      const sortedMovimientos = this.movimientosRes.sort((mov1, mov2) => {
        return (
          new Date(mov1.fechaMovimiento).getTime() -
          new Date(mov2.fechaMovimiento).getTime()
        );
      });
      sortedMovimientos.forEach((movimiento) => {
        this.addMovimiento(
          movimiento.fechaMovimiento,
          movimiento.estado,
          movimiento.estadoId,
          movimiento.descripcion,
          movimiento.link,
          movimiento.id
        );
      });
    } else {
      return;
    }
  }

  private addMovimiento(
    fecha = null,
    estado = null,
    estadoId = null,
    descripcion = '',
    link = '',
    id = 0,
    entidadTipo = 3
  ) {
    this.movimientos.push(
      this.formBuilder.group({
        fecha,
        estado,
        estadoId,
        descripcion,
        link,
        id,
        entidadTipo,
      })
    );
  }

  ngOnInit(): void {
    this.loading = true;
    Swal.fire({ text: 'Cargando...' });
    Swal.showLoading();

    forkJoin({
      paises: this.lukeService.getPaises(),
      niveles: this.lukeService.getNiveles(),
      clientes: this.lukeService.getClientes(),
      categorias: this.lukeService.getCategorias(),
      proyectos: this.lukeService.getProyectos({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
      anteproyectos: this.lukeService.getAnteproyectos({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
      normativas: this.lukeService.getNormativas({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
      leyes: this.lukeService.getLeyes({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
      notas: this.lukeService.getNotas({
        sortBy: 'CreatedAt',
        sortOrder: 'desc',
      }),
      estados: this.lukeService.getEstados({ isLey: true }),
    })
      .pipe(
        tap((results: any) => {
          this.paises = results.paises;
          this.niveles = results.niveles;
          this.clientes = results.clientes;
          this.proyectos = results.proyectos;
          this.anteproyectos = results.anteproyectos;
          this.normativas = results.normativas;
          this.leyes = results.leyes;
          this.notas = results.notas;
          this.estados = results.estados;
          this.loading = false;
        }),
        concatMap(() => {
          if (this.leyId) {
            return this.getLey(this.leyId);
          } else {
            return of(null);
          }
        }),
        tap((ley) => {
          if (ley) {
            this.ley = ley;
            if (this.ley) {
              this.anteproyectos.items = [
                ...this.anteproyectos.items,
                ...this.ley.anteProyectosRelacionados,
              ];
              this.proyectos.items = [
                ...this.proyectos.items,
                ...this.ley.proyectosRelacionados,
              ];
              this.normativas.items = [
                ...this.normativas.items,
                ...this.ley.normativasRelacionadas,
              ];
              this.leyes.items = [
                ...this.leyes.items,
                ...this.ley.leyesRelacionadas,
              ];
              this.notas.items = [
                ...this.notas.items,
                ...this.ley.notasRelacionadas,
              ];
            }
          }
          Swal.close();
        })
      )
      .subscribe();
  }

  changeDependencies(value) {
    this.lukeService
      .getClientesFiltered(value, 2, 3)
      .subscribe((res: ClienteModel[]) => {
        this.clientes = res;
      });
  }

  scrollToEnd(entity: string) {
    if (entity === 'anteproyectos') {
      this.anteproyectosLoading = true;
      this.lukeService
        .getAnteproyectos({
          pageNumber: this.anteproyectos.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((ap: PagedResponse<AnteProyectoModel>) => {
          this.anteproyectos.items = [...this.anteproyectos.items, ...ap.items];
          this.anteproyectos.pageNumber = ap.pageNumber;
          this.anteproyectosLoading = false;
        });
    } else if (entity === 'proyectos') {
      this.proyectosLoading = true;
      this.lukeService
        .getProyectos({
          pageNumber: this.proyectos.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((proy: PagedResponse<ProyectoModel>) => {
          this.proyectos.items = [...this.proyectos.items, ...proy.items];
          this.proyectos.pageNumber = proy.pageNumber;
          this.proyectosLoading = false;
        });
    } else if (entity === 'notas') {
      this.notasLoading = true;
      this.lukeService
        .getNotas({
          pageNumber: this.notas.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((notas: PagedResponse<NotaModel>) => {
          this.notas.items = [...this.notas.items, ...notas.items];
          this.notas.pageNumber = notas.pageNumber;
          this.notasLoading = false;
        });
    } else if (entity === 'normativas') {
      this.normativasLoading = true;
      this.lukeService
        .getNotas({
          pageNumber: this.normativas.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((norms: PagedResponse<NormativaModel>) => {
          this.normativas.items = [...this.normativas.items, ...norms.items];
          this.normativas.pageNumber = norms.pageNumber;
          this.normativasLoading = false;
        });
    } else if (entity === 'leyes') {
      this.leyesLoading = true;
      this.lukeService
        .getLeyes({
          pageNumber: this.leyes.pageNumber + 1,
          paises: [this.form.get('pais').value],
          clienteId: this.form.get('clientes').value,
        })
        .subscribe((ley: PagedResponse<LeyModel>) => {
          this.leyes.items = [...this.leyes.items, ...ley.items];
          this.leyes.pageNumber = ley.pageNumber;
          this.leyesLoading = false;
        });
    }
  }

  changeCliente(value) {
    if (!value || !this.form.get('pais').value) {
      return;
    }
    showLoading();

    const anteproyectos$ = this.lukeService
      .getAnteproyectos({
        clienteId: value,
        paises: [this.form.get('pais').value],
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const notas$ = this.lukeService
      .getNotas({ clienteId: value, paises: [this.form.get('pais').value] })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const proyectos$ = this.lukeService
      .getProyectos({
        clienteId: value,
        paises: [this.form.get('pais').value],
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const leyes$ = this.lukeService
      .getLeyes({ clienteId: value, paises: [this.form.get('pais').value] })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const normativas$ = this.lukeService
      .getNormativas({
        clienteId: value,
        paises: [this.form.get('pais').value],
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    const temas$ = this.lukeService
      .getEntityByQueryParams('temas', { clienteId: value })
      .pipe(
        map((res: any) => {
          return res;
        })
      );

    zip(anteproyectos$, notas$, proyectos$, leyes$, normativas$, temas$)
      .pipe(finalize(() => Swal.close()))
      .subscribe(
        ([anteproyectos, notas, proyectos, leyes, normativas, temas]) => {
          this.anteproyectos = anteproyectos;
          this.notas = notas;
          this.proyectos = proyectos;
          this.leyes = leyes;
          this.normativas = normativas;
          this.temas = temas;
        }
      );
  }

  get movimientos() {
    return this.form.get('movimientos') as UntypedFormArray;
  }

  changePublic(value) {
    this.form.controls['isPublic'].setValue(value);
  }

  movimientoForm() {
    this.movForm = this.formBuilder.group({
      id: this.movimiento?.id,
      fecha: this.movimiento?.fecha,
      estado: this.movimiento?.estado,
      estadoId: this.movimiento?.estadoId,
      descripcion: this.movimiento?.descripcion,
      link: this.movimiento?.link,
      entidadTipo: 3,
    });
  }

  cleanMov() {
    this.movimiento = new MovimientoModel();
  }

  submitMov() {
    const formValue = this.movForm.value;
    if (formValue.id) {
      this.lukeService
        .putMovimiento(formValue, this.ley.id, 3)
        .subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Hubo un error, revisa los campos e intentalo de nuevo.',
            });
          } else {
            this.form.controls.movimientos = this.formBuilder.array([]);
            this.getMovimientos(3, this.ley.id);
            this.cleanMov();
            Swal.fire({
              icon: 'success',
              title: 'Listo!',
              text: 'El movimiento fue modificado',
            });
          }
        });
    } else if (this.ley) {
      this.lukeService
        .postMovimiento(formValue, this.ley.id, 3)
        .subscribe((res: any) => {
          this.addMovimiento(
            formValue.fecha ?? null,
            formValue.estado.descripcionEstado ?? '',
            formValue.estado.id ?? null,
            formValue.descripcion ?? '',
            formValue.link ?? '',
            res.id ?? 0
          );
        });
    } else {
      this.cleanMov();
      this.addMovimiento(
        formValue.fecha ?? null,
        formValue.estado.descripcionEstado ?? '',
        formValue.estado.id ?? null,
        formValue.descripcion ?? '',
        formValue.link ?? '',
        formValue.id ?? 0,
        formValue.entidadTipo
      );
    }
  }

  editMov(mov) {
    this.movimiento = mov.value;
    this.movimiento.fecha = this.movimiento.fecha.split('T')[0];
    this.movimientoForm();
  }

  delMov(id, index) {
    if (this.ley) {
      Swal.fire({
        title: 'Borrar movimiento',
        text: '¿Está seguro que quiere borrar este movimiento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.lukeService.deleteMovimiento(id).subscribe(
            (res) => {
              Swal.showLoading();
              Swal.fire('Borrado!', 'El movimiento fue borrado', 'success');
              this.removeMovimiento(index);
            },
            (error) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salió mal, intenta de nuevo',
              });
            }
          );
        } else {
          return;
        }
      });
    } else {
      this.removeMovimiento(index);
    }
  }

  removeMovimiento(index) {
    this.movimientos.removeAt(index);
  }

  generateFileRead() {
    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([reader.result], {
        type: this.expedienteFile.type,
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    };
    reader.readAsArrayBuffer(this.expedienteFile);
  }

  addExistingFileView() {
    this.newFileView = false;
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();
    this.s3Service.getUploadedFiles().subscribe((res) => {
      this.existingFiles = res.Contents.map((file) => {
        return {
          name: file.Key,
          url: `https://alertas-v2.directoriolegislativo.org/${file.Key}`,
        };
      });
      Swal.close();
    });
  }

  async submitForm() {
    if (this.form.invalid) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Tenes que llenar todos los campos marcados con un asterisco (*).',
      });
      return;
    }
    if (this.movimientos.length == 0) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No puede subirse sin ningun movimiento. Agrega un movimiento y volve a intentar.',
      });
      return;
    }
    Swal.fire({
      title: 'Espere',
      text: 'Guardando ley',
      icon: 'info',
      allowOutsideClick: false,
    });
    Swal.showLoading();
    const formValue = this.form.value;
    if (this.expedienteFile && this.newFileView) {
      const uploadedFile = await this.s3Service
        .uploadFile(this.expedienteFile)
        .toPromise();
      formValue.expedienteUrl = `https://alertas-v2.directoriolegislativo.org/${
        uploadedFile.key ? uploadedFile.key : uploadedFile.Key
      }`;
    } else if (this.expedienteFile && !this.newFileView) {
      formValue.expedienteUrl = this.expedienteFile['url'];
    }
    let mappedValues = LeyMapper.mapToRequest(formValue);

    this.route.params.subscribe((params) => {
      if (params['id']) {
        mappedValues.id = params['id'];
        this.lukeService.putLey(mappedValues).subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else
            Swal.fire({
              title: 'Ley modificada',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                this.router.navigateByUrl('/dashboard/leyes');
              },
            });
        });
      } else {
        mappedValues['movimientosParlamentarios'] = formValue.movimientos;
        this.lukeService.postLey(mappedValues).subscribe((res: any) => {
          if (res.error) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else {
            Swal.fire({
              title: 'Ley agregada',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                this.router.navigateByUrl('/dashboard/leyes');
              },
            });
          }
        });
      }
    });
  }
}
