<section id="content-types">
    <div class="row">
        <div class="col-xl-4 col-md-4 col-sm-12">
            <div class="card">
                <div class="card-content">
                    <img src="./assets/images/portrait/profile-pic.jpg" class="card-img-top img-fluid" alt="singleminded" style="border-radius: 100%;">
                    <div class="card-body">
                        <h5 class="card-title">Sebastian Minutto</h5>
                        <p class="card-text text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Consectetur libero id faucibus nisl tincidunt eget.
                        </p>
                    </div>
                </div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><b>Nombre:</b> Sebastian</li>
                    <li class="list-group-item"><b>Apellido:</b> Minutto</li>
                    <li class="list-group-item"><b>Email:</b> sminutto@directoriolegislativo.org</li>
                </ul>
                <div class="card-body text-center">
                    <button type="button" class="btn btn-outline-dark" data-toggle="modal" data-target="#inlineForm">
                        Editar
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-4 col-sm-12">
            <div class="card">
                <div class="card-content">
                </div>
                <div class="card-body text-center">
                    <h5 class="card-title">Cambiar Contraseña</h5>
                    <p class="card-text text-center">
                        - Debe contener al menos una mayúscula.<br> - Debe contener al menos un número o caracter especial.<br> - Como mínimo debe tener un largo de 8 carácteres.<br> - No puede tener el mismo carácter consecutivo mas de 4 veces.<br> -
                        No puede ser parecida a la contraseña anterior.<br>
                    </p>
                    <button type="button" class="btn btn-outline-dark" data-toggle="modal" data-target="#inlinePassword">
                        Cambiar
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <div class="card text-center">
                                <div class="card-content">
                                    <div class="card-body py-1">
                                        <div class="badge-circle badge-circle-lg badge-circle-light-success mx-auto mb-50">
                                            <i class="bx bx-briefcase-alt font-medium-5"></i>
                                        </div>
                                        <div class="text-muted line-ellipsis">New Products</div>
                                        <h3 class="mb-0">1.2k</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <div class="card text-center">
                                <div class="card-content">
                                    <div class="card-body py-1">
                                        <div class="badge-circle badge-circle-lg badge-circle-light-success mx-auto mb-50">
                                            <i class="bx bx-briefcase-alt font-medium-5"></i>
                                        </div>
                                        <div class="text-muted line-ellipsis">New Products</div>
                                        <h3 class="mb-0">1.2k</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- MODAL PERFIL -->
    <div class="modal fade text-left" id="inlineForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel33" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel33">Editar</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <i class="bx bx-x"></i>
                    </button>
                </div>
                <form action="#">
                    <div class="modal-body">
                        <label>Nombre</label>
                        <div class="form-group">
                            <input type="text" placeholder="Nombre" class="form-control">
                        </div>
                        <label>Apellido</label>
                        <div class="form-group">
                            <input type="text" placeholder="Apellido" class="form-control">
                        </div>
                        <label>Email</label>
                        <div class="form-group">
                            <input type="email" placeholder="Email" class="form-control">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light-secondary" data-dismiss="modal">
                            <i class="bx bx-x d-block d-sm-none"></i>
                            <span class="d-none d-sm-block">Cerrar</span>
                        </button>
                        <button type="submit" class="btn btn-primary ml-1" data-dismiss="modal">
                            <i class="bx bx-check d-block d-sm-none"></i>
                            <span class="d-none d-sm-block">Aceptar</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- MODAL PASSWORD -->
    <div class="modal fade text-left" id="inlinePassword" tabindex="-1" role="dialog" aria-labelledby="myModalLabel33" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="myModalLabel33">Cambiar Contraseña</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <i class="bx bx-x"></i>
                        </button>
                </div>
                <form action="#">
                    <div class="modal-body">
                        <label>Contraseña nueva</label>
                        <div class="form-group">
                            <input type="password" placeholder="Contraseña Nueva" class="form-control">
                        </div>
                        <label>Reescribir contraseña</label>
                        <div class="form-group">
                            <input type="password" placeholder="Reescribir Contraseña" class="form-control">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light-secondary" data-dismiss="modal">
                                <i class="bx bx-x d-block d-sm-none"></i>
                                <span class="d-none d-sm-block">Cerrar</span>
                            </button>
                        <button type="submit" class="btn btn-primary ml-1" data-dismiss="modal">
                                <i class="bx bx-check d-block d-sm-none"></i>
                                <span class="d-none d-sm-block">Aceptar</span>
                            </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>