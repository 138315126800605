import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { imageMap } from 'src/utils/imageMap';
import { NotaModel } from '../../entidades/models/nota.model';

@Component({
  selector: 'app-alerta-preview',
  templateUrl: './alerta-preview.component.html',
  styleUrls: ['./alerta-preview.component.css'],
  host: {
    '[style.overflow]': '"auto"',
  },
})
export class AlertaPreviewComponent implements OnInit {
  @Input() nota: NotaModel;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faLinkedinIn = faLinkedinIn;
  faInstagram = faInstagram;
  faYoutube = faYoutube;
  faEnvelope = faEnvelope;
  imageMap = imageMap;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    console.log(this.nota);
  }

  sanitizeHtml(html) {
    if (html == this.nota.sintesis || html == this.nota.contenido) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
  }
}
