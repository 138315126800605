import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LukeService } from 'src/app/panel/panelServices/luke.service';
import Swal from 'sweetalert2';
import { ClienteModel } from '../../entidades/models/clientes.model';
import { NewsletterResponse } from '../../models/newsletters-response.interface';

@Component({
  selector: 'app-premade',
  templateUrl: './premade.component.html',
  styleUrls: ['./premade.component.css'],
})
export class PremadeComponent implements OnInit {
  newsletters: NewsletterResponse[];
  clientes: ClienteModel[];
  loading: boolean = false;
  syncRunning: boolean = null;

  constructor(private lukeService: LukeService) {}

  ngOnInit(): void {
    this.loading = true;
    Swal.fire({
      text: 'Cargando...',
    });
    Swal.showLoading();

    this.lukeService
      .getNewsletterSync()
      .pipe(
        tap((res) => {
          this.syncRunning = res.isRunning;
        }),
        switchMap((res) => {
          if (!res.isRunning) {
            return forkJoin([
              this.lukeService.getNewsletters(),
              this.lukeService.getClientes(),
            ]);
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (result) => {
          if (result) {
            const [news, clients]: [NewsletterResponse[], ClienteModel[]] =
              result;
            this.newsletters = news;
            this.clientes = clients;
          }
          this.loading = false;
          Swal.close();
        },
        (error) => {
          console.error(error);
          this.loading = false;
          Swal.close();
        }
      );
  }

  handleSync() {
    this.ngOnInit();
  }
}
