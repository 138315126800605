import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { from, of } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { ClienteModel } from 'src/app/panel/entidades/models/clientes.model';
import { NewsletterResponse } from 'src/app/panel/models/newsletters-response.interface';
import { SendNewsletterResponse } from 'src/app/panel/models/sendNewsletterResponse.interface';
import { SyncNewsletterResponse } from 'src/app/panel/models/syncNewsletterResponse.interface';
import { LukeService } from 'src/app/panel/panelServices/luke.service';
import Swal from 'sweetalert2';
import { EmailPreviewComponent } from '../email-preview/email-preview.component';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.css'],
})
export class EmailSendComponent implements OnInit {
  @Input() isPremade: boolean = false;
  @Input() newsletters: NewsletterResponse[];
  @Input() clientes: ClienteModel[];
  @Input() syncRunning: boolean = null;
  groupedNews: any;
  @Output() backDataEvent = new EventEmitter<any>();
  @Output() syncedEvent = new EventEmitter<any>();
  isPreviewing: boolean;
  isPreviewingId: number;
  approvedIds: number[] = [];
  faChevronDown = faChevronDown;

  constructor(
    private modalService: NgbModal,
    private lukeService: LukeService
  ) {}

  ngOnInit(): void {
    if (!this.syncRunning) {
      this.groupedNews = _.groupBy(
        this.newsletters,
        (news) => news.recipient.clienteId
      );
    }
  }

  getClientName(id): string {
    return (
      this.clientes.find((cli) => cli.id === parseInt(id)).nombreCliente ??
      'No disponible.'
    );
  }

  syncNewsletters(): void {
    Swal.fire({
      title: 'Crear newsletters',
      text: 'Esta función va a crear/actualizar todos los newsletters disponibles, tomará un tiempo y es irreversible ¿Continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Continuar',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Cargando...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        this.lukeService.syncNewsletters().subscribe(
          (res: SyncNewsletterResponse) => {
            Swal.fire({
              title: 'Finalizado',
              text: `Se crearon/actualizaron ${res.newslettersCreated} newsletters.`,
              icon: 'success',
            }).then(() => {
              this.syncedEvent.emit();
            });
          },
          (error) => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo más tarde.',
            });
          }
        );
      } else {
        return;
      }
    });
  }

  selectAll() {
    this.approvedIds = [];
    this.approvedIds = this.newsletters.map((n) => n.id);
  }

  selectAllClient(clientId, event: MouseEvent) {
    event.stopPropagation();
    this.newsletters
      .filter((n) => n.recipient.clienteId == parseInt(clientId))
      .forEach((news: NewsletterResponse) => {
        if (!this.checkIfSelected(news.id)) {
          this.approvedIds.push(news.id);
        }
      });
  }

  removeAllClient(clientId, event: MouseEvent) {
    event.stopPropagation();
    const idsToRemove = this.newsletters
      .filter((n) => n.recipient.clienteId == parseInt(clientId))
      .map((n) => n.id);
    this.approvedIds = this.approvedIds.filter(
      (aI) => !idsToRemove.includes(aI)
    );
  }

  removeOne(id) {
    const newsIndex = this.approvedIds.indexOf(id);
    this.approvedIds.splice(newsIndex, 1);
  }

  removeAll() {
    this.approvedIds = [];
  }

  checkIfSelected(newsId: number) {
    return this.approvedIds.includes(newsId);
  }

  async openPreview(newsId) {
    const entityData = await this.getNewsletter(
      this.newsletters.find((n) => n.id == newsId).id
    ).toPromise();

    this.isPreviewing = true;
    const modalRef = this.modalService.open(EmailPreviewComponent, {
      animation: true,
      keyboard: true,
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: false,
      beforeDismiss: () => {
        this.isPreviewing = false;
        return true;
      },
    });

    modalRef.componentInstance.entityData = entityData;
  }

  private getNewsletter(id: number) {
    Swal.fire({
      text: 'Cargando',
    });
    Swal.showLoading();

    return this.lukeService.getNewsletterById(id).pipe(
      tap(() => Swal.close()),
      catchError((error) => {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salió mal, intenta de nuevo más tarde.',
        });
        return of(null);
      })
    );
  }

  deleteNewsletter(id: number, group, groupIndex) {
    Swal.fire({
      title: 'Borrar newsletter',
      text: '¿Está seguro que quiere borrar este newsletter?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService.deleteNewsletter(id).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'El newsletter fue borrado', 'success');
            const nIndex = this.newsletters.findIndex((n) => n.id == id);
            const aIndex = this.approvedIds.findIndex((aI) => aI == id);
            this.newsletters.splice(nIndex, 1);
            this.approvedIds.splice(aIndex, 1);
            this.groupedNews[group.key].splice(groupIndex, 1);
          },
          (error) => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo más tarde.',
            });
          }
        );
      } else {
        return;
      }
    });
  }

  public sendOneNewsletter(id: number, group, groupIndex): void {
    Swal.fire({
      title: 'Confirmar envío de Newsletter',
      text: `No es posible revertir esta acción.`,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService
          .sendNewsletter(id)
          .subscribe((res: SendNewsletterResponse) => {
            if (res.sent) {
              const nIndex = this.newsletters.findIndex((n) => n.id == id);
              const aIndex = this.approvedIds.findIndex((aI) => aI == id);
              this.newsletters.splice(nIndex, 1);
              this.approvedIds.splice(aIndex, 1);
              this.groupedNews[group.key].splice(groupIndex, 1);
              Swal.fire({
                title: 'Newsletter enviado con éxito',
                icon: 'success',
              });
            } else {
              Swal.fire({
                title: 'Ocurrió un error',
                text: 'Intenta más tarde',
                icon: 'error',
              });
            }
          });
      }
    });
  }

  public sendAllApproved(): void {
    Swal.fire({
      title: 'Confirmar envío de Newsletters',
      text: `No es posible revertir esta acción. Se van a enviar ${this.approvedIds.length} newsletters`,
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const totalNews = this.approvedIds.length;
        let currentIndex = 0;
        Swal.fire({
          title: `Enviando ${currentIndex + 1}/${totalNews}`,
          text: 'Esto puede tomar un tiempo.',
        });
        Swal.showLoading();
        from(this.approvedIds)
          .pipe(concatMap((id) => this.lukeService.sendNewsletter(id)))
          .subscribe(
            (res) => {
              currentIndex++;
            },
            (error) => {
              console.error('Error sending newsletter:', error);
              currentIndex++;
            },
            () => {
              Swal.fire({
                title: 'Envío completado',
                text: `Envío completo`,
                icon: 'success',
              }).then((result) => {
                this.syncedEvent.emit();
              });
            }
          );
      }
    });
  }
}
