export class AnteProyectosMapper {
  public static mapToRequest(formData) {
    let anteProyectoRequest = {
      id: formData?.id,
      paisId: formData.pais,
      titulo: formData.titulo,
      expedienteNumero: formData.expedienteNumero,
      expedienteUrl: formData.expedienteUrl,
      nivelGobiernoId: formData.nivelGobierno,
      ProbabilidadSancionId: formData.probabilidadAvance,
      AnteProyectoTipoId: formData.anteproyectoTipo,
      impactoId: formData.impacto,
      camaraId: formData?.camara ?? null,
      AnteProyectoOrigenId: formData.origen,
      temas: formData.temas.map((tema) => {
        return {id:tema, descripcionTema:"a"}
      }),
      clientes: formData?.clientes.map((cliente) => {
        return { id: cliente, nombreCliente: 'a' };
      }) ?? [],
      autores: formData.autores.map((autor) => {
        return {id: autor, nombreApellido:"a"};
      }) ?? [],
      sintesis: formData.sintesis,
      categorias: formData.categorias.map((cat) => {
        return {id:cat, descripcionCat: "a"}
      }),
      fechaIngreso: formData.fechaIngreso,
      notasRelacionadas: formData.notasAsociadas,
      apRelacionados: formData.anteproyectosAsociados,
      isPublic: formData.isPublic
    }

    return anteProyectoRequest;
  }
}
