import { CamaraModel } from '../../entidades/models/camaras.model';
import { AutoresModel } from '../../entidades/models/autores.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ScraperService } from '../../panelServices/scraper.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { FilterPipe } from 'src/app/core/pipes/filter.pipe';
import { NormativaModel } from '../../entidades/models/normativa.model';
import { FilterAutoresPipe } from 'src/app/core/pipes/search-autor.pipe';
import { Proyectos } from '../../models/proyectos.model';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-scraping-tables',
  templateUrl: './scraping-tables.component.html',
  styleUrls: ['./scraping-tables.component.css'],
})
export class ScrapingTablesComponent implements OnInit {
  normativas: NormativaModel[];
  searchTerm: string;
  proyectos: Proyectos[];
  autorFilter: number;
  parlamentos: CamaraModel[];
  unfilteredAutores: AutoresModel[];
  camaraFilter: number;
  autores: AutoresModel[];
  id: any = this.route.snapshot.paramMap.get('id');
  startDate: Date;
  endDate: Date;
  startDateSearch: Date;
  endDateSearch: Date;
  searchForm: UntypedFormGroup;
  pagination: { next: number; previous: number[]; current: number } = {
    next: 0,
    previous: [],
    current: 0,
  };
  @Output() dateRangeChanged = new EventEmitter<[Date, Date]>();

  constructor(
    private scraperService: ScraperService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.getCamaras();

    this.getNormativas();

    this.getUnfilteredAutores();

    this.searchForm = this.formBuilder.group({
      paisId: this.id,
      titulo: [null],
      numero: [null],
      contenido: [null],
      camaraId: [null],
      fechaDesde: [null],
      fechaHasta: [null],
      autoresId: [null],
      keyword: [null],
      sortBy: 'updatedAt',
      sortOrder: 'desc'
    });
  }

  parseDate(fecha) {
    const defaultDate = '0001-01-01T00:00:00';
    if (fecha === defaultDate || !fecha ) {
      return 'No disponible';
    }
    return formatDate(fecha, 'dd-MM-yyyy', 'en-US');
  }

  sortProyectosByDate(array) {
    return array.sort(function (a, b) {
      return (
        new Date(b.fechaIngreso).valueOf() - new Date(a.fechaIngreso).valueOf()
      );
    });
  }

  updateDateRange(): void {
    if (this.startDate && this.endDate) {
      this.dateRangeChanged.emit([this.startDate, this.endDate]);
    }
  }

  sortNormativasByDate(array) {
    return array.sort(function (a, b) {
      return (
        new Date(b.fechaPublicacion).valueOf() -
        new Date(a.fechaPublicacion).valueOf()
      );
    });
  }

  private getProyectos() {
    this.scraperService
      .getProyectosByQueryParams({
        paisId: this.id,
        sortBy: 'updatedAt',
        sortOrder: 'desc',
      })
      .subscribe({
        next: (res: any) => {
          this.proyectos = res.proyectos;
          this.pagination.next = res.last;
          this.pagination.previous.push(null);
        },
        error: (error) => {
          console.log(error);
        },
        complete: () => {
          this.getAutores();
        },
      });
  }

  private getNormativas() {
    this.scraperService.getNormativas(this.id).subscribe((res: any) => {
      this.normativas = this.sortNormativasByDate(res);
    });
  }

  private getCamaras() {
    this.parlamentos = [];
    this.scraperService.getCamaras(this.id).subscribe({
      next: (res: any) =>
        res.forEach((r) => {
          this.parlamentos.push(r['camara']);
        }),
      complete: () => {
        this.getProyectos();
      },
    });
  }

  private getAutores() {
    this.scraperService.getAutores(this.id).subscribe({
      next: (res: any) =>
        (this.autores = res.filter((autor) =>
          this.proyectos.some((item) =>
            item.proyectosAutores.some(
              (proyectoAutor) => proyectoAutor.autorId === autor.id
            )
          )
        )),
      complete: () => {
        Swal.close();
        return 'Ok';
      },
    });
  }

  private getUnfilteredAutores() {
    this.scraperService.getAutores(this.id).subscribe((res: AutoresModel[]) => {
      this.unfilteredAutores = res;
    });
  }

  public getNextOrPreviousPage(button) {
    this.showLoading();
    let params = this.searchForm.value;
    if (button == 'next') {
      params.last = this.pagination.next;
    } else {
      params.last = this.pagination.previous.pop();
    }

    this.scraperService.getProyectosByQueryParams(params).subscribe({
      next: (res: any) => {
        this.proyectos = res.proyectos;
        if (button == 'next') {
          this.pagination.previous.push(this.pagination.current);
          this.pagination.current = params.last;
        }
        this.pagination.next = res.last;
      },
      complete: () => {
        Swal.close();
      },
    });
  }

  public getNombreCamaraById(camaraId) {
    const parlamento = this.parlamentos.find((p) => {
      return p.id == camaraId;
    });
    return parlamento.nombreCamara ?? '';
  }

  public resetFilters() {
    this.showLoading();
    this.searchForm.reset();
    this.getProyectos();
  }

  private showLoading() {
    Swal.fire({
      title: 'Cargando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  public onSubmitSearch() {
    this.showLoading();
    const searchParams = this.searchForm.value;
    if (searchParams.fechaDesde) {
      searchParams.fechaDesde = this.datePipe.transform(
        this.searchForm.get('fechaDesde').value,
        'yyyy-MM-dd'
      );
    }
    if (searchParams.fechaHasta) {
      searchParams.fechaHasta = this.datePipe.transform(
        this.searchForm.get('fechaHasta').value,
        'yyyy-MM-dd'
      );
    }
    this.scraperService.getProyectosByQueryParams(searchParams).subscribe({
      next: (res: any) => {
        this.proyectos = res.proyectos;
        this.pagination = { next: 0, previous: [], current: 0 };
      },
      error: () => {
        Swal.close();
      },
      complete: () => {
        Swal.close();
      },
    });
  }
}
