import { Pipe, PipeTransform } from '@angular/core';
import { PaisModel } from '../../panel/entidades/models/pais.model';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(Pais: PaisModel[], searchValue: string): PaisModel[] {
    if (!Pais || !searchValue) {
      return Pais;
    }
    return Pais.filter(pais => pais.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
  }

}
