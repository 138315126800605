<div class="col-12">
  <h5 class="my-2" *ngIf="!this.isStaff">Usuarios</h5>
  <h5 class="my-2" *ngIf="this.isStaff">ABM Staff DL</h5>
  <button
    type="button"
    class="btn btn-primary"
    data-toggle="modal"
    data-target="#usuarioModal"
    (click)="addUsuario()"
  >
    Agregar usuario
  </button>
</div>
<div class="table-responsive w-100">
  <table class="table">
    <thead>
      <tr>
        <th>Nombre</th>
        <th>Apellido</th>
        <th>Email</th>
        <th>Activo</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let usuario of usuarios; let i = index">
        <td style="width: 33%">{{ usuario.nombre }}</td>
        <td>{{ usuario.apellido }}</td>
        <td>{{ usuario.email }}</td>
        <td>{{ usuario.isActive ? "Si" : "No" }}</td>
        <td>
          <a
            data-target="#usuarioModal"
            data-toggle="modal"
            (click)="editUsuario(usuario)"
            ><i
              class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
            ></i
          ></a>
          <a (click)="onDelete(usuario.id)"
            ><i
              class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
            ></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  class="modal fade text-left"
  id="usuarioModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel1">Agregar Usuario</h3>
        <button
          type="button"
          class="close rounded-pill"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cleanUsuario()"
        >
          <i class="bx bx-x"></i>
        </button>
      </div>
      <form
        *ngIf="usuarioForm"
        [formGroup]="usuarioForm"
        (ngSubmit)="submitUsuario()"
      >
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <label>Nombre</label><span class="red">*</span>
              <div class="form-group">
                <input
                  type="text"
                  placeholder=""
                  class="form-control"
                  formControlName="nombre"
                />
              </div>
            </div>
            <div class="col-md-6">
              <label>Apellido</label><span class="red">*</span>
              <input
                type="text"
                placeholder=""
                class="form-control"
                formControlName="apellido"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Email</label><span class="red">*</span>
              <input
                type="text"
                placeholder=""
                class="form-control"
                formControlName="email"
              />
            </div>
            <div class="col-md-6">
              <label>Activo</label>
              <fieldset name="estado">
                <button
                  *ngIf="this.isActive.value"
                  (click)="changeActive(false)"
                  class="btn btn-success"
                  type="button"
                >
                  Activo
                </button>
                <button
                  *ngIf="!this.isActive.value"
                  (click)="changeActive(true)"
                  class="btn btn-danger"
                  type="button"
                >
                  Inactivo
                </button>
              </fieldset>
            </div>
            <div class="col-6 pt-2">
              <label for="password">Contraseña</label><span class="red">*</span>
              <input type="password" class="form-control" formControlName="password" />
              <div *ngIf="usuarioForm.get('password').hasError('required') && usuarioForm.get('password').dirty">
                <span>Debe ingresar una contraseña</span>
              </div>
            </div>
            <div class="col-6 pt-2">
              <label for="confirmPassword">Repetir contraseña</label><span class="red">*</span>
              <input type="password" class="form-control" formControlName="confirmPassword" />
              <div *ngIf="usuarioForm.hasError('passwordMismatch') && usuarioForm.get('password').dirty">
                <span>Las contraseñas no coinciden.</span>
              </div>
            </div>
          </div>
          <div *ngIf="!isStaff" class="container" style="padding-top: 3%;">
            <div class="alert alert-light alert-dismissible fade show" role="alert">
              Seleccioná las entidades que tendrá habilitadas este usuario.
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>            
            </div>            
            <div class="row">
              <div class="col-xl-12">
                <div class="accordion" id="accordionUser">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card" *ngIf="isServicePresent('Alertas')">
                        <div class="card-header" id="headingOne">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left"
                              type="button"
                              data-toggle="collapse"
                              data-target="#alertasUser"
                              aria-expanded="true"
                              aria-controls="alertasUser"
                            >
                              Alertas
                            </button>
                          </h2>
                        </div>
                        <div
                          id="alertasUser"
                          class="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionUser"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(pais, 'alertas')
                                  }"
                                  *ngFor="let pais of getPaisesService('Alertas', false)"
                                  aria-pressed="true"
                                  (click)="addRemoveAlerta(pais, 'alertas')"
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card" *ngIf="isServicePresent('Newsletter')">
                        <div class="card-header" id="headingTwo">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#newsletterUser"
                              aria-expanded="false"
                              aria-controls="newsletterUser"
                            >
                              Newsletter
                            </button>
                          </h2>
                        </div>
                        <div
                          id="newsletterUser"
                          class="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionUser"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(pais, 'newsletter')
                                  }"
                                  *ngFor="let pais of getPaisesService('Newsletter', false)"
                                  aria-pressed="true"
                                  (click)="
                                    addRemoveAlerta(pais, 'newsletter')
                                  "
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card" *ngIf="isServicePresent('Scraper')">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#scraperUser"
                              aria-expanded="false"
                              aria-controls="scraperUser"
                            >
                              Scraper
                            </button>
                          </h2>
                        </div>
                        <div
                          id="scraperUser"
                          class="collapse"
                          aria-labelledby="scraperUser"
                          data-parent="#accordionUser"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(pais, 'scraper')
                                  }"
                                  *ngFor="let pais of getPaisesService('Scraper', false)"
                                  aria-pressed="true"
                                  (click)="addRemoveAlerta(pais, 'scraper')"
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card" *ngIf="isServicePresent('Dashboard')">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#dashboardUser"
                              aria-expanded="false"
                              aria-controls="dashboardUser"
                            >
                              Dashboard
                            </button>
                          </h2>
                        </div>
                        <div
                          id="dashboardUser"
                          class="collapse"
                          aria-labelledby="dashboardUser"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(pais, 'dashboard')
                                  }"
                                  *ngFor="let pais of getPaisesService('Dashboard', false)"
                                  aria-pressed="true"
                                  (click)="addRemoveAlerta(pais, 'dashboard')"
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card" *ngIf="this.serviciosDashboard[0]">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#dashboardNotasUser"
                              aria-expanded="false"
                              aria-controls="dashboardNotasUser"
                            >
                              Dashboard - Notas
                            </button>
                          </h2>
                        </div>
                        <div
                          id="dashboardNotasUser"
                          class="collapse"
                          aria-labelledby="dashboardNotasUser"
                          data-parent="#accordionUser"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(pais, 'dashboardNotas')
                                  }"
                                  *ngFor="let pais of getPaisesService('Notas', true)"
                                  aria-pressed="true"
                                  (click)="
                                    addRemoveAlerta(pais, 'dashboardNotas')
                                  "
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="card" *ngIf="this.serviciosDashboard[1]">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#dashboardNormativasUser"
                              aria-expanded="false"
                              aria-controls="dashboardNormativasUser"
                            >
                              Dashboard - Normativas
                            </button>
                          </h2>
                        </div>
                        <div
                          id="dashboardNormativasUser"
                          class="collapse"
                          aria-labelledby="dashboardNormativasUser"
                          data-parent="#accordionUser"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(
                                      pais,
                                      'dashboardNormativas'
                                    )
                                  }"
                                  *ngFor="let pais of getPaisesService('Normativas', true)"
                                  aria-pressed="true"
                                  (click)="
                                    addRemoveAlerta(
                                      pais,
                                      'dashboardNormativas'
                                    )
                                  "
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="card" *ngIf="this.serviciosDashboard[2]">
                        <div class="card-header" id="headingThree">
                          <h2 class="mb-0">
                            <button
                              class="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#dashboardProyectosUser"
                              aria-expanded="false"
                              aria-controls="dashboardProyectosUser"
                            >
                              Dashboard - Proyectos
                            </button>
                          </h2>
                        </div>
                        <div
                          id="dashboardProyectosUser"
                          class="collapse"
                          aria-labelledby="dashboardProyectosUser"
                          data-parent="#accordionUser"
                        >
                          <div class="card-body">
                            <div class="btn-toolbar" role="toolbar">
                              <div class="btn-group flex-wrap" role="group">
                                <button
                                  type="button"
                                  class="btn btn-outline-success"
                                  [ngClass]="{
                                    active: objExists(
                                      pais,
                                      'dashboardProyectos'
                                    )
                                  }"
                                  *ngFor="let pais of getPaisesService('Proyectos', true)"
                                  aria-pressed="true"
                                  (click)="
                                    addRemoveAlerta(
                                      pais,
                                      'dashboardProyectos'
                                    )
                                  "
                                >
                                  {{ pais.name ?? pais.nombre }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-light-secondary"
          data-dismiss="modal"
          (click)="cleanUsuario()"
        >
          <i class="bx bx-x d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Cerrar</span>
        </button>
        <button
          type="button"
          class="btn btn-primary ml-1"
          data-dismiss="modal"
          [disabled]="usuarioForm?.invalid"
          (click)="submitUsuario()"
        >
          <i class="bx bx-check d-block d-sm-none"></i>
          <span class="d-none d-sm-block">Guardar</span>
        </button>
      </div>
    </div>
  </div>
</div>
