import { Component, OnInit} from '@angular/core';
import { LukeService } from '../panelServices/luke.service';
import Swal from 'sweetalert2';
import { PaisModel } from '../entidades/models/pais.model';
import { CamaraModel } from '../entidades/models/camaras.model';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ComisionesModel } from '../entidades/models/comisiones.model';


@Component({
  selector: 'app-comisiones',
  templateUrl: './comisiones.component.html',
  styleUrls: ['./comisiones.component.css'],
})
export class ComisionesComponent implements OnInit {
  paises: PaisModel[];
  selectedPais: PaisModel;
  camaras: CamaraModel[];
  comisiones: ComisionesModel[];
  comisionForm: UntypedFormGroup;
  comision: ComisionesModel;

  constructor(private lukeService: LukeService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.lukeService.getPaises().subscribe((res: PaisModel[]) => {
      this.paises = res;
    });
  }

  changeDependencies(value) {
    this.lukeService.getCamaras(value).subscribe((res:CamaraModel[]) => {
      this.camaras = res;
    });
    this.lukeService.getComisionesByPaisId(value).subscribe((res:ComisionesModel[]) => {
      this.comisiones = res.map((com) => {
        return this.defineCamara(com)
      }).filter((comi) => {
        return comi.pais.id == this.selectedPais.id;
      });
    });
  }

  definePais(camaraPais) {
    return this.paises.find((pais) => {
      return pais.camarasPaises.find((camPais) => {
        return camPais.id == camaraPais
      })
    })
  }

  defineCamara(comision) {
    let com = comision
    com.pais = this.definePais(comision.camaraPaisId)
    com.camara = com.pais.camarasPaises.find((camPais) => {
      return camPais.id == com.camaraPaisId
    })
    com.camara = com.camara.camara

    return com
  }

  comisionesForm() {
    this.comisionForm = this.formBuilder.group({
      id: this.comision?.id,
      nombre: this.comision?.nombre,
      camara: this.comision?.camara?.nombreCamara,
      pais: [{value: this.selectedPais.id ?? 'none', disabled: true}]
    })
  }

  editComision(comision) {
    this.comision = comision;
    this.comisionesForm();
  }

  cleanComision() {
    this.comision = new ComisionesModel;
  }

  submitComision() {
    const formValue = this.comisionForm.value;
    formValue.pais = this.selectedPais.name;
    if (formValue.id) {
      this.lukeService.putComision(formValue).subscribe((res:any) => {
        Swal.fire({
          title: 'Comision modificada',
          text: 'Se actualizó correctamente',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
        }).then((res:any)=>{
          this.changeDependencies(this.selectedPais.id);
          this.cleanComision()
        });
      })
    } else {
      delete formValue.id;
      this.lukeService.postComision(formValue).subscribe((res:any) => {
        Swal.fire({
          title: 'Comision agregada',
          text: 'Se actualizó correctamente',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonText: 'Cerrar',
        }).then((res:any)=>{
          this.changeDependencies(this.selectedPais.id);
          this.cleanComision()
        });
      })      
    }
  }
  

  onDelete(id: string) {
    Swal.fire({
      title: 'Borrar comision',
      text: '¿Está seguro que quiere borrar esta comision?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Borrar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.lukeService.deleteComision(id).subscribe(
          (res) => {
            Swal.showLoading();
            Swal.fire('Borrado!', 'La comision fue borrada', 'success');
            this.changeDependencies(this.selectedPais.id);
            this.cleanComision();
          },
          (error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo salió mal, intenta de nuevo',
            });
          }
        );
      } else {
        return;
      }
    });
  }
}