import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camaraPipe'
})
export class FilterCamaraPipe implements PipeTransform {
  transform(value: any[], camaraId?: number): any[] {
    if (!value || !Array.isArray(value)) {
      return [];
    }

    if (!camaraId) {
      return value;
    }

    return value.filter(item => item.camaraId === camaraId);
  }
}