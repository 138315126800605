import { Pipe, PipeTransform } from '@angular/core';
import { ProyectoModel } from '../../panel/entidades/models/proyecto.model';

@Pipe({
  name: 'searchProyectos'
})
export class SearchProyectosPipe implements PipeTransform {

  transform(Proyecto: ProyectoModel[], searchProyecto: string): ProyectoModel[] {
    if (!Proyecto || !searchProyecto) {
      return Proyecto;
    }
    return Proyecto.filter(proyecto => proyecto.titulo.toLocaleLowerCase().includes(searchProyecto.toLocaleLowerCase()));
  }

}
