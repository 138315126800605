import { Component, OnInit } from '@angular/core';
import { LukeService } from '../panelServices/luke.service';
import { BloqueModel } from '../entidades/models/bloque.model';
import Swal from 'sweetalert2';
import { forkJoin } from 'rxjs';
import { PaisModel } from '../entidades/models/pais.model';

@Component({
  selector: 'app-bloques',
  templateUrl: './bloques.component.html',
  styleUrls: ['./bloques.component.css'],
})
export class BloquesComponent implements OnInit {
    bloques: BloqueModel[];
    paises: PaisModel[];
    editingIndex = -1;
    oldDesc: string;
    oldPais: number;
    searchTerm: string;

    constructor(private lukeService: LukeService) {}

    ngOnInit(): void {
        Swal.fire({
          text: 'Cargando...'
        });
        Swal.showLoading();
        forkJoin([
          this.lukeService.getBloques(),
          this.lukeService.getPaises()
        ]).subscribe(([bloques, paises]: [BloqueModel[], PaisModel[]]) => {
          this.bloques = bloques;
          this.paises = paises;
        });
        Swal.close();
    }

    getPaisString(id) {
      let paisStr = this.paises.find(p => p.id === id)
      
      return paisStr ? paisStr.name : "No disponible";
    }

    refreshBloques() {
      this.lukeService.getBloques().subscribe((res: BloqueModel[]) => {
        this.bloques = res;
      })
    }

    onOpenEdit(index) {
      this.editingIndex = index;
    }

    onCancel(bloque) {
      this.editingIndex = -1;
      bloque.descripcionBloque = this.oldDesc;
      bloque.paisId = this.oldPais;
      this.oldDesc = '';
      this.oldPais = null;
    }

    onSubmit(newBloque) {
      Swal.fire({
        text: 'Agregando',
      });
      Swal.showLoading();
      const payload = new BloqueModel();
      payload.descripcionBloque = newBloque;
      payload.id = 0;
      this.lukeService.postBloque(payload).subscribe((res) => {
        this.searchTerm = '';
        this.refreshBloques();
        Swal.fire('Agregado', 'El bloque fue agregado', 'success');
      });
    }

    onSave(bloque) {
      Swal.fire({
        text: 'Agregando',
      });
      Swal.showLoading();
      const payload = bloque;
      payload.descripcionBloque = bloque.descripcionBloque;
      payload.paisId = bloque.paisId;
      this.lukeService.putBloque(bloque).subscribe((res) => {
        this.editingIndex = -1;
        this.refreshBloques();
        Swal.fire('Guardado', 'El bloque fue modificado.', 'success');
      });
    }

    onDelete(id: string) {
      Swal.fire({
        title: 'Borrar bloque',
        text: '¿Está seguro que quiere borrar este bloque?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar',
      }).then((result) => {
        if (result.isConfirmed) {
          this.lukeService.deleteBloque(id).subscribe(
            (res) => {
              Swal.showLoading();
              Swal.fire('Borrado!', 'El bloque fue borrado', 'success');
              this.refreshBloques();
            },
            (error) => {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Algo salió mal, intenta de nuevo',
              });
            }
          );
        } else {
          return;
        }
      });
    }
}

