import { Pipe, PipeTransform } from '@angular/core';
import { NormativaModel } from '../../panel/entidades/models/normativa.model';

@Pipe({
  name: 'searchNormativas'
})
export class SearchNormativasPipe implements PipeTransform {

  transform(Normativa: NormativaModel[], searchNormativa: string): NormativaModel[] {
    if (!Normativa || !searchNormativa) {
      return Normativa;
    }
    return Normativa.filter(normativa => normativa.titulo.toLocaleLowerCase().includes(searchNormativa.toLocaleLowerCase()));
  }

}
