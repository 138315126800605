import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { NotaShowComponent } from '../../entidades/notas/show/notas.component';
import { AlertaV2Response } from '../../models/alertav2.interface';
import { LukeService } from '../../panelServices/luke.service';
import { AlertaPreviewComponent } from '../alerta-preview/alerta-preview.component';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.css'],
})
export class EntitySelectComponent implements OnInit {
  isLoading: boolean = false;
  alertas: AlertaV2Response[] = [];
  selectedAlertas: { entityType: number; entityId: number }[] = [];
  viewingSent: boolean = false;

  constructor(
    private modalService: NgbModal,
    private lukeService: LukeService,
    private router: Router
  ) {
    this.selectedAlertas = this.router.getCurrentNavigation().extras.state
      ? (this.router.getCurrentNavigation().extras.state as {
          entityType: number;
          entityId: number;
        }[])
      : [];
  }

  ngOnInit(): void {
    Swal.fire({
      title: 'Cargando',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        this.isLoading = true;
        Swal.showLoading();
      },
    });

    this.lukeService
      .formAlertas()
      .pipe(concatMap(() => this.lukeService.getAlertas()))
      .subscribe((res) => {
        this.alertas = res.reduce(
          (acc, alerta) => {
            const key = `${alerta.entityType}-${alerta.entityId}`;
            if (!acc.map.has(key)) {
              acc.map.set(key, alerta);
              acc.array.push(alerta);
            }
            return acc;
          },
          {
            map: new Map<string, AlertaV2Response>(),
            array: [] as AlertaV2Response[],
          }
        ).array;

        Swal.close();
        console.log(this.alertas);
        this.isLoading = false;
      });
  }

  openEdit(entityId) {
    const modalRef = this.modalService.open(NotaShowComponent, {
      animation: true,
      keyboard: true,
      size: 'xl',
      centered: true,
      backdrop: false,
    });

    modalRef.componentInstance.modalInput = entityId;

    modalRef.closed.subscribe((data) => {
      if (data === 'edited') {
        this.isLoading = true;
        Swal.fire({ text: 'Actualizando' });
        Swal.showLoading();

        this.alertas = null;
        this.lukeService.getAlertas().subscribe((res) => {
          this.alertas = res.reduce(
            (acc, alerta) => {
              const key = `${alerta.entityType}-${alerta.entityId}`;
              if (!acc.map.has(key)) {
                acc.map.set(key, alerta);
                acc.array.push(alerta);
              }
              return acc;
            },
            {
              map: new Map<string, AlertaV2Response>(),
              array: [] as AlertaV2Response[],
            }
          ).array;
          this.isLoading = false;
          Swal.close();
        });
      }
    });
  }

  selectAll() {
    this.alertas.forEach((alerta: AlertaV2Response) => {
      if (!this.checkIfSelected(alerta.entityId, alerta.entityType)) {
        this.selectedAlertas.push({
          entityType: alerta.entityType,
          entityId: alerta.entityId,
        });
      }
    });
  }

  selectAlerta(entityId: number, entityType: number) {
    if (!this.checkIfSelected(entityId, entityType)) {
      this.selectedAlertas.push({ entityType, entityId });
    }
  }

  removeAlerta(entityId: number, entityType: number) {
    const alertaIndex = this.selectedAlertas.indexOf({ entityType, entityId });
    this.selectedAlertas.splice(alertaIndex, 1);
  }

  removeAll() {
    this.selectedAlertas = [];
  }

  openPreview(nota) {
    const modalRef = this.modalService.open(AlertaPreviewComponent, {
      animation: true,
      keyboard: true,
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: false,
    });

    modalRef.componentInstance.nota = nota;
  }

  toggleView(): void {
    this.viewingSent = !this.viewingSent;

    Swal.fire({ text: 'Cargando' });
    Swal.showLoading();

    this.lukeService.getAlertas({ isSent: this.viewingSent }).subscribe(
      (res) => {
        this.alertas = res.sort((a: AlertaV2Response, b: AlertaV2Response) => {
          const dateA = new Date(a.sentAt).getTime();
          const dateB = new Date(b.sentAt).getTime();

          return dateB - dateA;
        });
        if (!this.viewingSent) {
          this.alertas = this.alertas.reduce(
            (acc, alerta) => {
              const key = `${alerta.entityType}-${alerta.entityId}`;
              if (!acc.map.has(key)) {
                acc.map.set(key, alerta);
                acc.array.push(alerta);
              }
              return acc;
            },
            {
              map: new Map<string, AlertaV2Response>(),
              array: [] as AlertaV2Response[],
            }
          ).array;
        }
        Swal.close();
      },
      (error) => {
        console.error(error);
        Swal.fire({ text: 'Error cargando alertas', icon: 'error' });
        Swal.close();
      }
    );
  }

  checkIfSelected(entityId: number, entityType: number) {
    return this.selectedAlertas.some((sA) => {
      return sA.entityId === entityId && sA.entityType === entityType;
    });
  }

  nextStep() {
    this.router.navigate(['dashboard/alertas/seleccion-usuarios'], {
      state: this.selectedAlertas,
    });
  }
}
