import { Component, OnInit } from '@angular/core';
import { ScrapingModel } from '../models/scraping.model';
import { ScraperService } from '../panelServices/scraper.service'

@Component({
  selector: 'app-scraping',
  templateUrl: './scraping.component.html',
  styleUrls: ['./scraping.component.css']
})
export class ScrapingComponent implements OnInit {

  searchValuev2 = '';
  paises = [];
  constructor(private scraperService: ScraperService) {}

  ngOnInit() {
    this.scraperService.getPaises().subscribe((res: any[]) => {
      this.paises = res;
    })
  }

  countries1: ScrapingModel[] = [
    {id: 23, name:'argentina', url:'../../../assets/images/flag/Argentina.webp'},
    {id: 9, name:'bolivia', url:'../../../assets/images/flag/Bolivia.webp'},
    {id: 8, name:'brasil', url:'../../../assets/images/flag/Brazil.webp'},
    {id: 10, name:'chile', url:'../../../assets/images/flag/Chile.webp'},
    {id: 11, name:'colombia', url:'../../../assets/images/flag/Colombia.webp'},
  ];

  countries2: ScrapingModel[] = [
    {id: 12, name:'costa rica', url:'../../../assets/images/flag/CostaRica.webp'},
    {id: 13, name:'ecuador', url:'../../../assets/images/flag/Ecuador.webp'},
    {id: 15, name:'el salvador', url:'../../../assets/images/flag/ElSalvador.webp'},
    {id: 22, name:'estados unidos', url:'../../../assets/images/flag/EstadosUnidos.webp'},
    {id: 16, name:'guatemala', url:'../../../assets/images/flag/Guatemala.webp'},
    {id: 17, name:'guyana', url:'../../../assets/images/flag/Guyana.webp'},
  ];

  countries3: ScrapingModel[] = [
    {id: 18, name:'honduras', url:'../../../assets/images/flag/Honduras.webp'},
    {id: 19, name:'mexico', url:'../../../assets/images/flag/Mexico.webp'},
    {id: 20, name:'nicaragua', url:'../../../assets/images/flag/Nicaragua.webp'},
    {id: 21, name:'panama', url:'../../../assets/images/flag/Panama.webp'},
    {id: 7, name:'paraguay', url:'../../../assets/images/flag/Paraguay.webp'},
    {id: 14, name:'peru', url:'../../../assets/images/flag/Peru.webp'},
  ];

  countries4: ScrapingModel[] = [
    {id: 6, name:'puerto rico', url:'../../../assets/images/flag/PuertoRico.webp'},
    {id: 1, name:'republica dominicana', url:'../../../assets/images/flag/RepublicaDominicana.webp'},
    {id: 4, name:'suriname', url:'../../../assets/images/flag/Suriname.webp'},
    {id: 3, name:'uruguay', url:'../../../assets/images/flag/Uruguay.webp'},
    {id: 2, name:'venezuela', url:'../../../assets/images/flag/Venezuela.webp'},
    {id: 5, name:'sin pais', url:'../../../assets/images/flag/SinPais.webp'},
  ]
}
