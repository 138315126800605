import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ProfileModel } from 'src/app/panel/models/profile.model';
import { LukeService } from 'src/app/panel/panelServices/luke.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit {
  profile: ProfileModel;

  constructor(
    private lukeService: LukeService,
    private router: Router,
    private cookieService: CookieService,
  ) { }

  ngOnInit() {
    this.lukeService.getProfile().subscribe((res) => {
      this.profile = res as ProfileModel;
      localStorage.setItem('user-data', JSON.stringify({ firstName:this.profile.firstName, lastName:this.profile.lastName,email:this.profile.email }));
    });
  }

  onCloseSession() {
    localStorage.removeItem('user');
    localStorage.removeItem('user-data');
    this.cookieService.delete('token');
    this.cookieService.delete('refreshToken');

    this.router.navigate(['/login']);

  }

}
