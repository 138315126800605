<ng-container>
  <h2>Envío de Newsletter</h2>
  <hr />
  <!-- <div style="margin-bottom: 20px">
    <button
      class="btn btn-primary"
      (click)="currentPanelIndex = 0"
      *ngIf="currentPanelIndex === -1"
    >
      Ir a envío manual
    </button>
    <button
      class="btn btn-primary"
      (click)="currentPanelIndex = -1"
      *ngIf="currentPanelIndex != -1"
    >
      Ir a envío automático
    </button>
  </div> -->

  <div *ngIf="currentPanelIndex === -1">
    <app-premade></app-premade>
  </div>

  <app-client-select
    *ngIf="currentPanelIndex === 0"
    (clientDataEvent)="handleClientData($event)"
    [capturedBackData]="capturedBackData"
    [ngClass]="{
      'fade-in': currentPanelIndex === 0,
      'fade-out': currentPanelIndex !== 0
    }"
  ></app-client-select>

  <app-entity-select
    *ngIf="currentPanelIndex === 1"
    (entityDataEvent)="handleEntityData($event)"
    (backDataEvent)="handleBackData($event)"
    [clientData]="capturedClientData"
    [ngClass]="{
      'fade-in': currentPanelIndex === 1,
      'fade-out': currentPanelIndex !== 1
    }"
  ></app-entity-select>

  <app-email-send
    *ngIf="currentPanelIndex === 2"
    (backDataEvent)="handleBackData($event)"
    [ngClass]="{
      'fade-in': currentPanelIndex === 2,
      'fade-out': currentPanelIndex !== 2
    }"
  ></app-email-send>
</ng-container>
