import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { ClienteComponent } from './pages/cliente/cliente.component';

const routes: Routes = [
    { path: '', component: ClientesComponent },
    { path: 'nuevo', component: ClienteComponent },
    { path: ':id', component: ClienteComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientesRoutingModule {}
