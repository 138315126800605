<div
  class="main-menu menu-fixed menu-dark menu-accordion menu-shadow"
  data-scroll-to-active="true"
>
  <div class="navbar-header">
    <ul class="nav navbar-nav flex-row">
      <li class="nav-item mr-auto">
        <a class="navbar-brand" routerLink="/">
          <div class="brand-logo">
            <img width="35" src="./assets/images/logo/logo_orange.png" />
          </div>
        </a>
      </li>
      <li class="nav-item nav-toggle">
        <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
          <i
            class="fas fa-angle-left d-block d-xl-none font-medium-4 primary"
          ></i>
          <i
            class="toggle-icon bx bx-disc font-medium-4 d-none d-xl-block primary"
            data-ticon="bx-disc"
          ></i>
        </a>
      </li>
    </ul>
  </div>
  <div class="main-menu-content">
    <ul
      class="navigation navigation-main"
      id="main-menu-navigation"
      data-menu="menu-navigation"
      data-icon-style="lines"
    >
      <li class="nav-item">
        <a routerLink="entidades"
          ><i class="menu-livicon" data-icon="grid"></i
          ><span class="menu-title" data-i18n="Email">Entidades</span></a
        >
      </li>
      <li class="nav-item">
        <a routerLink="scraping">
          <i class="menu-livicon" data-icon="diagram"></i>
          <span class="menu-title" data-i18n="User">Scraping</span>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="config">
          <i class="menu-livicon livicon-evo-holder" data-icon="gears"></i>
          <span class="menu-title" data-i18n="User">Config DL</span>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="notas"
          ><i class="menu-livicon" data-icon="responsive"></i
          ><span class="menu-title" data-i18n="Chat">Notas</span></a
        >
      </li>
      <!-- <li class="nav-item has-sub hover">
                <a href="">
                    <i class="menu-livicon livicon-evo-holder" data-icon="notebook" style="visibility: visible; width: 60px;"></i>
                    <span class="menu-title" data-i18n="User">Boletín Oficial</span>
                </a>
                <ul class="menu-content">
                    <li class=""><a href=""><i class="bx bx-right-arrow-alt"></i><span class="menu-item" data-i18n="List">Opción 1</span></a>
                    </li>
                    <li class=""><a href=""><i class="bx bx-right-arrow-alt"></i><span class="menu-item" data-i18n="View">Opcion 2</span></a>
                    </li>
                    <li class=""><a href=""><i class="bx bx-right-arrow-alt"></i><span class="menu-item" data-i18n="Edit">Opción 3</span></a>
                    </li>
                </ul>
            </li> -->
      <li class="nav-item">
        <a routerLink="clientes"
          ><i class="menu-livicon" data-icon="users"></i
          ><span class="menu-title" data-i18n="Chat">Clientes</span></a
        >
      </li>
      <li class="nav-item">
        <a routerLink="staff"
          ><i class="menu-livicon" data-icon="users"></i
          ><span class="menu-title" data-i18n="Chat">Usuarios DL</span></a
        >
      </li>
      <li class="nav-item">
        <a routerLink="newsletter"
          ><i class="menu-livicon" data-icon="envelope-put"></i
          ><span class="menu-title" data-i18n="Chat">Newsletter</span></a
        >
      </li>
      <li class="nav-item">
        <a routerLink="file-upload"
          ><i class="menu-livicon" data-icon="file-import"></i
          ><span class="menu-title" data-i18n="Alertas"
            >Subida de archivos</span
          ></a
        >
      </li>
      <li class="nav-item has-sub hover">
        <a href="">
          <i
            class="menu-livicon livicon-evo-holder"
            data-icon="paper-plane"
            style="visibility: visible; width: 60px"
          ></i>
          <span class="menu-title">Alertas</span>
        </a>
        <ul class="menu-content">
          <li class="">
            <a routerLink="alertas"
              ><i class="bx bx-right-arrow-alt"></i
              ><span class="menu-item">Envío</span></a
            >
          </li>
          <li class="">
            <a routerLink="email-history"
              ><i class="bx bx-right-arrow-alt"></i
              ><span class="menu-item" data-i18n="View">Historial</span></a
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- END: Main Menu-->
