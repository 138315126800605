<div class="row" id="basic-table">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <!-- head -->
        <h5 class="card-title">ABM de Clientes</h5>
        <div class="heading-elements">
          <ul class="list-group list-group-horizontal">
            <li>
              <button
                type="button"
                class="btn btn-light btn-md"
                routerLink="/dashboard/clientes/nuevo"
                style="margin-top: 10%"

              >
                Crear Nuevo
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-content" id="mainCard">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Estado</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cliente of clientes; let i = index">
                  <td style="width: 33%">{{ cliente.nombreCliente }}</td>
                  <td style="width: 40%">
                    <span
                      *ngIf="cliente.isActive"
                      class="badge badge-light-success"
                      >Activo</span
                    >
                    <span
                      *ngIf="!cliente.isActive"
                      class="badge badge-light-danger"
                      >Desactivado</span
                    >
                  </td>
                  <td>
                    <a [routerLink]="['/dashboard/clientes', cliente.id]"><i id="badges" class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"></i></a>
                    <a (click)="onDelete(cliente.id)"
                      ><i
                        class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                      ></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
