import { Component } from '@angular/core';
import { AutoresComponent } from '../autores/autores.component';
import { ComisionesComponent } from '../comisiones/comisiones.component';
import { TemasComponent } from '../temas/temas.component';
import { BloquesComponent } from '../bloques/bloques.component';

@Component({
  selector: 'app-configview',
  templateUrl: './configview.component.html',
  styleUrls: ['./configview.component.css']
})
export class ConfigViewComponent {
  activeTab = 1;
  autoresTab = AutoresComponent;
  comisionesTab = ComisionesComponent;
  temasTab = TemasComponent;
  bloquesTab = BloquesComponent;
}
