<div class="row" id="basic-table">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <!-- head -->
          <h5 class="card-title">ABM de Comisiones</h5>
          <div class="heading-elements">
            <ul class="list-group list-group-horizontal">
              <li class="list-group-item">
                <select
                  placeholder="Selecciona algún pais"
                  class="form-control"
                  [(ngModel)]="selectedPais"
                  (change)="changeDependencies(selectedPais.id)"
                >
                  <option *ngFor="let pais of paises" [ngValue]="pais">
                    {{ pais.name }}
                  </option>
                </select>
              </li>
              <li>
                <button
                  type="button"
                  class="btn btn-light btn-md"
                  data-toggle="modal"
                  data-target="#comisionModal"
                  style="margin-top: 10%"
                  (click)="comisionesForm()"
                  [attr.disabled]="selectedPais == undefined ? 'disabled' : null"
                >
                  Crear Nuevo
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-content" id="mainCard">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Pais</th>
                    <th>Camara</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let comision of comisiones; let i = index">
                    <td style="width: 33%">{{ comision.nombre }}</td>
                    <td>{{ comision.pais.name }}</td>
                    <td>{{ comision.camara.nombreCamara}}</td>
                    <td>
                      <a data-target="#comisionModal" data-toggle="modal" (click)="editComision(comision)"
                        ><i
                          class="badge-circle badge-circle-light-secondary bx bx-pencil font-medium-1 mr-1"
                        ></i
                      ></a>
                      <a (click)="onDelete(comision.id)"
                        ><i
                          class="badge-circle badge-circle-light-secondary bx bx-trash font-medium-1"
                        ></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- COMISION Modal -->
  <div
    class="modal fade text-left"
    id="comisionModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel1">Agregar Comision</h3>
          <button
            type="button"
            class="close rounded-pill"
            data-dismiss="modal"
            aria-label="Close"
            (click)="cleanComision()"
          >
            <i class="bx bx-x"></i>
          </button>
        </div>
        <form
          *ngIf="comisionForm"
          [formGroup]="comisionForm"
          (ngSubmit)="submitComision()"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <label>Nombre</label>
                <div class="form-group">
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    formControlName="nombre"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>País</label>
                <select
                  formControlName="pais"
                  class="custom-select d-block w-100 form-control"
                >
                  <option value="none">
                    Seleccione un pais en el menu principal
                  </option>
                  <option *ngFor="let pais of paises" [ngValue]="pais.id">
                    {{ pais.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label>Cámara</label>
                <select
                  formControlName="camara"
                  class="custom-select d-block w-100"
                >
                  <option *ngFor="let camara of camaras" [value]="camara.nombreCamara">
                    {{ camara.nombreCamara }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light-secondary"
            data-dismiss="modal"
          >
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Cerrar</span>
          </button>
          <button
            type="button"
            class="btn btn-primary ml-1"
            data-dismiss="modal"
            (click)="submitComision()"
          >
            <i class="bx bx-check d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Guardar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  