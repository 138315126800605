import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CamarasEnum } from 'src/utils/camarasEnum';
import { NormativasMapper } from 'src/utils/normativasMapper';
import { PaisesEnum } from 'src/utils/paisesEnum';
import { ProyectosMapper } from 'src/utils/proyectosMapper';
import Swal from 'sweetalert2';
import { AutoresModel } from '../../entidades/models/autores.model';
import { ProyectoModel } from '../../entidades/models/proyecto.model';
import { LukeService } from '../../panelServices/luke.service';
import { ScraperService } from '../../panelServices/scraper.service';

@Component({
  selector: 'app-scraping-view',
  templateUrl: './scraping-view.component.html',
  styleUrls: ['./scraping-view.component.css'],
})
export class ScrapingViewComponent implements OnInit {
  public entidad: any;
  public type: number;
  autores: AutoresModel[];
  constructor(
    private scraperService: ScraperService,
    private lukeService: LukeService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (this.router.url === `/dashboard/scraping/proyectos/${id}`) {
      this.type = 1;
      this.scraperService.getProyectoId(id).subscribe((res: any) => {
        this.entidad = res;
        this.entidad.paisId =
          PaisesEnum[this.entidad.pais.name.replace(/ /g, '')];
        this.lukeService
          .getAutores({ paisId: this.entidad.paisId })
          .subscribe((aut: any) => {
            this.autores = aut;
          });
      });
    } else {
      this.type = 2;
      this.scraperService.getNormativaId(id).subscribe((res: any) => {
        this.entidad = res;
      });
    }
  }

  parseDate(fecha) {
    return formatDate(fecha, 'dd-MM-yyyy', 'en-US');
  }

  uploadEntidad() {
    Swal.fire('Cargando...');
    Swal.showLoading();
    delete this.entidad.id;
    this.entidad.paisId = PaisesEnum[this.entidad.pais.name.replace(/ /g, '')];

    let movimientos = this.entidad.sintesis;

    this.entidad.sintesis = `Autores: ${this.entidad.proyectosAutores
      .map((aut) => {
        return aut.autor.nombreApellido;
      })
      .join('| ')}`;

    if (this.type == 1) {
      this.entidad.camaraId =
        CamarasEnum[this.entidad.camara.nombreCamara.replace(/ /g, '')];
      this.entidad.proyectoTipoId = 7;
      let mappedValues = ProyectosMapper.mapToRequest(this.entidad);
      mappedValues['movimientosParlamentarios'] = JSON.parse(
        movimientos.replace(/'/g, '"')
      );
      delete mappedValues.id;
      this.lukeService
        .postProyecto(mappedValues)
        .subscribe((res: ProyectoModel) => {
          if (!res) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else {
            Swal.fire({
              title: 'Entidad agregada',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                this.router.navigateByUrl('/dashboard/proyectos/' + res.id);
              },
            });
          }
        });
    } else {
      let mappedValues = NormativasMapper.mapToRequest(this.entidad);
      delete mappedValues.id;
      this.lukeService
        .postNormativa(mappedValues)
        .subscribe((res: ProyectoModel) => {
          if (!res) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Algo no salió bien, revisá los datos del formulario',
            });
          } else {
            Swal.fire({
              title: 'Entidad agregada',
              text: 'Se actualizó correctamente',
              icon: 'success',
              showConfirmButton: true,
              confirmButtonText: 'Cerrar',
              willClose: () => {
                this.router.navigateByUrl('/dashboard/normativas/' + res.id);
              },
            });
          }
        });
    }
  }

  camarasArray = [
    {
      id: 1,
      nombreCamara: 'Unicameral',
      selected: false,
    },
    {
      id: 2,
      nombreCamara: 'Asamblea Legislativa',
      selected: false,
    },
    {
      id: 3,
      nombreCamara: 'Asamblea Nacional',
      selected: false,
    },
    {
      id: 4,
      nombreCamara: 'Asamblea Unicameral',
      selected: false,
    },
    {
      id: 5,
      nombreCamara: 'Cámara de Representantes',
      selected: false,
    },
    {
      id: 6,
      nombreCamara: 'Cámara de Senadores',
      selected: false,
    },
    {
      id: 7,
      nombreCamara: 'Cámara de Diputados',
      selected: false,
    },
    {
      id: 8,
      nombreCamara: 'Congreso Nacional',
      selected: false,
    },
    {
      id: 9,
      nombreCamara: 'Congreso Unicameral',
      selected: false,
    },
    {
      id: 10,
      nombreCamara: 'Senado',
      selected: false,
    },
    {
      id: 11,
      nombreCamara: 'Senado de la República',
      selected: false,
    },
    {
      id: 12,
      nombreCamara: 'Congreso de la República',
      selected: false,
    },
    {
      id: 13,
      nombreCamara: 'Senado Federal',
      selected: false,
    },
  ];
}
