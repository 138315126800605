import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import {
  EmailActivityQP,
  EmailActivityStatus,
} from '../entidades/models/email-activity-qp.interface';
import { EmailActivityResponse } from '../models/email-activity-response.interface';
import { SentEmail } from '../models/sent-email.model';
import { MailerService } from '../panelServices/mailer.service';

@Component({
  selector: 'app-email-history',
  templateUrl: './email-history.component.html',
  styleUrls: ['./email-history.component.css'],
})
export class EmailHistoryComponent implements OnInit {
  sentEmails: SentEmail[] = [];
  isLoading: boolean = false;
  filteredEmails: SentEmail[] = [];
  filterForm: UntypedFormGroup;
  totalCount: number = null;
  pageSize: number = 20;
  pageNumber: number = 1;
  productos = [
    { id: 'd-8046b7c187b14218a0ceca969dcf372c', value: 'Alertas' },
    { id: 'd-0213e20aee4d4b76a215163116684028', value: 'Newsletter' },
  ];
  emailStatus = [
    { id: 'DELIVERED', value: 'Enviado' },
    { id: 'NOT_DELIVERED', value: 'No enviado' },
    { id: 'PROCESSING', value: 'Procesando' },
  ];

  constructor(
    private mailerService: MailerService,
    private formBuilder: FormBuilder
  ) {
    this.createFilterForm();
  }

  ngOnInit(): void {
    Swal.showLoading();
    this.isLoading = true;
    this.mailerService.getActivity().subscribe(
      (res: EmailActivityResponse) => {
        this.sentEmails = res.messages;
        this.totalCount = res.messages.length;
        this.updateFilteredEmails();
        this.isLoading = false;
        Swal.close();
      },
      (error) => {
        Swal.close();
        console.error(error);
        Swal.fire({ text: 'Error recuperando historial', icon: 'error' });
        this.isLoading = false;
      }
    );
  }

  private createFilterForm(): void {
    this.filterForm = this.formBuilder.group({
      to_email: new FormControl(null),
      status: new FormControl(null),
      template_id: new FormControl(null),
      subject: new FormControl(null),
      start_date: new FormControl(null),
      end_date: new FormControl(null),
    });
  }

  updateFilteredEmails() {
    const start = (this.pageNumber - 1) * this.pageSize;
    const end = start + this.pageSize;
    this.filteredEmails = this.sentEmails.slice(start, end);
  }

  onPageChange(newPageNumber: number) {
    this.pageNumber = newPageNumber;
    this.updateFilteredEmails();
  }

  resetFilter(): void {
    Swal.fire({ text: 'Cargando' });
    Swal.showLoading();
    this.isLoading = true;

    this.filterForm.reset();

    this.mailerService.getActivity().subscribe(
      (res: EmailActivityResponse) => {
        this.sentEmails = res.messages;
        this.totalCount = res.messages.length;
        this.updateFilteredEmails();
        this.isLoading = false;
        Swal.close();
      },
      (error) => {
        console.error(error);
        Swal.fire({ text: 'Error recuperando historial de mails.' });
        this.isLoading = false;
        Swal.close();
      }
    );
  }

  applyFilter(): void {
    Swal.fire({ text: 'Cargando' });
    Swal.showLoading();
    this.isLoading = true;
    const qp: EmailActivityQP = {
      to_email: this.filterForm.get('to_email').value,
      status: EmailActivityStatus[this.filterForm.get('status').value],
      template_id: this.filterForm.get('template_id').value,
      subject: this.filterForm.get('subject').value,
      start_date: this.filterForm.get('start_date').value,
      end_date: this.filterForm.get('end_date').value,
    };

    this.mailerService.getActivity(qp).subscribe(
      (res: EmailActivityResponse) => {
        this.sentEmails = res.messages;
        this.totalCount = res.messages.length;
        this.updateFilteredEmails();
        this.isLoading = false;
        Swal.close();
      },
      (error) => {
        console.error(error);
        Swal.fire({ text: 'Error recuperando historial de mails.' });
        this.isLoading = false;
        Swal.close();
      }
    );
  }
}
