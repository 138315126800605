export class ClientesMapper {
  public static mapToRequest(formData) {
    let clienteRequest = {
      id: formData?.id ?? 0,
      nombreCliente: formData?.nombreCliente,
      isActive: formData?.isActive ?? true,
      categorias: formData?.categorias,
      serviciosPaises: {
        Alertas: formData.alertas,
        Dashboard: formData.dashboard,
        Scraper: formData.scraper,
        Newsletter: formData.newsletter,
      },
      serviciosDashboardPaises: {
        Notas: formData.dashboardNotas,
        Normativas: formData.dashboardNormativas,
        Proyectos: formData.dashboardProyectos,
      },
    };

    return clienteRequest;
  }
}
