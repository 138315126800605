<div class="row">
  <div class="col-md-4 clientes-card-container" *ngIf="selectedClientes">
    <div *ngFor="let selectedCliente of selectedClientes; let i = index">
      <div *ngIf="!addingUsuarios || (addingUsuarios && addingUsuariosCliente == selectedCliente)">
        <div class="card mb-3 focus move-to-top-left" style="margin-right: 10px;">
          <div class="card-body">
            <h5 class="card-title">{{ selectedCliente.nombreCliente }}</h5>
            <p class="card-text">Cliente seleccionado</p>
            <div class="d-flex justify-content-between">
              <button
                class="btn btn-primary mr-2"
                (click)="limpiarCliente(selectedCliente)"
                [disabled]="addingUsuarios"
              >
                Eliminar
              </button>
              <button
                class="btn btn-primary"
                *ngIf="!addingUsuarios"
                (click)="startAddingUsuarios(selectedCliente)"
              >
                Agregar Usuarios
              </button>
              <button
                class="btn btn-primary"
                *ngIf="addingUsuarios"
                (click)="addingUsuarios = false"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<hr *ngIf="selectedClientes.length > 0">

<div class="row" [class.fade-in]="!addingUsuarios" *ngIf="!addingUsuarios">
  <div
    class="col-md-4"
    *ngFor="let cliente of clientes; let i index"
  >
    <div class="card mb-3" (click)="selectClient(cliente)">
      <div class="card-body">
        <h5 class="card-title">{{ cliente.nombreCliente }}</h5>
        <a class="btn btn-primary">Seleccionar</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="addingUsuarios" [class.fade-in]="addingUsuarios">
  <div class="row mb-3">
    <div class="col-md-4">
      <button class="btn btn-light" (click)="selectAll(addingUsuariosCliente)">
        Seleccionar todos
      </button>
      <button class="btn btn-light ml-2" (click)="unselectAll(addingUsuariosCliente)">
        Deseleccionar todos
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <div
      class="col-md-4"
      *ngFor="let usuario of addingUsuariosCliente.usuariosRelacionados"
    >
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">{{ usuario.nombre + usuario.apellido }}</h5>
          <p class="card-text">Email: {{ usuario.email }}</p>
          <ng-container *ngIf="!isUsuarioSelected(usuario)">
            <a class="btn btn-primary" (click)="addUsuario(usuario)"
              >Seleccionar para envío</a
            >
          </ng-container>
          <ng-container *ngIf="isUsuarioSelected(usuario)">
            <a class="btn btn-success" (click)="removeUsuario(usuario)"
              >Agregado</a
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>
<div class="row mb-3">
  <div class="col ml-2">
    <button class="btn btn-success" [disabled]="selectedUsuarios.length < 1" (click)="emitClientData()">
      Continuar a selección de entidades
    </button>
  </div>
</div>
