import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EmailActivityQP } from '../entidades/models/email-activity-qp.interface';
import { EmailActivityResponse } from '../models/email-activity-response.interface';

@Injectable({
  providedIn: 'root',
})
export class MailerService {
  constructor(private http: HttpClient) {}

  postEmail(payload) {
    return this.http.post(`${environment.mailer_url}/mail`, payload);
  }

  postNewsletter(payload) {
    return this.http.post(`${environment.mailer_url}/mail/newsletter`, payload);
  }

  postEmailWithTemplate(payload) {
    return this.http.post(
      `${environment.mailer_url}/mail/dynamic-template`,
      payload
    );
  }

  getActivity(payload?: EmailActivityQP): Observable<EmailActivityResponse> {
    return this.http
      .post<EmailActivityResponse>(
        `${environment.mailer_url}/mail/activity`,
        payload
      )
      .pipe(
        catchError((error) => {
          console.error('Error recovering email activity', error);
          return throwError([]);
        })
      );
  }
}
