import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from '../services/login.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private isRefreshing = false;
  constructor(
    private router: Router,
    private loginService: LoginService,
    private cookieService: CookieService,
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = this.cookieService.get('token');
    const refreshToken = this.cookieService.get('refreshToken');
      if (!token && refreshToken && !this.isRefreshing) {
        return this.loginService.refreshToken().pipe(
          map((response) => {
            token = response.access_token;
            const tokenPayload = JSON.parse(
              atob(token.split('.')[1])
            );
            const expirationDate = new Date(tokenPayload.exp * 1000);
            this.cookieService.set('token', token, expirationDate);
            return true;
          }),
          catchError(() => {
            this.cookieService.delete('token');
            this.cookieService.delete('refreshToken');
            this.router.navigate(['/login']);
            return of(false);
          })
        );
      }
      if (!token && !refreshToken && !this.isRefreshing) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
  }
}
