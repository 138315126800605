import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ScraperService {
  private url = environment.scraper_url;

  searchText: String = '';
  selected_count: number = 1;

  constructor(private http: HttpClient) {}

  getPaises() {
    return this.http.get(`${this.url}/paises?sortby=Name`);
  }

  getCamaras(id: string) {
    return this.http.get(`${this.url}/paises/${id}/camaras`);
  }

  getAutores(id: string) {
    return this.http.get(`${this.url}/autores?paisId=${id}`);
  }

  getNormativas(id: string) {
    return this.http.get(`${this.url}/normativas?paisId=${id}`);
  }

  getProyectos(id: string) {
    return this.http.get(`${this.url}/proyectos?paisId=${id}`);
  }

  getProyectosByQueryParams(params: {[key: string]: any}) {
    const queryString = Object.keys(params)
      .filter(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return value.length > 0; 
        }
        return value !== null && value !== '';
      })
      .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  
    const urlWithParams = `${this.url}/proyectos?${queryString}`;
    return this.http.get(urlWithParams);
  }

  getProyectosTipos() {
    return this.http.get(`${this.url}/proyectos/tipos`);
  }

  getNormativaId(id: string) {
    return this.http.get(`${this.url}/normativas/${id}`);
  }

  getNormativaByNombre(titulo: string) {
    return this.http.get(`${this.url}/normativas/?titulo=${titulo}`);
  }

  getProyectoId(id: string) {
    return this.http.get(`${this.url}/proyectos/${id}`);
  }

  getParlamentos(): Observable<any> {
    return this.http.get(`${this.url}/parlamentos`);
  }

  getParlamentosByPaisId(selectedCamaraId: string): Observable<any> {
    let params1 = new HttpParams().set('camaraId', selectedCamaraId);
    return this.http.get(`${this.url}/proyectos`, { params: params1 });
  }

  getProyectosByParlamentoId(paisId: string, camaraId: number[]) {
    if (camaraId != null) {
      return this.http.get(
        `${this.url}/proyectos?paisId=${paisId}&camaraId=${camaraId}`
      );
    } else {
      return this.http.get(`${this.url}/proyectos?paisId=${paisId}`);
    }
  }

  setSearchTerm(term: String) {
    this.searchText = term;
  }

  getSearchTerm() {
    return this.searchText;
  }

  setVisibleProyecto(id: string) {
    return this.http.put(`${this.url}/proyectos/${id}/visible`, {});
  }

  setVisibleNormativa(id: string) {
    return this.http.put(`${this.url}/normativas/${id}/visible`, {});
  }
}
