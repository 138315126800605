<div class="header">
    <div class="card-body text-center">
        <h1 class=" mb-2 kb-title">Scraping</h1>
        <form>
            <fieldset class="form-group position-relative w-50 mx-auto kb-search-width">
                <input type="text" name="searchValuev2" class="form-control form-control-lg round pl-2" placeholder="Pais..." id="search-text" aria-describedby="search-text" [(ngModel)]="searchValuev2">
            </fieldset>
        </form>
    </div>
</div>

<div class="row">
    <div class="column">
        <div *ngFor="let flag of countries1 | searchcountry: searchValuev2">
            <a [routerLink]="flag.id"><img [title]='flag.name' [src]='flag.url' [id]="flag.id" [alt]="flag.name" /></a>
        </div>
    </div>
    <div class="column">
        <div *ngFor="let flag of countries2 | searchcountry: searchValuev2">
            <a [routerLink]="flag.id"><img [title]='flag.name' [src]='flag.url' [id]="flag.id" [alt]="flag.name" /></a>
        </div>
    </div>
    <div class="column">
        <div *ngFor="let flag of countries3 | searchcountry: searchValuev2">
            <a [routerLink]="flag.id"><img [title]='flag.name' [src]='flag.url' [id]="flag.id" [alt]="flag.name" /></a>
        </div>
    </div>
    <div class="column">
        <div *ngFor="let flag of countries4 | searchcountry: searchValuev2">
            <a [routerLink]="flag.id"><img [title]='flag.name' [src]='flag.url' [id]="flag.id" [alt]="flag.name" /></a>
        </div>
    </div>
</div>