function renameProperties(objArray) {
  objArray.forEach((obj) => {
    obj.paises.forEach((pais) => {
      if (pais.nombre) {
        pais.name = pais.nombre;
      }
      delete pais.nombre;
    });
  });

  return objArray;
}

export default renameProperties;
