<section class="kb-search">
    <div class="row">
        <div class="col-12">
            <div class="card bg-transparent shadow-none kb-header">
                <div class="card-content">
                    <div class="card-body text-center">
                        <h1 class="kb-title">Entidades</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="kb-content">
    <div class="row kb-search-content-info mx-1 mx-md-2 mx-lg-5">
        <div class="col-12">
            <div class="row">
                <div class="col-6 col-md-3 kb-search-content">
                    <div class="card kb-hover-1" style="height: 250.5px;">
                        <div class="card-content">
                            <div class="card-body text-center">
                                
                                <a [routerLink]="['/dashboard/anteproyectos']">
                                    <div class="mb-2 icon-wrapper">
                                        <fa-icon class="icon" [icon]="faFileArchive"></fa-icon>
                                    </div>
                                    <h3>Anteproyectos</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 kb-search-content">
                    <div class="card kb-hover-2" style="height: 250.5px;">
                        <div class="card-content">
                            <div class="card-body text-center">
                                <a [routerLink]="['/dashboard/proyectos']">
                                    <div class="mb-2 icon-wrapper">
                                        <fa-icon class="icon" [icon]="faRectangleList"></fa-icon>
                                    </div>
                                    <h3>Proyectos</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 kb-search-content">
                    <div class="card kb-hover-3" style="height: 250.5px;">
                        <div class="card-content">
                            <div class="card-body text-center">
                                <a [routerLink]="['/dashboard/leyes']">
                                    <div class="mb-2 icon-wrapper">
                                        <fa-icon class="icon" [icon]="faScale"></fa-icon>
                                    </div>
                                    <h3>Leyes</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-3 kb-search-content">
                    <div class="card kb-hover-4" style="height: 250.5px;">
                        <div class="card-content">
                            <div class="card-body text-center">
                                <a [routerLink]="['/dashboard/normativas']">
                                    <div class="mb-2 icon-wrapper">
                                        <fa-icon class="icon" [icon]="faGavel"></fa-icon>
                                    </div>
                                    <h3>Normativas</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>