<main class="position-relative w-100 h-100 px-xxl-5">

  <div class="row gx-5 justify-content-center h-100 align-items-center px-2 px-lg-5">
    <div class="card col-12 col-lg-6 py-5 px-5 mx-lg-5 d-flex flex-column">
      <div class="d-flex flex-column">
        <div>
          <h1 class="fw-semibold fs-1 text-center pb-4">
            Iniciar sesión
          </h1>
        </div>
        <div class="d-flex justify-content-center">
        </div>
      </div>
      <div>
        <form [formGroup]="form">
          <div class="w-100 mb-3" appearance="outline">
            <label>Email</label>
            <input type="text" class="form-control" name="user" formControlName="email">
          </div>
          <div>
            <div class="w-100 mb-3" appearance="outline">
              <label>Contraseña</label>
              <input type="password" class="form-control" name="password" formControlName="password">
            </div>
            <div class="d-flex justify-content-end pb-1">
              <a routerLink="/forgot-password" class="text-decoration-none">
                ¿Olvidaste tu contraseña?
              </a>
            </div>
          </div>
          <div class="d-flex justify-content-center pb-2">
            <div *ngIf="form.hasError('invalidCredentials')">
              El email o la contraseña son incorrectos
            </div>
          </div>
          <div class="d-flex justify-content-center pb-2">
            <div *ngIf="form.hasError('unauthorized')">
              No autorizado.
            </div>
          </div>
          <div class="d-flex justify-content-center pb-2">
            <div *ngIf="form.hasError('apiError')">
              Ocurrió un error. Intente en unos minutos.
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button color="accent" class="w-100 btn btn-primary" (click)="submit()" [disabled]='form.invalid'>Iniciar sesión</button>
          </div>
        </form>
      </div>
    </div>
  </div>


</main>
